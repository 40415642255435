<template>
    <div>
        <van-index-bar class="indexBar" :sticky="false" highlight-color="#AE853A">
            <van-index-anchor v-for="(item,index) in citys" :key="index" :index="item.initial">
                <span class="indexWord">{{item.initial}}</span>
                <van-cell
                        @click="chooseCity(citem)"
                        v-for="(citem,cindex) in item.list"
                        :key="cindex"
                        :title="citem.city"
                />
            </van-index-anchor>
        </van-index-bar>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { IndexBar, IndexAnchor } from 'vant';
    import {areaList} from '@vant/area-data'
    Vue.use(IndexBar);
    Vue.use(IndexAnchor);
    export default {
        city: "SelectCity",
        data () {
            return {
                areaList,
                citys: [
                    {
                        "initial": "A",
                        "list": [
                            {
                                "code": "0997",
                                "city": "阿克苏",
                                "pinyin": "Akesu",
                                "label": "Akesu0997"
                            },
                            {
                                "code": "0837",
                                "city": "阿坝",
                                "pinyin": "Aba",
                                "label": "Aba0837"
                            },
                            {
                                "code": "0483",
                                "city": "阿拉善盟",
                                "pinyin": "Alashanmeng",
                                "label": "Alashanmeng0483"
                            },
                            {
                                "code": "0906",
                                "city": "阿勒泰",
                                "pinyin": "Aletai",
                                "label": "Aletai0906"
                            },
                            {
                                "code": "0897",
                                "city": "阿里",
                                "pinyin": "Ali",
                                "label": "Ali0897"
                            },
                            {
                                "code": "0915",
                                "city": "安康",
                                "pinyin": "Ankang",
                                "label": "Ankang0915"
                            },
                            {
                                "code": "0556",
                                "city": "安庆",
                                "pinyin": "Anqing",
                                "label": "Anqing0556"
                            },
                            {
                                "code": "0412",
                                "city": "鞍山",
                                "pinyin": "Anshan",
                                "label": "Anshan0412"
                            },
                            {
                                "code": "0853",
                                "city": "安顺",
                                "pinyin": "Anshun",
                                "label": "Anshun0853"
                            },
                            {
                                "code": "0372",
                                "city": "安阳",
                                "pinyin": "Anyang",
                                "label": "Anyang0372"
                            },
                            {
                                "code": "0451",
                                "city": "阿城",
                                "pinyin": "Acheng",
                                "label": "Acheng0451"
                            },
                            {
                                "code": "0796",
                                "city": "安福",
                                "pinyin": "Anfu",
                                "label": "Anfu0796"
                            },
                            {
                                "code": "0572",
                                "city": "安吉",
                                "pinyin": "Anji",
                                "label": "Anji0572"
                            },
                            {
                                "code": "0871",
                                "city": "安宁",
                                "pinyin": "Anning",
                                "label": "Anning0871"
                            },
                            {
                                "code": "0536",
                                "city": "安丘",
                                "pinyin": "Anqiu",
                                "label": "Anqiu0536"
                            },
                            {
                                "code": "0595",
                                "city": "安溪",
                                "pinyin": "Anxi",
                                "label": "Anxi0595"
                            },
                            {
                                "code": "0791",
                                "city": "安义",
                                "pinyin": "Anyi",
                                "label": "Anyi0791"
                            },
                            {
                                "code": "0797",
                                "city": "安远",
                                "pinyin": "Anyuan",
                                "label": "Anyuan0797"
                            }
                        ]
                    },
                    {
                        "initial": "B",
                        "list": [
                            {
                                "code": "010",
                                "city": "北京",
                                "pinyin": "Beijing",
                                "label": "Beijing010"
                            },
                            {
                                "code": "0436",
                                "city": "白城",
                                "pinyin": "Baicheng",
                                "label": "Baicheng0436"
                            },
                            {
                                "code": "0776",
                                "city": "百色",
                                "pinyin": "Baise",
                                "label": "Baise0776"
                            },
                            {
                                "code": "0439",
                                "city": "白山",
                                "pinyin": "Baishan",
                                "label": "Baishan0439"
                            },
                            {
                                "code": "0943",
                                "city": "白银",
                                "pinyin": "Baiyin",
                                "label": "Baiyin0943"
                            },
                            {
                                "code": "0552",
                                "city": "蚌埠",
                                "pinyin": "Bangbu",
                                "label": "Bangbu0552"
                            },
                            {
                                "code": "0312",
                                "city": "保定",
                                "pinyin": "Baoding",
                                "label": "Baoding0312"
                            },
                            {
                                "code": "0917",
                                "city": "宝鸡",
                                "pinyin": "Baoji",
                                "label": "Baoji0917"
                            },
                            {
                                "code": "0875",
                                "city": "保山",
                                "pinyin": "Baoshan",
                                "label": "Baoshan0875"
                            },
                            {
                                "code": "0472",
                                "city": "包头",
                                "pinyin": "Baotou",
                                "label": "Baotou0472"
                            },
                            {
                                "code": "0478",
                                "city": "巴彦淖尔",
                                "pinyin": "Bayannaoer",
                                "label": "Bayannaoer0478"
                            },
                            {
                                "code": "0996",
                                "city": "巴音郭楞",
                                "pinyin": "Bayinguoleng",
                                "label": "Bayinguoleng0996"
                            },
                            {
                                "code": "0827",
                                "city": "巴中",
                                "pinyin": "Bazhong",
                                "label": "Bazhong0827"
                            },
                            {
                                "code": "0779",
                                "city": "北海",
                                "pinyin": "Beihai",
                                "label": "Beihai0779"
                            },
                            {
                                "code": "0414",
                                "city": "本溪",
                                "pinyin": "Benxi",
                                "label": "Benxi0414"
                            },
                            {
                                "code": "0857",
                                "city": "毕节",
                                "pinyin": "Bijie",
                                "label": "Bijie0857"
                            },
                            {
                                "code": "0543",
                                "city": "滨州",
                                "pinyin": "Binzhou",
                                "label": "Binzhou0543"
                            },
                            {
                                "code": "0909",
                                "city": "博尔塔拉",
                                "pinyin": "Boertala",
                                "label": "Boertala0909"
                            },
                            {
                                "code": "0558",
                                "city": "亳州",
                                "pinyin": "Bozhou",
                                "label": "Bozhou0558"
                            },
                            {
                                "code": "0514",
                                "city": "宝应",
                                "pinyin": "Baoying",
                                "label": "Baoying0514"
                            },
                            {
                                "code": "0451",
                                "city": "巴彦",
                                "pinyin": "Bayan",
                                "label": "Bayan0451"
                            },
                            {
                                "code": "0515",
                                "city": "滨海",
                                "pinyin": "Binhai",
                                "label": "Binhai0515"
                            },
                            {
                                "code": "0451",
                                "city": "宾县",
                                "pinyin": "Binxian",
                                "label": "Binxian0451"
                            },
                            {
                                "code": "0771",
                                "city": "宾阳",
                                "pinyin": "Binyang",
                                "label": "Binyang0771"
                            },
                            {
                                "code": "023",
                                "city": "璧山",
                                "pinyin": "Bishan",
                                "label": "Bishan023"
                            },
                            {
                                "code": "0391",
                                "city": "博爱",
                                "pinyin": "Boai",
                                "label": "Boai0391"
                            },
                            {
                                "code": "0752",
                                "city": "博罗",
                                "pinyin": "Boluo",
                                "label": "Boluo0752"
                            },
                            {
                                "code": "0543",
                                "city": "博兴",
                                "pinyin": "Boxing",
                                "label": "Boxing0543"
                            }
                        ]
                    },
                    {
                        "initial": "C",
                        "list": [
                            {
                                "code": "023",
                                "city": "重庆",
                                "pinyin": "Chongqing",
                                "label": "Chongqing023"
                            },
                            {
                                "code": "0431",
                                "city": "长春",
                                "pinyin": "Changchun",
                                "label": "Changchun0431"
                            },
                            {
                                "code": "0731",
                                "city": "长沙",
                                "pinyin": "Changsha",
                                "label": "Changsha0731"
                            },
                            {
                                "code": "0519",
                                "city": "常州",
                                "pinyin": "Changzhou",
                                "label": "Changzhou0519"
                            },
                            {
                                "code": "028",
                                "city": "成都",
                                "pinyin": "Chengdu",
                                "label": "Chengdu028"
                            },
                            {
                                "code": "0317",
                                "city": "沧州",
                                "pinyin": "Cangzhou",
                                "label": "Cangzhou0317"
                            },
                            {
                                "code": "0736",
                                "city": "常德",
                                "pinyin": "Changde",
                                "label": "Changde0736"
                            },
                            {
                                "code": "0895",
                                "city": "昌都",
                                "pinyin": "Changdu",
                                "label": "Changdu0895"
                            },
                            {
                                "code": "0997",
                                "city": "昌吉",
                                "pinyin": "Changji",
                                "label": "Changji0997"
                            },
                            {
                                "code": "0355",
                                "city": "长治",
                                "pinyin": "Changzhi",
                                "label": "Changzhi0355"
                            },
                            {
                                "code": "0565",
                                "city": "巢湖",
                                "pinyin": "Chaohu",
                                "label": "Chaohu0565"
                            },
                            {
                                "code": "0421",
                                "city": "朝阳",
                                "pinyin": "Chaoyang",
                                "label": "Chaoyang0421"
                            },
                            {
                                "code": "0768",
                                "city": "潮州",
                                "pinyin": "Chaozhou",
                                "label": "Chaozhou0768"
                            },
                            {
                                "code": "0314",
                                "city": "承德",
                                "pinyin": "Chengde",
                                "label": "Chengde0314"
                            },
                            {
                                "code": "0735",
                                "city": "郴州",
                                "pinyin": "Chenzhou",
                                "label": "Chenzhou0735"
                            },
                            {
                                "code": "0476",
                                "city": "赤峰",
                                "pinyin": "Chifeng",
                                "label": "Chifeng0476"
                            },
                            {
                                "code": "0566",
                                "city": "池州",
                                "pinyin": "Chizhou",
                                "label": "Chizhou0566"
                            },
                            {
                                "code": "0771",
                                "city": "崇左",
                                "pinyin": "Chongzuo",
                                "label": "Chongzuo0771"
                            },
                            {
                                "code": "0875",
                                "city": "楚雄",
                                "pinyin": "Chuxiong",
                                "label": "Chuxiong0875"
                            },
                            {
                                "code": "0550",
                                "city": "滁州",
                                "pinyin": "Chuzhou",
                                "label": "Chuzhou0550"
                            },
                            {
                                "code": "0577",
                                "city": "苍南",
                                "pinyin": "Cangnan",
                                "label": "Cangnan0577"
                            },
                            {
                                "code": "0539",
                                "city": "苍山",
                                "pinyin": "Cangshan",
                                "label": "Cangshan0539"
                            },
                            {
                                "code": "0530",
                                "city": "曹县",
                                "pinyin": "Caoxian",
                                "label": "Caoxian0530"
                            },
                            {
                                "code": "0535",
                                "city": "长岛",
                                "pinyin": "Changdao",
                                "label": "Changdao0535"
                            },
                            {
                                "code": "0551",
                                "city": "长丰",
                                "pinyin": "Changfeng",
                                "label": "Changfeng0551"
                            },
                            {
                                "code": "0411",
                                "city": "长海",
                                "pinyin": "Changhai",
                                "label": "Changhai0411"
                            },
                            {
                                "code": "0591",
                                "city": "长乐",
                                "pinyin": "Changle",
                                "label": "Changle0591"
                            },
                            {
                                "code": "0536",
                                "city": "昌乐",
                                "pinyin": "Changle",
                                "label": "Changle0536"
                            },
                            {
                                "code": "0570",
                                "city": "常山",
                                "pinyin": "Changshan",
                                "label": "Changshan0570"
                            },
                            {
                                "code": "0512",
                                "city": "常熟",
                                "pinyin": "Changshu",
                                "label": "Changshu0512"
                            },
                            {
                                "code": "0596",
                                "city": "长泰",
                                "pinyin": "Changtai",
                                "label": "Changtai0596"
                            },
                            {
                                "code": "0597",
                                "city": "长汀",
                                "pinyin": "Changting",
                                "label": "Changting0597"
                            },
                            {
                                "code": "0572",
                                "city": "长兴",
                                "pinyin": "Changxing",
                                "label": "Changxing0572"
                            },
                            {
                                "code": "0536",
                                "city": "昌邑",
                                "pinyin": "Changyi",
                                "label": "Changyi0536"
                            },
                            {
                                "code": "0768",
                                "city": "潮安",
                                "pinyin": "Chaoan",
                                "label": "Chaoan0768"
                            },
                            {
                                "code": "0871",
                                "city": "呈贡",
                                "pinyin": "Chenggong",
                                "label": "Chenggong0871"
                            },
                            {
                                "code": "023",
                                "city": "城口",
                                "pinyin": "Chengkou",
                                "label": "Chengkou023"
                            },
                            {
                                "code": "0530",
                                "city": "成武",
                                "pinyin": "Chengwu",
                                "label": "Chengwu0530"
                            },
                            {
                                "code": "0635",
                                "city": "茌平",
                                "pinyin": "Chiping",
                                "label": "Chiping0635"
                            },
                            {
                                "code": "0794",
                                "city": "崇仁",
                                "pinyin": "Chongren",
                                "label": "Chongren0794"
                            },
                            {
                                "code": "0797",
                                "city": "崇义",
                                "pinyin": "Chongyi",
                                "label": "Chongyi0797"
                            },
                            {
                                "code": "028",
                                "city": "崇州",
                                "pinyin": "Chongzhou",
                                "label": "Chongzhou028"
                            },
                            {
                                "code": "0571",
                                "city": "淳安",
                                "pinyin": "Chunan",
                                "label": "Chunan0571"
                            },
                            {
                                "code": "0574",
                                "city": "慈溪",
                                "pinyin": "Cixi",
                                "label": "Cixi0574"
                            },
                            {
                                "code": "020",
                                "city": "从化",
                                "pinyin": "Conghua",
                                "label": "Conghua020"
                            },
                            {
                                "code": "0556",
                                "city": "枞阳",
                                "pinyin": "Congyang",
                                "label": "Congyang0556"
                            }
                        ]
                    },
                    {
                        "initial": "D",
                        "list": [
                            {
                                "code": "0411",
                                "city": "大连",
                                "pinyin": "Dalian",
                                "label": "Dalian0411"
                            },
                            {
                                "code": "0769",
                                "city": "东莞",
                                "pinyin": "Dongguan",
                                "label": "Dongguan0769"
                            },
                            {
                                "code": "0872",
                                "city": "大理",
                                "pinyin": "Dali",
                                "label": "Dali0872"
                            },
                            {
                                "code": "0415",
                                "city": "丹东",
                                "pinyin": "Dandong",
                                "label": "Dandong0415"
                            },
                            {
                                "code": "0459",
                                "city": "大庆",
                                "pinyin": "Daqing",
                                "label": "Daqing0459"
                            },
                            {
                                "code": "0352",
                                "city": "大同",
                                "pinyin": "Datong",
                                "label": "Datong0352"
                            },
                            {
                                "code": "0457",
                                "city": "大兴安岭",
                                "pinyin": "Daxinganling",
                                "label": "Daxinganling0457"
                            },
                            {
                                "code": "0818",
                                "city": "达州",
                                "pinyin": "Dazhou",
                                "label": "Dazhou0818"
                            },
                            {
                                "code": "0692",
                                "city": "德宏",
                                "pinyin": "Dehong",
                                "label": "Dehong0692"
                            },
                            {
                                "code": "0838",
                                "city": "德阳",
                                "pinyin": "Deyang",
                                "label": "Deyang0838"
                            },
                            {
                                "code": "0534",
                                "city": "德州",
                                "pinyin": "Dezhou",
                                "label": "Dezhou0534"
                            },
                            {
                                "code": "0932",
                                "city": "定西",
                                "pinyin": "Dingxi",
                                "label": "Dingxi0932"
                            },
                            {
                                "code": "0887",
                                "city": "迪庆",
                                "pinyin": "Diqing",
                                "label": "Diqing0887"
                            },
                            {
                                "code": "0546",
                                "city": "东营",
                                "pinyin": "Dongying",
                                "label": "Dongying0546"
                            },
                            {
                                "code": "0515",
                                "city": "大丰",
                                "pinyin": "Dafeng",
                                "label": "Dafeng0515"
                            },
                            {
                                "code": "0580",
                                "city": "岱山",
                                "pinyin": "Daishan",
                                "label": "Daishan0580"
                            },
                            {
                                "code": "0557",
                                "city": "砀山",
                                "pinyin": "Dangshan",
                                "label": "Dangshan0557"
                            },
                            {
                                "code": "0555",
                                "city": "当涂",
                                "pinyin": "Dangtu",
                                "label": "Dangtu0555"
                            },
                            {
                                "code": "0530",
                                "city": "单县",
                                "pinyin": "Danxian",
                                "label": "Danxian0530"
                            },
                            {
                                "code": "0511",
                                "city": "丹阳",
                                "pinyin": "Danyang",
                                "label": "Danyang0511"
                            },
                            {
                                "code": "0753",
                                "city": "大埔",
                                "pinyin": "Dapu",
                                "label": "Dapu0753"
                            },
                            {
                                "code": "0598",
                                "city": "大田",
                                "pinyin": "Datian",
                                "label": "Datian0598"
                            },
                            {
                                "code": "028",
                                "city": "大邑",
                                "pinyin": "Dayi",
                                "label": "Dayi028"
                            },
                            {
                                "code": "0797",
                                "city": "大余",
                                "pinyin": "Dayu",
                                "label": "Dayu0797"
                            },
                            {
                                "code": "023",
                                "city": "大足",
                                "pinyin": "Dazu",
                                "label": "Dazu023"
                            },
                            {
                                "code": "0792",
                                "city": "德安",
                                "pinyin": "Dean",
                                "label": "Dean0792"
                            },
                            {
                                "code": "0595",
                                "city": "德化",
                                "pinyin": "Dehua",
                                "label": "Dehua0595"
                            },
                            {
                                "code": "0431",
                                "city": "德惠",
                                "pinyin": "Dehui",
                                "label": "Dehui0431"
                            },
                            {
                                "code": "0371",
                                "city": "登封",
                                "pinyin": "Dengfeng",
                                "label": "Dengfeng0371"
                            },
                            {
                                "code": "0572",
                                "city": "德清",
                                "pinyin": "Deqing",
                                "label": "Deqing0572"
                            },
                            {
                                "code": "0758",
                                "city": "德庆",
                                "pinyin": "Deqing",
                                "label": "Deqing0758"
                            },
                            {
                                "code": "0793",
                                "city": "德兴",
                                "pinyin": "Dexing",
                                "label": "Dexing0793"
                            },
                            {
                                "code": "0668",
                                "city": "电白",
                                "pinyin": "Dianbai",
                                "label": "Dianbai0668"
                            },
                            {
                                "code": "023",
                                "city": "垫江",
                                "pinyin": "Dianjiang",
                                "label": "Dianjiang023"
                            },
                            {
                                "code": "0797",
                                "city": "定南",
                                "pinyin": "Dingnan",
                                "label": "Dingnan0797"
                            },
                            {
                                "code": "0530",
                                "city": "定陶",
                                "pinyin": "Dingtao",
                                "label": "Dingtao0530"
                            },
                            {
                                "code": "0550",
                                "city": "定远",
                                "pinyin": "Dingyuan",
                                "label": "Dingyuan0550"
                            },
                            {
                                "code": "0635",
                                "city": "东阿",
                                "pinyin": "Donga",
                                "label": "Donga0635"
                            },
                            {
                                "code": "0518",
                                "city": "东海",
                                "pinyin": "Donghai",
                                "label": "Donghai0518"
                            },
                            {
                                "code": "0530",
                                "city": "东明",
                                "pinyin": "Dongming",
                                "label": "Dongming0530"
                            },
                            {
                                "code": "0538",
                                "city": "东平",
                                "pinyin": "Dongping",
                                "label": "Dongping0538"
                            },
                            {
                                "code": "0596",
                                "city": "东山",
                                "pinyin": "Dongshan",
                                "label": "Dongshan0596"
                            },
                            {
                                "code": "0515",
                                "city": "东台",
                                "pinyin": "Dongtai",
                                "label": "Dongtai0515"
                            },
                            {
                                "code": "0577",
                                "city": "洞头",
                                "pinyin": "Dongtou",
                                "label": "Dongtou0577"
                            },
                            {
                                "code": "0794",
                                "city": "东乡",
                                "pinyin": "Dongxiang",
                                "label": "Dongxiang0794"
                            },
                            {
                                "code": "0579",
                                "city": "东阳",
                                "pinyin": "Dongyang",
                                "label": "Dongyang0579"
                            },
                            {
                                "code": "0762",
                                "city": "东源",
                                "pinyin": "Dongyuan",
                                "label": "Dongyuan0762"
                            },
                            {
                                "code": "0566",
                                "city": "东至",
                                "pinyin": "Dongzhi",
                                "label": "Dongzhi0566"
                            },
                            {
                                "code": "0792",
                                "city": "都昌",
                                "pinyin": "Duchang",
                                "label": "Duchang0792"
                            },
                            {
                                "code": "028",
                                "city": "都江堰",
                                "pinyin": "Dujiangyan",
                                "label": "Dujiangyan028"
                            }
                        ]
                    },
                    {
                        "initial": "E",
                        "list": [
                            {
                                "code": "0718",
                                "city": "恩施",
                                "pinyin": "Enshi",
                                "label": "Enshi0718"
                            },
                            {
                                "code": "0477",
                                "city": "鄂尔多斯",
                                "pinyin": "Eerduosi",
                                "label": "Eerduosi0477"
                            },
                            {
                                "code": "0711",
                                "city": "鄂州",
                                "pinyin": "Ezhou",
                                "label": "Ezhou0711"
                            },
                            {
                                "code": "0750",
                                "city": "恩平",
                                "pinyin": "Enping",
                                "label": "Enping0750"
                            }
                        ]
                    },
                    {
                        "initial": "F",
                        "list": [
                            {
                                "code": "0413",
                                "city": "抚顺",
                                "pinyin": "Fushun",
                                "label": "Fushun0413"
                            },
                            {
                                "code": "0757",
                                "city": "佛山",
                                "pinyin": "Foshan",
                                "label": "Foshan0757"
                            },
                            {
                                "code": "0591",
                                "city": "福州",
                                "pinyin": "Fuzhou",
                                "label": "Fuzhou0591"
                            },
                            {
                                "code": "0770",
                                "city": "防城港",
                                "pinyin": "Fangchenggang",
                                "label": "Fangchenggang0770"
                            },
                            {
                                "code": "0418",
                                "city": "阜新",
                                "pinyin": "Fuxin",
                                "label": "Fuxin0418"
                            },
                            {
                                "code": "0558",
                                "city": "阜阳",
                                "pinyin": "Fuyang",
                                "label": "Fuyang0558"
                            },
                            {
                                "code": "0794",
                                "city": "抚州",
                                "pinyin": "Fuzhou",
                                "label": "Fuzhou0794"
                            },
                            {
                                "code": "024",
                                "city": "法库",
                                "pinyin": "Faku",
                                "label": "Faku024"
                            },
                            {
                                "code": "0553",
                                "city": "繁昌",
                                "pinyin": "Fanchang",
                                "label": "Fanchang0553"
                            },
                            {
                                "code": "0451",
                                "city": "方正",
                                "pinyin": "Fangzheng",
                                "label": "Fangzheng0451"
                            },
                            {
                                "code": "0538",
                                "city": "肥城",
                                "pinyin": "Feicheng",
                                "label": "Feicheng0538"
                            },
                            {
                                "code": "0551",
                                "city": "肥东",
                                "pinyin": "Feidong",
                                "label": "Feidong0551"
                            },
                            {
                                "code": "0551",
                                "city": "肥西",
                                "pinyin": "Feixi",
                                "label": "Feixi0551"
                            },
                            {
                                "code": "0539",
                                "city": "费县",
                                "pinyin": "Feixian",
                                "label": "Feixian0539"
                            },
                            {
                                "code": "0795",
                                "city": "丰城",
                                "pinyin": "Fengcheng",
                                "label": "Fengcheng0795"
                            },
                            {
                                "code": "023",
                                "city": "丰都",
                                "pinyin": "Fengdu",
                                "label": "Fengdu023"
                            },
                            {
                                "code": "0574",
                                "city": "奉化",
                                "pinyin": "Fenghua",
                                "label": "Fenghua0574"
                            },
                            {
                                "code": "023",
                                "city": "奉节",
                                "pinyin": "Fengjie",
                                "label": "Fengjie023"
                            },
                            {
                                "code": "0758",
                                "city": "封开",
                                "pinyin": "Fengkai",
                                "label": "Fengkai0758"
                            },
                            {
                                "code": "0753",
                                "city": "丰顺",
                                "pinyin": "Fengshun",
                                "label": "Fengshun0753"
                            },
                            {
                                "code": "0554",
                                "city": "凤台",
                                "pinyin": "Fengtai",
                                "label": "Fengtai0554"
                            },
                            {
                                "code": "0516",
                                "city": "丰县",
                                "pinyin": "Fengxian",
                                "label": "Fengxian0516"
                            },
                            {
                                "code": "0795",
                                "city": "奉新",
                                "pinyin": "Fengxin",
                                "label": "Fengxin0795"
                            },
                            {
                                "code": "0550",
                                "city": "凤阳",
                                "pinyin": "Fengyang",
                                "label": "Fengyang0550"
                            },
                            {
                                "code": "0790",
                                "city": "分宜",
                                "pinyin": "Fenyi",
                                "label": "Fenyi0790"
                            },
                            {
                                "code": "0763",
                                "city": "佛冈",
                                "pinyin": "Fogang",
                                "label": "Fogang0763"
                            },
                            {
                                "code": "0593",
                                "city": "福安",
                                "pinyin": "Fuan",
                                "label": "Fuan0593"
                            },
                            {
                                "code": "0593",
                                "city": "福鼎",
                                "pinyin": "Fuding",
                                "label": "Fuding0593"
                            },
                            {
                                "code": "0798",
                                "city": "浮梁",
                                "pinyin": "Fuliang",
                                "label": "Fuliang0798"
                            },
                            {
                                "code": "0871",
                                "city": "富民",
                                "pinyin": "Fumin",
                                "label": "Fumin0871"
                            },
                            {
                                "code": "0558",
                                "city": "阜南",
                                "pinyin": "Funan",
                                "label": "Funan0558"
                            },
                            {
                                "code": "0515",
                                "city": "阜宁",
                                "pinyin": "Funing",
                                "label": "Funing0515"
                            },
                            {
                                "code": "0591",
                                "city": "福清",
                                "pinyin": "Fuqing",
                                "label": "Fuqing0591"
                            },
                            {
                                "code": "0571",
                                "city": "富阳",
                                "pinyin": "Fuyang",
                                "label": "Fuyang0571"
                            }
                        ]
                    },
                    {
                        "initial": "G",
                        "list": [
                            {
                                "code": "0941",
                                "city": "甘南",
                                "pinyin": "Gannan",
                                "label": "Gannan0941"
                            },
                            {
                                "code": "020",
                                "city": "广州",
                                "pinyin": "Guangzhou",
                                "label": "Guangzhou020"
                            },
                            {
                                "code": "0851",
                                "city": "贵阳",
                                "pinyin": "Guiyang",
                                "label": "Guiyang0851"
                            },
                            {
                                "code": "0797",
                                "city": "赣州",
                                "pinyin": "Ganzhou",
                                "label": "Ganzhou0797"
                            },
                            {
                                "code": "0836",
                                "city": "甘孜",
                                "pinyin": "Ganzi",
                                "label": "Ganzi0836"
                            },
                            {
                                "code": "0826",
                                "city": "广安",
                                "pinyin": "Guangan",
                                "label": "Guangan0826"
                            },
                            {
                                "code": "0839",
                                "city": "广元",
                                "pinyin": "Guangyuan",
                                "label": "Guangyuan0839"
                            },
                            {
                                "code": "0775",
                                "city": "贵港",
                                "pinyin": "Guigang",
                                "label": "Guigang0775"
                            },
                            {
                                "code": "0773",
                                "city": "桂林",
                                "pinyin": "Guilin",
                                "label": "Guilin0773"
                            },
                            {
                                "code": "0975",
                                "city": "果洛",
                                "pinyin": "Guoluo",
                                "label": "Guoluo0975"
                            },
                            {
                                "code": "0954",
                                "city": "固原",
                                "pinyin": "Guyuan",
                                "label": "Guyuan0954"
                            },
                            {
                                "code": "0797",
                                "city": "赣县",
                                "pinyin": "Ganxian",
                                "label": "Ganxian0797"
                            },
                            {
                                "code": "0518",
                                "city": "赣榆",
                                "pinyin": "Ganyu",
                                "label": "Ganyu0518"
                            },
                            {
                                "code": "0795",
                                "city": "高安",
                                "pinyin": "Gaoan",
                                "label": "Gaoan0795"
                            },
                            {
                                "code": "0311",
                                "city": "藁城",
                                "pinyin": "Gaocheng",
                                "label": "Gaocheng0311"
                            },
                            {
                                "code": "025",
                                "city": "高淳",
                                "pinyin": "Gaochun",
                                "label": "Gaochun025"
                            },
                            {
                                "code": "0931",
                                "city": "皋兰",
                                "pinyin": "Gaolan",
                                "label": "Gaolan0931"
                            },
                            {
                                "code": "029",
                                "city": "高陵",
                                "pinyin": "Gaoling",
                                "label": "Gaoling029"
                            },
                            {
                                "code": "0536",
                                "city": "高密",
                                "pinyin": "Gaomi",
                                "label": "Gaomi0536"
                            },
                            {
                                "code": "0533",
                                "city": "高青",
                                "pinyin": "Gaoqing",
                                "label": "Gaoqing0533"
                            },
                            {
                                "code": "0635",
                                "city": "高唐",
                                "pinyin": "Gaotang",
                                "label": "Gaotang0635"
                            },
                            {
                                "code": "0758",
                                "city": "高要",
                                "pinyin": "Gaoyao",
                                "label": "Gaoyao0758"
                            },
                            {
                                "code": "0311",
                                "city": "高邑",
                                "pinyin": "Gaoyi",
                                "label": "Gaoyi0311"
                            },
                            {
                                "code": "0514",
                                "city": "高邮",
                                "pinyin": "Gaoyou",
                                "label": "Gaoyou0514"
                            },
                            {
                                "code": "0668",
                                "city": "高州",
                                "pinyin": "Gaozhou",
                                "label": "Gaozhou0668"
                            },
                            {
                                "code": "0371",
                                "city": "巩义",
                                "pinyin": "Gongyi",
                                "label": "Gongyi0371"
                            },
                            {
                                "code": "0794",
                                "city": "广昌",
                                "pinyin": "Guangchang",
                                "label": "Guangchang0794"
                            },
                            {
                                "code": "0563",
                                "city": "广德",
                                "pinyin": "Guangde",
                                "label": "Guangde0563"
                            },
                            {
                                "code": "0793",
                                "city": "广丰",
                                "pinyin": "Guangfeng",
                                "label": "Guangfeng0793"
                            },
                            {
                                "code": "0758",
                                "city": "广宁",
                                "pinyin": "Guangning",
                                "label": "Guangning0758"
                            },
                            {
                                "code": "0546",
                                "city": "广饶",
                                "pinyin": "Guangrao",
                                "label": "Guangrao0546"
                            },
                            {
                                "code": "0599",
                                "city": "光泽",
                                "pinyin": "Guangze",
                                "label": "Guangze0599"
                            },
                            {
                                "code": "0518",
                                "city": "灌南",
                                "pinyin": "Guannan",
                                "label": "Guannan0518"
                            },
                            {
                                "code": "0635",
                                "city": "冠县",
                                "pinyin": "Guanxian",
                                "label": "Guanxian0635"
                            },
                            {
                                "code": "0518",
                                "city": "灌云",
                                "pinyin": "Guanyun",
                                "label": "Guanyun0518"
                            },
                            {
                                "code": "0701",
                                "city": "贵溪",
                                "pinyin": "Guixi",
                                "label": "Guixi0701"
                            },
                            {
                                "code": "0593",
                                "city": "古田",
                                "pinyin": "Gutian",
                                "label": "Gutian0593"
                            },
                            {
                                "code": "0552",
                                "city": "固镇",
                                "pinyin": "Guzhen",
                                "label": "Guzhen0552"
                            }
                        ]
                    },
                    {
                        "initial": "H",
                        "list": [
                            {
                                "code": "0970",
                                "city": "海北",
                                "pinyin": "Haibei",
                                "label": "Haibei0970"
                            },
                            {
                                "code": "0451",
                                "city": "哈尔滨",
                                "pinyin": "Haerbin",
                                "label": "Haerbin0451"
                            },
                            {
                                "code": "0898",
                                "city": "海口",
                                "pinyin": "Haikou",
                                "label": "Haikou0898"
                            },
                            {
                                "code": "0310",
                                "city": "邯郸",
                                "pinyin": "Handan",
                                "label": "Handan0310"
                            },
                            {
                                "code": "0571",
                                "city": "杭州",
                                "pinyin": "Hangzhou",
                                "label": "Hangzhou0571"
                            },
                            {
                                "code": "0551",
                                "city": "合肥",
                                "pinyin": "Hefei",
                                "label": "Hefei0551"
                            },
                            {
                                "code": "0752",
                                "city": "惠州",
                                "pinyin": "Huizhou",
                                "label": "Huizhou0752"
                            },
                            {
                                "code": "0972",
                                "city": "海东",
                                "pinyin": "Haidong",
                                "label": "Haidong0972"
                            },
                            {
                                "code": "0974",
                                "city": "海南",
                                "pinyin": "Hainan",
                                "label": "Hainan0974"
                            },
                            {
                                "code": "0977",
                                "city": "海西",
                                "pinyin": "Haixi",
                                "label": "Haixi0977"
                            },
                            {
                                "code": "0902",
                                "city": "哈密",
                                "pinyin": "Hami",
                                "label": "Hami0902"
                            },
                            {
                                "code": "0916",
                                "city": "汉中",
                                "pinyin": "Hanzhong",
                                "label": "Hanzhong0916"
                            },
                            {
                                "code": "0392",
                                "city": "鹤壁",
                                "pinyin": "Hebi",
                                "label": "Hebi0392"
                            },
                            {
                                "code": "0778",
                                "city": "河池",
                                "pinyin": "Hechi",
                                "label": "Hechi0778"
                            },
                            {
                                "code": "0468",
                                "city": "鹤岗",
                                "pinyin": "Hegang",
                                "label": "Hegang0468"
                            },
                            {
                                "code": "0456",
                                "city": "黑河",
                                "pinyin": "Heihe",
                                "label": "Heihe0456"
                            },
                            {
                                "code": "0318",
                                "city": "衡水",
                                "pinyin": "Hengshui",
                                "label": "Hengshui0318"
                            },
                            {
                                "code": "0734",
                                "city": "衡阳",
                                "pinyin": "Hengyang",
                                "label": "Hengyang0734"
                            },
                            {
                                "code": "0903",
                                "city": "和田地",
                                "pinyin": "Hetiandi",
                                "label": "Hetiandi0903"
                            },
                            {
                                "code": "0762",
                                "city": "河源",
                                "pinyin": "Heyuan",
                                "label": "Heyuan0762"
                            },
                            {
                                "code": "0530",
                                "city": "菏泽",
                                "pinyin": "Heze",
                                "label": "Heze0530"
                            },
                            {
                                "code": "0774",
                                "city": "贺州",
                                "pinyin": "Hezhou",
                                "label": "Hezhou0774"
                            },
                            {
                                "code": "0873",
                                "city": "红河",
                                "pinyin": "Honghe",
                                "label": "Honghe0873"
                            },
                            {
                                "code": "0517",
                                "city": "淮安",
                                "pinyin": "Huaian",
                                "label": "Huaian0517"
                            },
                            {
                                "code": "0561",
                                "city": "淮北",
                                "pinyin": "Huaibei",
                                "label": "Huaibei0561"
                            },
                            {
                                "code": "0745",
                                "city": "怀化",
                                "pinyin": "Huaihua",
                                "label": "Huaihua0745"
                            },
                            {
                                "code": "0554",
                                "city": "淮南",
                                "pinyin": "Huainan",
                                "label": "Huainan0554"
                            },
                            {
                                "code": "0713",
                                "city": "黄冈",
                                "pinyin": "Huanggang",
                                "label": "Huanggang0713"
                            },
                            {
                                "code": "0973",
                                "city": "黄南",
                                "pinyin": "Huangnan",
                                "label": "Huangnan0973"
                            },
                            {
                                "code": "0559",
                                "city": "黄山",
                                "pinyin": "Huangshan",
                                "label": "Huangshan0559"
                            },
                            {
                                "code": "0714",
                                "city": "黄石",
                                "pinyin": "Huangshi",
                                "label": "Huangshi0714"
                            },
                            {
                                "code": "0471",
                                "city": "呼和浩特",
                                "pinyin": "Huhehaote",
                                "label": "Huhehaote0471"
                            },
                            {
                                "code": "0429",
                                "city": "葫芦岛",
                                "pinyin": "Huludao",
                                "label": "Huludao0429"
                            },
                            {
                                "code": "0470",
                                "city": "呼伦贝尔",
                                "pinyin": "Hulunbeier",
                                "label": "Hulunbeier0470"
                            },
                            {
                                "code": "0572",
                                "city": "湖州",
                                "pinyin": "Huzhou",
                                "label": "Huzhou0572"
                            },
                            {
                                "code": "0513",
                                "city": "海安",
                                "pinyin": "Haian",
                                "label": "Haian0513"
                            },
                            {
                                "code": "0660",
                                "city": "海丰",
                                "pinyin": "Haifeng",
                                "label": "Haifeng0660"
                            },
                            {
                                "code": "0513",
                                "city": "海门",
                                "pinyin": "Haimen",
                                "label": "Haimen0513"
                            },
                            {
                                "code": "0573",
                                "city": "海宁",
                                "pinyin": "Haining",
                                "label": "Haining0573"
                            },
                            {
                                "code": "0573",
                                "city": "海盐",
                                "pinyin": "Haiyan",
                                "label": "Haiyan0573"
                            },
                            {
                                "code": "0535",
                                "city": "海阳",
                                "pinyin": "Haiyang",
                                "label": "Haiyang0535"
                            },
                            {
                                "code": "0565",
                                "city": "含山",
                                "pinyin": "Hanshan",
                                "label": "Hanshan0565"
                            },
                            {
                                "code": "023",
                                "city": "合川",
                                "pinyin": "Hechuan",
                                "label": "Hechuan023"
                            },
                            {
                                "code": "0793",
                                "city": "横峰",
                                "pinyin": "Hengfeng",
                                "label": "Hengfeng0793"
                            },
                            {
                                "code": "0771",
                                "city": "横县",
                                "pinyin": "Hengxian",
                                "label": "Hengxian0771"
                            },
                            {
                                "code": "0762",
                                "city": "和平",
                                "pinyin": "Heping",
                                "label": "Heping0762"
                            },
                            {
                                "code": "0750",
                                "city": "鹤山",
                                "pinyin": "Heshan",
                                "label": "Heshan0750"
                            },
                            {
                                "code": "0565",
                                "city": "和县",
                                "pinyin": "Hexian",
                                "label": "Hexian0565"
                            },
                            {
                                "code": "0517",
                                "city": "洪泽",
                                "pinyin": "Hongze",
                                "label": "Hongze0517"
                            },
                            {
                                "code": "0596",
                                "city": "华安",
                                "pinyin": "Huaan",
                                "label": "Huaan0596"
                            },
                            {
                                "code": "0423",
                                "city": "桦甸",
                                "pinyin": "Huadian",
                                "label": "Huadian0423"
                            },
                            {
                                "code": "0758",
                                "city": "怀集",
                                "pinyin": "Huaiji",
                                "label": "Huaiji0758"
                            },
                            {
                                "code": "0556",
                                "city": "怀宁",
                                "pinyin": "Huaining",
                                "label": "Huaining0556"
                            },
                            {
                                "code": "0552",
                                "city": "怀远",
                                "pinyin": "Huaiyuan",
                                "label": "Huaiyuan0552"
                            },
                            {
                                "code": "0533",
                                "city": "桓台",
                                "pinyin": "Huantai",
                                "label": "Huantai0533"
                            },
                            {
                                "code": "0668",
                                "city": "化州",
                                "pinyin": "Huazhou",
                                "label": "Huazhou0668"
                            },
                            {
                                "code": "0595",
                                "city": "惠安",
                                "pinyin": "Huian",
                                "label": "Huian0595"
                            },
                            {
                                "code": "0797",
                                "city": "会昌",
                                "pinyin": "Huichang",
                                "label": "Huichang0797"
                            },
                            {
                                "code": "0752",
                                "city": "惠东",
                                "pinyin": "Huidong",
                                "label": "Huidong0752"
                            },
                            {
                                "code": "0663",
                                "city": "惠来",
                                "pinyin": "Huilai",
                                "label": "Huilai0663"
                            },
                            {
                                "code": "0543",
                                "city": "惠民",
                                "pinyin": "Huimin",
                                "label": "Huimin0543"
                            },
                            {
                                "code": "0792",
                                "city": "湖口",
                                "pinyin": "Hukou",
                                "label": "Hukou0792"
                            },
                            {
                                "code": "0451",
                                "city": "呼兰",
                                "pinyin": "Hulan",
                                "label": "Hulan0451"
                            },
                            {
                                "code": "0564",
                                "city": "霍邱",
                                "pinyin": "Huoqiu",
                                "label": "Huoqiu0564"
                            },
                            {
                                "code": "0564",
                                "city": "霍山",
                                "pinyin": "Huoshan",
                                "label": "Huoshan0564"
                            },
                            {
                                "code": "029",
                                "city": "户县",
                                "pinyin": "Huxian",
                                "label": "Huxian029"
                            }
                        ]
                    },
                    {
                        "initial": "J",
                        "list": [
                            {
                                "code": "0454",
                                "city": "佳木斯",
                                "pinyin": "Jiamusi",
                                "label": "Jiamusi0454"
                            },
                            {
                                "code": "0391",
                                "city": "焦作",
                                "pinyin": "Jiaozuo",
                                "label": "Jiaozuo0391"
                            },
                            {
                                "code": "0573",
                                "city": "嘉兴",
                                "pinyin": "Jiaxing",
                                "label": "Jiaxing0573"
                            },
                            {
                                "code": "0423",
                                "city": "吉林",
                                "pinyin": "Jilin",
                                "label": "Jilin0423"
                            },
                            {
                                "code": "0531",
                                "city": "济南",
                                "pinyin": "Jinan",
                                "label": "Jinan0531"
                            },
                            {
                                "code": "0750",
                                "city": "江门",
                                "pinyin": "Jiangmen",
                                "label": "Jiangmen0750"
                            },
                            {
                                "code": "0796",
                                "city": "吉安",
                                "pinyin": "Jian",
                                "label": "Jian0796"
                            },
                            {
                                "code": "0937",
                                "city": "嘉峪关",
                                "pinyin": "Jiayuguan",
                                "label": "Jiayuguan0937"
                            },
                            {
                                "code": "0663",
                                "city": "揭阳",
                                "pinyin": "Jieyang",
                                "label": "Jieyang0663"
                            },
                            {
                                "code": "0935",
                                "city": "金昌",
                                "pinyin": "Jinchang",
                                "label": "Jinchang0935"
                            },
                            {
                                "code": "0356",
                                "city": "晋城",
                                "pinyin": "Jincheng",
                                "label": "Jincheng0356"
                            },
                            {
                                "code": "0798",
                                "city": "景德镇",
                                "pinyin": "Jingdezhen",
                                "label": "Jingdezhen0798"
                            },
                            {
                                "code": "0724",
                                "city": "荆门",
                                "pinyin": "Jingmen",
                                "label": "Jingmen0724"
                            },
                            {
                                "code": "0716",
                                "city": "荆州",
                                "pinyin": "Jingzhou",
                                "label": "Jingzhou0716"
                            },
                            {
                                "code": "0579",
                                "city": "金华",
                                "pinyin": "Jinhua",
                                "label": "Jinhua0579"
                            },
                            {
                                "code": "0537",
                                "city": "济宁",
                                "pinyin": "Jining",
                                "label": "Jining0537"
                            },
                            {
                                "code": "0354",
                                "city": "晋中",
                                "pinyin": "Jinzhong",
                                "label": "Jinzhong0354"
                            },
                            {
                                "code": "0416",
                                "city": "锦州",
                                "pinyin": "Jinzhou",
                                "label": "Jinzhou0416"
                            },
                            {
                                "code": "0792",
                                "city": "九江",
                                "pinyin": "Jiujiang",
                                "label": "Jiujiang0792"
                            },
                            {
                                "code": "0937",
                                "city": "酒泉",
                                "pinyin": "Jiuquan",
                                "label": "Jiuquan0937"
                            },
                            {
                                "code": "0467",
                                "city": "鸡西",
                                "pinyin": "Jixi",
                                "label": "Jixi0467"
                            },
                            {
                                "code": "0571",
                                "city": "建德",
                                "pinyin": "Jiande",
                                "label": "Jiande0571"
                            },
                            {
                                "code": "0514",
                                "city": "江都",
                                "pinyin": "Jiangdu",
                                "label": "Jiangdu0514"
                            },
                            {
                                "code": "023",
                                "city": "江津",
                                "pinyin": "Jiangjin",
                                "label": "Jiangjin023"
                            },
                            {
                                "code": "0598",
                                "city": "将乐",
                                "pinyin": "Jiangle",
                                "label": "Jiangle0598"
                            },
                            {
                                "code": "0570",
                                "city": "江山",
                                "pinyin": "Jiangshan",
                                "label": "Jiangshan0570"
                            },
                            {
                                "code": "0523",
                                "city": "姜堰",
                                "pinyin": "Jiangyan",
                                "label": "Jiangyan0523"
                            },
                            {
                                "code": "0510",
                                "city": "江阴",
                                "pinyin": "Jiangyin",
                                "label": "Jiangyin0510"
                            },
                            {
                                "code": "0515",
                                "city": "建湖",
                                "pinyin": "Jianhu",
                                "label": "Jianhu0515"
                            },
                            {
                                "code": "0598",
                                "city": "建宁",
                                "pinyin": "Jianning",
                                "label": "Jianning0598"
                            },
                            {
                                "code": "0599",
                                "city": "建瓯",
                                "pinyin": "Jianou",
                                "label": "Jianou0599"
                            },
                            {
                                "code": "0599",
                                "city": "建阳",
                                "pinyin": "Jianyang",
                                "label": "Jianyang0599"
                            },
                            {
                                "code": "0796",
                                "city": "吉安",
                                "pinyin": "Jian",
                                "label": "Jian0796"
                            },
                            {
                                "code": "0423",
                                "city": "蛟河",
                                "pinyin": "Jiaohe",
                                "label": "Jiaohe0423"
                            },
                            {
                                "code": "0753",
                                "city": "蕉岭",
                                "pinyin": "Jiaoling",
                                "label": "Jiaoling0753"
                            },
                            {
                                "code": "0532",
                                "city": "胶南",
                                "pinyin": "Jiaonan",
                                "label": "Jiaonan0532"
                            },
                            {
                                "code": "0532",
                                "city": "胶州",
                                "pinyin": "Jiaozhou",
                                "label": "Jiaozhou0532"
                            },
                            {
                                "code": "0573",
                                "city": "嘉善",
                                "pinyin": "Jiashan",
                                "label": "Jiashan0573"
                            },
                            {
                                "code": "0537",
                                "city": "嘉祥",
                                "pinyin": "Jiaxiang",
                                "label": "Jiaxiang0537"
                            },
                            {
                                "code": "0663",
                                "city": "揭东",
                                "pinyin": "Jiedong",
                                "label": "Jiedong0663"
                            },
                            {
                                "code": "0558",
                                "city": "界首",
                                "pinyin": "Jieshou",
                                "label": "Jieshou0558"
                            },
                            {
                                "code": "0663",
                                "city": "揭西",
                                "pinyin": "Jiexi",
                                "label": "Jiexi0663"
                            },
                            {
                                "code": "0532",
                                "city": "即墨",
                                "pinyin": "Jimo",
                                "label": "Jimo0532"
                            },
                            {
                                "code": "0795",
                                "city": "靖安",
                                "pinyin": "Jingan",
                                "label": "Jingan0795"
                            },
                            {
                                "code": "0563",
                                "city": "旌德",
                                "pinyin": "Jingde",
                                "label": "Jingde0563"
                            },
                            {
                                "code": "0796",
                                "city": "井冈山",
                                "pinyin": "Jinggangshan",
                                "label": "Jinggangshan0796"
                            },
                            {
                                "code": "0523",
                                "city": "靖江",
                                "pinyin": "Jingjiang",
                                "label": "Jingjiang0523"
                            },
                            {
                                "code": "0578",
                                "city": "景宁",
                                "pinyin": "Jingning",
                                "label": "Jingning0578"
                            },
                            {
                                "code": "0563",
                                "city": "泾县",
                                "pinyin": "Jingxian",
                                "label": "Jingxian0563"
                            },
                            {
                                "code": "0311",
                                "city": "井陉",
                                "pinyin": "Jingxing",
                                "label": "Jingxing0311"
                            },
                            {
                                "code": "0517",
                                "city": "金湖",
                                "pinyin": "Jinhu",
                                "label": "Jinhu0517"
                            },
                            {
                                "code": "0595",
                                "city": "晋江",
                                "pinyin": "Jinjiang",
                                "label": "Jinjiang0595"
                            },
                            {
                                "code": "0595",
                                "city": "金门",
                                "pinyin": "Jinmen",
                                "label": "Jinmen0595"
                            },
                            {
                                "code": "0871",
                                "city": "晋宁",
                                "pinyin": "Jinning",
                                "label": "Jinning0871"
                            },
                            {
                                "code": "0519",
                                "city": "金坛",
                                "pinyin": "Jintan",
                                "label": "Jintan0519"
                            },
                            {
                                "code": "028",
                                "city": "金堂",
                                "pinyin": "Jintang",
                                "label": "Jintang028"
                            },
                            {
                                "code": "0791",
                                "city": "进贤",
                                "pinyin": "Jinxian",
                                "label": "Jinxian0791"
                            },
                            {
                                "code": "0794",
                                "city": "金溪",
                                "pinyin": "Jinxi",
                                "label": "Jinxi0794"
                            },
                            {
                                "code": "0537",
                                "city": "金乡",
                                "pinyin": "Jinxiang",
                                "label": "Jinxiang0537"
                            },
                            {
                                "code": "0578",
                                "city": "缙云",
                                "pinyin": "Jinyun",
                                "label": "Jinyun0578"
                            },
                            {
                                "code": "0564",
                                "city": "金寨",
                                "pinyin": "Jinzhai",
                                "label": "Jinzhai0564"
                            },
                            {
                                "code": "0311",
                                "city": "晋州",
                                "pinyin": "Jinzhou",
                                "label": "Jinzhou0311"
                            },
                            {
                                "code": "0796",
                                "city": "吉水",
                                "pinyin": "Jishui",
                                "label": "Jishui0796"
                            },
                            {
                                "code": "0792",
                                "city": "九江",
                                "pinyin": "Jiujiang",
                                "label": "Jiujiang0792"
                            },
                            {
                                "code": "0431",
                                "city": "九台",
                                "pinyin": "Jiutai",
                                "label": "Jiutai0431"
                            },
                            {
                                "code": "0563",
                                "city": "绩溪",
                                "pinyin": "Jixi",
                                "label": "Jixi0563"
                            },
                            {
                                "code": "0531",
                                "city": "济阳",
                                "pinyin": "Jiyang",
                                "label": "Jiyang0531"
                            },
                            {
                                "code": "0391",
                                "city": "济源",
                                "pinyin": "Jiyuan",
                                "label": "Jiyuan0391"
                            },
                            {
                                "code": "0530",
                                "city": "鄄城",
                                "pinyin": "Juancheng",
                                "label": "Juancheng0530"
                            },
                            {
                                "code": "0539",
                                "city": "莒南",
                                "pinyin": "Junan",
                                "label": "Junan0539"
                            },
                            {
                                "code": "0511",
                                "city": "句容",
                                "pinyin": "Jurong",
                                "label": "Jurong0511"
                            },
                            {
                                "code": "0633",
                                "city": "莒县",
                                "pinyin": "Juxian",
                                "label": "Juxian0633"
                            },
                            {
                                "code": "0530",
                                "city": "巨野",
                                "pinyin": "Juye",
                                "label": "Juye0530"
                            }
                        ]
                    },
                    {
                        "initial": "K",
                        "list": [
                            {
                                "code": "0378",
                                "city": "开封",
                                "pinyin": "Kaifeng",
                                "label": "Kaifeng0378"
                            },
                            {
                                "code": "0871",
                                "city": "昆明",
                                "pinyin": "Kunming",
                                "label": "Kunming0871"
                            },
                            {
                                "code": "0998",
                                "city": "喀什地",
                                "pinyin": "Kashidi",
                                "label": "Kashidi0998"
                            },
                            {
                                "code": "0990",
                                "city": "克拉玛依",
                                "pinyin": "Kelamayi",
                                "label": "Kelamayi0990"
                            },
                            {
                                "code": "0908",
                                "city": "克孜勒",
                                "pinyin": "Kezile",
                                "label": "Kezile0908"
                            },
                            {
                                "code": "0570",
                                "city": "开化",
                                "pinyin": "Kaihua",
                                "label": "Kaihua0570"
                            },
                            {
                                "code": "0750",
                                "city": "开平",
                                "pinyin": "Kaiping",
                                "label": "Kaiping0750"
                            },
                            {
                                "code": "023",
                                "city": "开县",
                                "pinyin": "Kaixian",
                                "label": "Kaixian023"
                            },
                            {
                                "code": "0851",
                                "city": "开阳",
                                "pinyin": "Kaiyang",
                                "label": "Kaiyang0851"
                            },
                            {
                                "code": "024",
                                "city": "康平",
                                "pinyin": "Kangping",
                                "label": "Kangping024"
                            },
                            {
                                "code": "0546",
                                "city": "垦利",
                                "pinyin": "Kenli",
                                "label": "Kenli0546"
                            },
                            {
                                "code": "0512",
                                "city": "昆山",
                                "pinyin": "Kunshan",
                                "label": "Kunshan0512"
                            }
                        ]
                    },
                    {
                        "initial": "L",
                        "list": [
                            {
                                "code": "0772",
                                "city": "来宾",
                                "pinyin": "Laibin",
                                "label": "Laibin0772"
                            },
                            {
                                "code": "0931",
                                "city": "兰州",
                                "pinyin": "Lanzhou",
                                "label": "Lanzhou0931"
                            },
                            {
                                "code": "0772",
                                "city": "柳州",
                                "pinyin": "Liuzhou",
                                "label": "Liuzhou0772"
                            },
                            {
                                "code": "0379",
                                "city": "洛阳",
                                "pinyin": "Luoyang",
                                "label": "Luoyang0379"
                            },
                            {
                                "code": "0634",
                                "city": "莱芜",
                                "pinyin": "Laiwu",
                                "label": "Laiwu0634"
                            },
                            {
                                "code": "0316",
                                "city": "廊坊",
                                "pinyin": "Langfang",
                                "label": "Langfang0316"
                            },
                            {
                                "code": "0891",
                                "city": "拉萨",
                                "pinyin": "Lasa",
                                "label": "Lasa0891"
                            },
                            {
                                "code": "0833",
                                "city": "乐山",
                                "pinyin": "Leshan",
                                "label": "Leshan0833"
                            },
                            {
                                "code": "0834",
                                "city": "凉山",
                                "pinyin": "Liangshan",
                                "label": "Liangshan0834"
                            },
                            {
                                "code": "0518",
                                "city": "连云港",
                                "pinyin": "Lianyungang",
                                "label": "Lianyungang0518"
                            },
                            {
                                "code": "0635",
                                "city": "聊城",
                                "pinyin": "Liaocheng",
                                "label": "Liaocheng0635"
                            },
                            {
                                "code": "0419",
                                "city": "辽阳",
                                "pinyin": "Liaoyang",
                                "label": "Liaoyang0419"
                            },
                            {
                                "code": "0437",
                                "city": "辽源",
                                "pinyin": "Liaoyuan",
                                "label": "Liaoyuan0437"
                            },
                            {
                                "code": "0888",
                                "city": "丽江",
                                "pinyin": "Lijiang",
                                "label": "Lijiang0888"
                            },
                            {
                                "code": "0883",
                                "city": "临沧",
                                "pinyin": "Lincang",
                                "label": "Lincang0883"
                            },
                            {
                                "code": "0357",
                                "city": "临汾",
                                "pinyin": "Linfen",
                                "label": "Linfen0357"
                            },
                            {
                                "code": "0930",
                                "city": "临夏",
                                "pinyin": "Linxia",
                                "label": "Linxia0930"
                            },
                            {
                                "code": "0539",
                                "city": "临沂",
                                "pinyin": "Linyi",
                                "label": "Linyi0539"
                            },
                            {
                                "code": "0894",
                                "city": "林芝",
                                "pinyin": "Linzhi",
                                "label": "Linzhi0894"
                            },
                            {
                                "code": "0578",
                                "city": "丽水",
                                "pinyin": "Lishui",
                                "label": "Lishui0578"
                            },
                            {
                                "code": "0564",
                                "city": "六安",
                                "pinyin": "Liuan",
                                "label": "Liuan0564"
                            },
                            {
                                "code": "0858",
                                "city": "六盘水",
                                "pinyin": "Liupanshui",
                                "label": "Liupanshui0858"
                            },
                            {
                                "code": "0939",
                                "city": "陇南",
                                "pinyin": "Longnan",
                                "label": "Longnan0939"
                            },
                            {
                                "code": "0597",
                                "city": "龙岩",
                                "pinyin": "Longyan",
                                "label": "Longyan0597"
                            },
                            {
                                "code": "0738",
                                "city": "娄底",
                                "pinyin": "Loudi",
                                "label": "Loudi0738"
                            },
                            {
                                "code": "0395",
                                "city": "漯河",
                                "pinyin": "Luohe",
                                "label": "Luohe0395"
                            },
                            {
                                "code": "0830",
                                "city": "泸州",
                                "pinyin": "Luzhou",
                                "label": "Luzhou0830"
                            },
                            {
                                "code": "0358",
                                "city": "吕梁",
                                "pinyin": "Lvliang",
                                "label": "Lvliang0358"
                            },
                            {
                                "code": "0550",
                                "city": "来安",
                                "pinyin": "Laian",
                                "label": "Laian0550"
                            },
                            {
                                "code": "0532",
                                "city": "莱西",
                                "pinyin": "Laixi",
                                "label": "Laixi0532"
                            },
                            {
                                "code": "0535",
                                "city": "莱阳",
                                "pinyin": "Laiyang",
                                "label": "Laiyang0535"
                            },
                            {
                                "code": "0535",
                                "city": "莱州",
                                "pinyin": "Laizhou",
                                "label": "Laizhou0535"
                            },
                            {
                                "code": "0563",
                                "city": "郎溪",
                                "pinyin": "Langxi",
                                "label": "Langxi0563"
                            },
                            {
                                "code": "029",
                                "city": "蓝田",
                                "pinyin": "Lantian",
                                "label": "Lantian029"
                            },
                            {
                                "code": "0579",
                                "city": "兰溪",
                                "pinyin": "Lanxi",
                                "label": "Lanxi0579"
                            },
                            {
                                "code": "0794",
                                "city": "乐安",
                                "pinyin": "Lean",
                                "label": "Lean0794"
                            },
                            {
                                "code": "0751",
                                "city": "乐昌",
                                "pinyin": "Lechang",
                                "label": "Lechang0751"
                            },
                            {
                                "code": "0759",
                                "city": "雷州",
                                "pinyin": "Leizhou",
                                "label": "Leizhou0759"
                            },
                            {
                                "code": "0534",
                                "city": "乐陵",
                                "pinyin": "Leling",
                                "label": "Leling0534"
                            },
                            {
                                "code": "0798",
                                "city": "乐平",
                                "pinyin": "Leping",
                                "label": "Leping0798"
                            },
                            {
                                "code": "0577",
                                "city": "乐清",
                                "pinyin": "Leqing",
                                "label": "Leqing0577"
                            },
                            {
                                "code": "0315",
                                "city": "乐亭",
                                "pinyin": "Leting",
                                "label": "Leting0315"
                            },
                            {
                                "code": "0597",
                                "city": "连城",
                                "pinyin": "Liancheng",
                                "label": "Liancheng0597"
                            },
                            {
                                "code": "023",
                                "city": "梁平",
                                "pinyin": "Liangping",
                                "label": "Liangping023"
                            },
                            {
                                "code": "0537",
                                "city": "梁山",
                                "pinyin": "Liangshan",
                                "label": "Liangshan0537"
                            },
                            {
                                "code": "0799",
                                "city": "莲花",
                                "pinyin": "Lianhua",
                                "label": "Lianhua0799"
                            },
                            {
                                "code": "0591",
                                "city": "连江",
                                "pinyin": "Lianjiang",
                                "label": "Lianjiang0591"
                            },
                            {
                                "code": "0759",
                                "city": "廉江",
                                "pinyin": "Lianjiang",
                                "label": "Lianjiang0759"
                            },
                            {
                                "code": "0763",
                                "city": "连南",
                                "pinyin": "Liannan",
                                "label": "Liannan0763"
                            },
                            {
                                "code": "0762",
                                "city": "连平",
                                "pinyin": "Lianping",
                                "label": "Lianping0762"
                            },
                            {
                                "code": "0763",
                                "city": "连山",
                                "pinyin": "Lianshan",
                                "label": "Lianshan0763"
                            },
                            {
                                "code": "0517",
                                "city": "涟水",
                                "pinyin": "Lianshui",
                                "label": "Lianshui0517"
                            },
                            {
                                "code": "0763",
                                "city": "连州",
                                "pinyin": "Lianzhou",
                                "label": "Lianzhou0763"
                            },
                            {
                                "code": "024",
                                "city": "辽中",
                                "pinyin": "Liaozhong",
                                "label": "Liaozhong024"
                            },
                            {
                                "code": "0794",
                                "city": "黎川",
                                "pinyin": "Lichuan",
                                "label": "Lichuan0794"
                            },
                            {
                                "code": "0546",
                                "city": "利津",
                                "pinyin": "Lijin",
                                "label": "Lijin0546"
                            },
                            {
                                "code": "0571",
                                "city": "临安",
                                "pinyin": "Linan",
                                "label": "Linan0571"
                            },
                            {
                                "code": "0557",
                                "city": "灵璧",
                                "pinyin": "Lingbi",
                                "label": "Lingbi0557"
                            },
                            {
                                "code": "0311",
                                "city": "灵寿",
                                "pinyin": "Lingshou",
                                "label": "Lingshou0311"
                            },
                            {
                                "code": "0534",
                                "city": "陵县",
                                "pinyin": "Lingxian",
                                "label": "Lingxian0534"
                            },
                            {
                                "code": "0576",
                                "city": "临海",
                                "pinyin": "Linhai",
                                "label": "Linhai0576"
                            },
                            {
                                "code": "0635",
                                "city": "临清",
                                "pinyin": "Linqing",
                                "label": "Linqing0635"
                            },
                            {
                                "code": "0558",
                                "city": "临泉",
                                "pinyin": "Linquan",
                                "label": "Linquan0558"
                            },
                            {
                                "code": "0536",
                                "city": "临朐",
                                "pinyin": "Linqu",
                                "label": "Linqu0536"
                            },
                            {
                                "code": "0539",
                                "city": "临沭",
                                "pinyin": "Linshu",
                                "label": "Linshu0539"
                            },
                            {
                                "code": "0534",
                                "city": "临邑",
                                "pinyin": "Linyi",
                                "label": "Linyi0534"
                            },
                            {
                                "code": "025",
                                "city": "溧水",
                                "pinyin": "Lishui",
                                "label": "Lishui025"
                            },
                            {
                                "code": "0772",
                                "city": "柳城",
                                "pinyin": "Liucheng",
                                "label": "Liucheng0772"
                            },
                            {
                                "code": "0772",
                                "city": "柳江",
                                "pinyin": "Liujiang",
                                "label": "Liujiang0772"
                            },
                            {
                                "code": "0731",
                                "city": "浏阳",
                                "pinyin": "Liuyang",
                                "label": "Liuyang0731"
                            },
                            {
                                "code": "0558",
                                "city": "利辛",
                                "pinyin": "Lixin",
                                "label": "Lixin0558"
                            },
                            {
                                "code": "0519",
                                "city": "溧阳",
                                "pinyin": "Liyang",
                                "label": "Liyang0519"
                            },
                            {
                                "code": "0771",
                                "city": "隆安",
                                "pinyin": "Longan",
                                "label": "Longan0771"
                            },
                            {
                                "code": "0762",
                                "city": "龙川",
                                "pinyin": "Longchuan",
                                "label": "Longchuan0762"
                            },
                            {
                                "code": "0596",
                                "city": "龙海",
                                "pinyin": "Longhai",
                                "label": "Longhai0596"
                            },
                            {
                                "code": "0535",
                                "city": "龙口",
                                "pinyin": "Longkou",
                                "label": "Longkou0535"
                            },
                            {
                                "code": "0752",
                                "city": "龙门",
                                "pinyin": "Longmen",
                                "label": "Longmen0752"
                            },
                            {
                                "code": "0797",
                                "city": "龙南",
                                "pinyin": "Longnan",
                                "label": "Longnan0797"
                            },
                            {
                                "code": "0578",
                                "city": "龙泉",
                                "pinyin": "Longquan",
                                "label": "Longquan0578"
                            },
                            {
                                "code": "0570",
                                "city": "龙游",
                                "pinyin": "Longyou",
                                "label": "Longyou0570"
                            },
                            {
                                "code": "0311",
                                "city": "栾城",
                                "pinyin": "Luancheng",
                                "label": "Luancheng0311"
                            },
                            {
                                "code": "0379",
                                "city": "栾川",
                                "pinyin": "Luanchuan",
                                "label": "Luanchuan0379"
                            },
                            {
                                "code": "0315",
                                "city": "滦南",
                                "pinyin": "Luannan",
                                "label": "Luannan0315"
                            },
                            {
                                "code": "0315",
                                "city": "滦县",
                                "pinyin": "Luanxian",
                                "label": "Luanxian0315"
                            },
                            {
                                "code": "0660",
                                "city": "陆丰",
                                "pinyin": "Lufeng",
                                "label": "Lufeng0660"
                            },
                            {
                                "code": "0660",
                                "city": "陆河",
                                "pinyin": "Luhe",
                                "label": "Luhe0660"
                            },
                            {
                                "code": "0565",
                                "city": "庐江",
                                "pinyin": "Lujiang",
                                "label": "Lujiang0565"
                            },
                            {
                                "code": "0766",
                                "city": "罗定",
                                "pinyin": "Luoding",
                                "label": "Luoding0766"
                            },
                            {
                                "code": "0379",
                                "city": "洛宁",
                                "pinyin": "Luoning",
                                "label": "Luoning0379"
                            },
                            {
                                "code": "0591",
                                "city": "罗源",
                                "pinyin": "Luoyuan",
                                "label": "Luoyuan0591"
                            },
                            {
                                "code": "0311",
                                "city": "鹿泉",
                                "pinyin": "Luquan",
                                "label": "Luquan0311"
                            },
                            {
                                "code": "0871",
                                "city": "禄劝",
                                "pinyin": "Luquan",
                                "label": "Luquan0871"
                            },
                            {
                                "code": "0799",
                                "city": "芦溪",
                                "pinyin": "Luxi",
                                "label": "Luxi0799"
                            },
                            {
                                "code": "0772",
                                "city": "鹿寨",
                                "pinyin": "Luzhai",
                                "label": "Luzhai0772"
                            }
                        ]
                    },
                    {
                        "initial": "M",
                        "list": [
                            {
                                "code": "0555",
                                "city": "马鞍山",
                                "pinyin": "Maanshan",
                                "label": "Maanshan0555"
                            },
                            {
                                "code": "0668",
                                "city": "茂名",
                                "pinyin": "Maoming",
                                "label": "Maoming0668"
                            },
                            {
                                "code": "028",
                                "city": "眉山",
                                "pinyin": "Meishan",
                                "label": "Meishan028"
                            },
                            {
                                "code": "0753",
                                "city": "梅州",
                                "pinyin": "Meizhou",
                                "label": "Meizhou0753"
                            },
                            {
                                "code": "0816",
                                "city": "绵阳",
                                "pinyin": "Mianyang",
                                "label": "Mianyang0816"
                            },
                            {
                                "code": "0453",
                                "city": "牡丹江",
                                "pinyin": "Mudanjiang",
                                "label": "Mudanjiang0453"
                            },
                            {
                                "code": "0771",
                                "city": "马山",
                                "pinyin": "Mashan",
                                "label": "Mashan0771"
                            },
                            {
                                "code": "0753",
                                "city": "梅县",
                                "pinyin": "Meixian",
                                "label": "Meixian0753"
                            },
                            {
                                "code": "0558",
                                "city": "蒙城",
                                "pinyin": "Mengcheng",
                                "label": "Mengcheng0558"
                            },
                            {
                                "code": "0379",
                                "city": "孟津",
                                "pinyin": "Mengjin",
                                "label": "Mengjin0379"
                            },
                            {
                                "code": "0539",
                                "city": "蒙阴",
                                "pinyin": "Mengyin",
                                "label": "Mengyin0539"
                            },
                            {
                                "code": "0391",
                                "city": "孟州",
                                "pinyin": "Mengzhou",
                                "label": "Mengzhou0391"
                            },
                            {
                                "code": "0550",
                                "city": "明光",
                                "pinyin": "Mingguang",
                                "label": "Mingguang0550"
                            },
                            {
                                "code": "0598",
                                "city": "明溪",
                                "pinyin": "Mingxi",
                                "label": "Mingxi0598"
                            },
                            {
                                "code": "0591",
                                "city": "闽侯",
                                "pinyin": "Minhou",
                                "label": "Minhou0591"
                            },
                            {
                                "code": "0591",
                                "city": "闽清",
                                "pinyin": "Minqing",
                                "label": "Minqing0591"
                            },
                            {
                                "code": "0451",
                                "city": "木兰",
                                "pinyin": "Mulan",
                                "label": "Mulan0451"
                            }
                        ]
                    },
                    {
                        "initial": "N",
                        "list": [
                            {
                                "code": "0817",
                                "city": "南充",
                                "pinyin": "Nanchong",
                                "label": "Nanchong0817"
                            },
                            {
                                "code": "0791",
                                "city": "南昌",
                                "pinyin": "Nanchang",
                                "label": "Nanchang0791"
                            },
                            {
                                "code": "025",
                                "city": "南京",
                                "pinyin": "Nanjing",
                                "label": "Nanjing025"
                            },
                            {
                                "code": "0771",
                                "city": "南宁",
                                "pinyin": "Nanning",
                                "label": "Nanning0771"
                            },
                            {
                                "code": "0513",
                                "city": "南通",
                                "pinyin": "Nantong",
                                "label": "Nantong0513"
                            },
                            {
                                "code": "0574",
                                "city": "宁波",
                                "pinyin": "Ningbo",
                                "label": "Ningbo0574"
                            },
                            {
                                "code": "0599",
                                "city": "南平",
                                "pinyin": "Nanping",
                                "label": "Nanping0599"
                            },
                            {
                                "code": "0377",
                                "city": "南阳",
                                "pinyin": "Nanyang",
                                "label": "Nanyang0377"
                            },
                            {
                                "code": "0896",
                                "city": "那曲",
                                "pinyin": "Naqu",
                                "label": "Naqu0896"
                            },
                            {
                                "code": "0832",
                                "city": "内江",
                                "pinyin": "Neijiang",
                                "label": "Neijiang0832"
                            },
                            {
                                "code": "0593",
                                "city": "宁德",
                                "pinyin": "Ningde",
                                "label": "Ningde0593"
                            },
                            {
                                "code": "0886",
                                "city": "怒江",
                                "pinyin": "Nujiang",
                                "label": "Nujiang0886"
                            },
                            {
                                "code": "0595",
                                "city": "南安",
                                "pinyin": "Nanan",
                                "label": "Nanan0595"
                            },
                            {
                                "code": "0754",
                                "city": "南澳",
                                "pinyin": "Nanao",
                                "label": "Nanao0754"
                            },
                            {
                                "code": "0794",
                                "city": "南城",
                                "pinyin": "Nancheng",
                                "label": "Nancheng0794"
                            },
                            {
                                "code": "023",
                                "city": "南川",
                                "pinyin": "Nanchuan",
                                "label": "Nanchuan023"
                            },
                            {
                                "code": "0794",
                                "city": "南丰",
                                "pinyin": "Nanfeng",
                                "label": "Nanfeng0794"
                            },
                            {
                                "code": "0596",
                                "city": "南靖",
                                "pinyin": "Nanjing",
                                "label": "Nanjing0596"
                            },
                            {
                                "code": "0797",
                                "city": "南康",
                                "pinyin": "Nankang",
                                "label": "Nankang0797"
                            },
                            {
                                "code": "0553",
                                "city": "南陵",
                                "pinyin": "Nanling",
                                "label": "Nanling0553"
                            },
                            {
                                "code": "0751",
                                "city": "南雄",
                                "pinyin": "Nanxiong",
                                "label": "Nanxiong0751"
                            },
                            {
                                "code": "0797",
                                "city": "宁都",
                                "pinyin": "Ningdu",
                                "label": "Ningdu0797"
                            },
                            {
                                "code": "0563",
                                "city": "宁国",
                                "pinyin": "Ningguo",
                                "label": "Ningguo0563"
                            },
                            {
                                "code": "0574",
                                "city": "宁海",
                                "pinyin": "Ninghai",
                                "label": "Ninghai0574"
                            },
                            {
                                "code": "0598",
                                "city": "宁化",
                                "pinyin": "Ninghua",
                                "label": "Ninghua0598"
                            },
                            {
                                "code": "0534",
                                "city": "宁津",
                                "pinyin": "Ningjin",
                                "label": "Ningjin0534"
                            },
                            {
                                "code": "0731",
                                "city": "宁乡",
                                "pinyin": "Ningxiang",
                                "label": "Ningxiang0731"
                            },
                            {
                                "code": "0538",
                                "city": "宁阳",
                                "pinyin": "Ningyang",
                                "label": "Ningyang0538"
                            },
                            {
                                "code": "0431",
                                "city": "农安",
                                "pinyin": "Nongan",
                                "label": "Nongan0431"
                            }
                        ]
                    },
                    {
                        "initial": "P",
                        "list": [
                            {
                                "code": "0427",
                                "city": "盘锦",
                                "pinyin": "Panjin",
                                "label": "Panjin0427"
                            },
                            {
                                "code": "0812",
                                "city": "攀枝花",
                                "pinyin": "Panzhihua",
                                "label": "Panzhihua0812"
                            },
                            {
                                "code": "0375",
                                "city": "平顶山",
                                "pinyin": "Pingdingshan",
                                "label": "Pingdingshan0375"
                            },
                            {
                                "code": "0933",
                                "city": "平凉",
                                "pinyin": "Pingliang",
                                "label": "Pingliang0933"
                            },
                            {
                                "code": "0799",
                                "city": "萍乡",
                                "pinyin": "Pingxiang",
                                "label": "Pingxiang0799"
                            },
                            {
                                "code": "0879",
                                "city": "普洱",
                                "pinyin": "Puer",
                                "label": "Puer0879"
                            },
                            {
                                "code": "0594",
                                "city": "莆田",
                                "pinyin": "Putian",
                                "label": "Putian0594"
                            },
                            {
                                "code": "0393",
                                "city": "濮阳",
                                "pinyin": "Puyang",
                                "label": "Puyang0393"
                            },
                            {
                                "code": "0579",
                                "city": "磐安",
                                "pinyin": "Panan",
                                "label": "Panan0579"
                            },
                            {
                                "code": "0423",
                                "city": "磐石",
                                "pinyin": "Panshi",
                                "label": "Panshi0423"
                            },
                            {
                                "code": "0516",
                                "city": "沛县",
                                "pinyin": "Peixian",
                                "label": "Peixian0516"
                            },
                            {
                                "code": "0535",
                                "city": "蓬莱",
                                "pinyin": "Penglai",
                                "label": "Penglai0535"
                            },
                            {
                                "code": "023",
                                "city": "彭水",
                                "pinyin": "Pengshui",
                                "label": "Pengshui023"
                            },
                            {
                                "code": "0792",
                                "city": "彭泽",
                                "pinyin": "Pengze",
                                "label": "Pengze0792"
                            },
                            {
                                "code": "028",
                                "city": "彭州",
                                "pinyin": "Pengzhou",
                                "label": "Pengzhou028"
                            },
                            {
                                "code": "0532",
                                "city": "平度",
                                "pinyin": "Pingdu",
                                "label": "Pingdu0532"
                            },
                            {
                                "code": "0596",
                                "city": "平和",
                                "pinyin": "Pinghe",
                                "label": "Pinghe0596"
                            },
                            {
                                "code": "0573",
                                "city": "平湖",
                                "pinyin": "Pinghu",
                                "label": "Pinghu0573"
                            },
                            {
                                "code": "0593",
                                "city": "屏南",
                                "pinyin": "Pingnan",
                                "label": "Pingnan0593"
                            },
                            {
                                "code": "0311",
                                "city": "平山",
                                "pinyin": "Pingshan",
                                "label": "Pingshan0311"
                            },
                            {
                                "code": "0591",
                                "city": "平潭",
                                "pinyin": "Pingtan",
                                "label": "Pingtan0591"
                            },
                            {
                                "code": "0577",
                                "city": "平阳",
                                "pinyin": "Pingyang",
                                "label": "Pingyang0577"
                            },
                            {
                                "code": "0531",
                                "city": "平阴",
                                "pinyin": "Pingyin",
                                "label": "Pingyin0531"
                            },
                            {
                                "code": "0539",
                                "city": "平邑",
                                "pinyin": "Pingyi",
                                "label": "Pingyi0539"
                            },
                            {
                                "code": "0534",
                                "city": "平原",
                                "pinyin": "Pingyuan",
                                "label": "Pingyuan0534"
                            },
                            {
                                "code": "0753",
                                "city": "平远",
                                "pinyin": "Pingyuan",
                                "label": "Pingyuan0753"
                            },
                            {
                                "code": "028",
                                "city": "郫县",
                                "pinyin": "Pixian",
                                "label": "Pixian028"
                            },
                            {
                                "code": "0516",
                                "city": "邳州",
                                "pinyin": "Pizhou",
                                "label": "Pizhou0516"
                            },
                            {
                                "code": "0793",
                                "city": "鄱阳",
                                "pinyin": "Poyang",
                                "label": "Poyang0793"
                            },
                            {
                                "code": "0599",
                                "city": "浦城",
                                "pinyin": "Pucheng",
                                "label": "Pucheng0599"
                            },
                            {
                                "code": "0579",
                                "city": "浦江",
                                "pinyin": "Pujiang",
                                "label": "Pujiang0579"
                            },
                            {
                                "code": "028",
                                "city": "蒲江",
                                "pinyin": "Pujiang",
                                "label": "Pujiang028"
                            },
                            {
                                "code": "0411",
                                "city": "普兰店",
                                "pinyin": "Pulandian",
                                "label": "Pulandian0411"
                            },
                            {
                                "code": "0663",
                                "city": "普宁",
                                "pinyin": "Puning",
                                "label": "Puning0663"
                            }
                        ]
                    },
                    {
                        "initial": "Q",
                        "list": [
                            {
                                "code": "0855",
                                "city": "黔东",
                                "pinyin": "Qiandong",
                                "label": "Qiandong0855"
                            },
                            {
                                "code": "0532",
                                "city": "青岛",
                                "pinyin": "Qingdao",
                                "label": "Qingdao0532"
                            },
                            {
                                "code": "0595",
                                "city": "泉州",
                                "pinyin": "Quanzhou",
                                "label": "Quanzhou0595"
                            },
                            {
                                "code": "0854",
                                "city": "黔南",
                                "pinyin": "Qiannan",
                                "label": "Qiannan0854"
                            },
                            {
                                "code": "0859",
                                "city": "黔西南",
                                "pinyin": "Qianxinan",
                                "label": "Qianxinan0859"
                            },
                            {
                                "code": "0934",
                                "city": "庆阳",
                                "pinyin": "Qingyang",
                                "label": "Qingyang0934"
                            },
                            {
                                "code": "0763",
                                "city": "清远",
                                "pinyin": "Qingyuan",
                                "label": "Qingyuan0763"
                            },
                            {
                                "code": "0335",
                                "city": "秦皇岛",
                                "pinyin": "Qinhuangdao",
                                "label": "Qinhuangdao0335"
                            },
                            {
                                "code": "0777",
                                "city": "钦州",
                                "pinyin": "Qinzhou",
                                "label": "Qinzhou0777"
                            },
                            {
                                "code": "0452",
                                "city": "齐齐哈尔",
                                "pinyin": "Qiqihaer",
                                "label": "Qiqihaer0452"
                            },
                            {
                                "code": "0464",
                                "city": "七台河",
                                "pinyin": "Qitaihe",
                                "label": "Qitaihe0464"
                            },
                            {
                                "code": "0874",
                                "city": "曲靖",
                                "pinyin": "Qujing",
                                "label": "Qujing0874"
                            },
                            {
                                "code": "0570",
                                "city": "衢州",
                                "pinyin": "Quzhou",
                                "label": "Quzhou0570"
                            },
                            {
                                "code": "0315",
                                "city": "迁安",
                                "pinyin": "Qianan",
                                "label": "Qianan0315"
                            },
                            {
                                "code": "0556",
                                "city": "潜山",
                                "pinyin": "Qianshan",
                                "label": "Qianshan0556"
                            },
                            {
                                "code": "0793",
                                "city": "铅山",
                                "pinyin": "Qianshan",
                                "label": "Qianshan0793"
                            },
                            {
                                "code": "0315",
                                "city": "迁西",
                                "pinyin": "Qianxi",
                                "label": "Qianxi0315"
                            },
                            {
                                "code": "0513",
                                "city": "启东",
                                "pinyin": "Qidong",
                                "label": "Qidong0513"
                            },
                            {
                                "code": "0534",
                                "city": "齐河",
                                "pinyin": "Qihe",
                                "label": "Qihe0534"
                            },
                            {
                                "code": "023",
                                "city": "綦江",
                                "pinyin": "Qijiang",
                                "label": "Qijiang023"
                            },
                            {
                                "code": "0559",
                                "city": "祁门",
                                "pinyin": "Qimen",
                                "label": "Qimen0559"
                            },
                            {
                                "code": "0598",
                                "city": "清流",
                                "pinyin": "Qingliu",
                                "label": "Qingliu0598"
                            },
                            {
                                "code": "0578",
                                "city": "青田",
                                "pinyin": "Qingtian",
                                "label": "Qingtian0578"
                            },
                            {
                                "code": "0763",
                                "city": "清新",
                                "pinyin": "Qingxin",
                                "label": "Qingxin0763"
                            },
                            {
                                "code": "0566",
                                "city": "青阳",
                                "pinyin": "Qingyang",
                                "label": "Qingyang0566"
                            },
                            {
                                "code": "0578",
                                "city": "庆元",
                                "pinyin": "Qingyuan",
                                "label": "Qingyuan0578"
                            },
                            {
                                "code": "0534",
                                "city": "庆云",
                                "pinyin": "Qingyun",
                                "label": "Qingyun0534"
                            },
                            {
                                "code": "0851",
                                "city": "清镇",
                                "pinyin": "Qingzhen",
                                "label": "Qingzhen0851"
                            },
                            {
                                "code": "0536",
                                "city": "青州",
                                "pinyin": "Qingzhou",
                                "label": "Qingzhou0536"
                            },
                            {
                                "code": "0391",
                                "city": "沁阳",
                                "pinyin": "Qinyang",
                                "label": "Qinyang0391"
                            },
                            {
                                "code": "028",
                                "city": "邛崃",
                                "pinyin": "Qionglai",
                                "label": "Qionglai028"
                            },
                            {
                                "code": "0535",
                                "city": "栖霞",
                                "pinyin": "Qixia",
                                "label": "Qixia0535"
                            },
                            {
                                "code": "0550",
                                "city": "全椒",
                                "pinyin": "Quanjiao",
                                "label": "Quanjiao0550"
                            },
                            {
                                "code": "0797",
                                "city": "全南",
                                "pinyin": "Quannan",
                                "label": "Quannan0797"
                            },
                            {
                                "code": "0537",
                                "city": "曲阜",
                                "pinyin": "Qufu",
                                "label": "Qufu0537"
                            },
                            {
                                "code": "0751",
                                "city": "曲江",
                                "pinyin": "Qujiang",
                                "label": "Qujiang0751"
                            }
                        ]
                    },
                    {
                        "initial": "R",
                        "list": [
                            {
                                "code": "0892",
                                "city": "日喀则",
                                "pinyin": "Rikaze",
                                "label": "Rikaze0892"
                            },
                            {
                                "code": "0633",
                                "city": "日照",
                                "pinyin": "Rizhao",
                                "label": "Rizhao0633"
                            },
                            {
                                "code": "0768",
                                "city": "饶平",
                                "pinyin": "Raoping",
                                "label": "Raoping0768"
                            },
                            {
                                "code": "0751",
                                "city": "仁化",
                                "pinyin": "Renhua",
                                "label": "Renhua0751"
                            },
                            {
                                "code": "0772",
                                "city": "融安",
                                "pinyin": "Rongan",
                                "label": "Rongan0772"
                            },
                            {
                                "code": "023",
                                "city": "荣昌",
                                "pinyin": "Rongchang",
                                "label": "Rongchang023"
                            },
                            {
                                "code": "0631",
                                "city": "荣成",
                                "pinyin": "Rongcheng",
                                "label": "Rongcheng0631"
                            },
                            {
                                "code": "0772",
                                "city": "融水",
                                "pinyin": "Rongshui",
                                "label": "Rongshui0772"
                            },
                            {
                                "code": "0513",
                                "city": "如东",
                                "pinyin": "Rudong",
                                "label": "Rudong0513"
                            },
                            {
                                "code": "0513",
                                "city": "如皋",
                                "pinyin": "Rugao",
                                "label": "Rugao0513"
                            },
                            {
                                "code": "0577",
                                "city": "瑞安",
                                "pinyin": "Ruian",
                                "label": "Ruian0577"
                            },
                            {
                                "code": "0792",
                                "city": "瑞昌",
                                "pinyin": "Ruichang",
                                "label": "Ruichang0792"
                            },
                            {
                                "code": "0797",
                                "city": "瑞金",
                                "pinyin": "Ruijin",
                                "label": "Ruijin0797"
                            },
                            {
                                "code": "0631",
                                "city": "乳山",
                                "pinyin": "Rushan",
                                "label": "Rushan0631"
                            },
                            {
                                "code": "0379",
                                "city": "汝阳",
                                "pinyin": "Ruyang",
                                "label": "Ruyang0379"
                            },
                            {
                                "code": "0751",
                                "city": "乳源",
                                "pinyin": "Ruyuan",
                                "label": "Ruyuan0751"
                            }
                        ]
                    },
                    {
                        "initial": "S",
                        "list": [
                            {
                                "code": "0398",
                                "city": "三门峡",
                                "pinyin": "Sanmenxia",
                                "label": "Sanmenxia0398"
                            },
                            {
                                "code": "021",
                                "city": "上海",
                                "pinyin": "Shanghai",
                                "label": "Shanghai021"
                            },
                            {
                                "code": "024",
                                "city": "沈阳",
                                "pinyin": "Shenyang",
                                "label": "Shenyang024"
                            },
                            {
                                "code": "0755",
                                "city": "深圳",
                                "pinyin": "Shenzhen",
                                "label": "Shenzhen0755"
                            },
                            {
                                "code": "0311",
                                "city": "石家庄",
                                "pinyin": "Shijiazhuang",
                                "label": "Shijiazhuang0311"
                            },
                            {
                                "code": "0512",
                                "city": "苏州",
                                "pinyin": "Suzhou",
                                "label": "Suzhou0512"
                            },
                            {
                                "code": "0598",
                                "city": "三明",
                                "pinyin": "Sanming",
                                "label": "Sanming0598"
                            },
                            {
                                "code": "0899",
                                "city": "三亚",
                                "pinyin": "Sanya",
                                "label": "Sanya0899"
                            },
                            {
                                "code": "0914",
                                "city": "商洛",
                                "pinyin": "Shangluo",
                                "label": "Shangluo0914"
                            },
                            {
                                "code": "0370",
                                "city": "商丘",
                                "pinyin": "Shangqiu",
                                "label": "Shangqiu0370"
                            },
                            {
                                "code": "0793",
                                "city": "上饶",
                                "pinyin": "Shangrao",
                                "label": "Shangrao0793"
                            },
                            {
                                "code": "0893",
                                "city": "山南",
                                "pinyin": "Shannan",
                                "label": "Shannan0893"
                            },
                            {
                                "code": "0754",
                                "city": "汕头",
                                "pinyin": "Shantou",
                                "label": "Shantou0754"
                            },
                            {
                                "code": "0660",
                                "city": "汕尾",
                                "pinyin": "Shanwei",
                                "label": "Shanwei0660"
                            },
                            {
                                "code": "0751",
                                "city": "韶关",
                                "pinyin": "Shaoguan",
                                "label": "Shaoguan0751"
                            },
                            {
                                "code": "0575",
                                "city": "绍兴",
                                "pinyin": "Shaoxing",
                                "label": "Shaoxing0575"
                            },
                            {
                                "code": "0739",
                                "city": "邵阳",
                                "pinyin": "Shaoyang",
                                "label": "Shaoyang0739"
                            },
                            {
                                "code": "0719",
                                "city": "十堰",
                                "pinyin": "Shiyan",
                                "label": "Shiyan0719"
                            },
                            {
                                "code": "0952",
                                "city": "石嘴山",
                                "pinyin": "Shizuishan",
                                "label": "Shizuishan0952"
                            },
                            {
                                "code": "0469",
                                "city": "双鸭山",
                                "pinyin": "Shuangyashan",
                                "label": "Shuangyashan0469"
                            },
                            {
                                "code": "0349",
                                "city": "朔州",
                                "pinyin": "Shuozhou",
                                "label": "Shuozhou0349"
                            },
                            {
                                "code": "0434",
                                "city": "四平",
                                "pinyin": "Siping",
                                "label": "Siping0434"
                            },
                            {
                                "code": "0438",
                                "city": "松原",
                                "pinyin": "Songyuan",
                                "label": "Songyuan0438"
                            },
                            {
                                "code": "0455",
                                "city": "绥化",
                                "pinyin": "Suihua",
                                "label": "Suihua0455"
                            },
                            {
                                "code": "0825",
                                "city": "遂宁",
                                "pinyin": "Suining",
                                "label": "Suining0825"
                            },
                            {
                                "code": "0722",
                                "city": "随州",
                                "pinyin": "Suizhou",
                                "label": "Suizhou0722"
                            },
                            {
                                "code": "0527",
                                "city": "宿迁",
                                "pinyin": "Suqian",
                                "label": "Suqian0527"
                            },
                            {
                                "code": "0557",
                                "city": "宿州",
                                "pinyin": "Suzhou",
                                "label": "Suzhou0557"
                            },
                            {
                                "code": "0772",
                                "city": "三江",
                                "pinyin": "Sanjiang",
                                "label": "Sanjiang0772"
                            },
                            {
                                "code": "0576",
                                "city": "三门",
                                "pinyin": "Sanmen",
                                "label": "Sanmen0576"
                            },
                            {
                                "code": "0596",
                                "city": "诏安",
                                "pinyin": "Saoan",
                                "label": "Saoan0596"
                            },
                            {
                                "code": "0795",
                                "city": "上高",
                                "pinyin": "Shanggao",
                                "label": "Shanggao0795"
                            },
                            {
                                "code": "0597",
                                "city": "上杭",
                                "pinyin": "Shanghang",
                                "label": "Shanghang0597"
                            },
                            {
                                "code": "0531",
                                "city": "商河",
                                "pinyin": "Shanghe",
                                "label": "Shanghe0531"
                            },
                            {
                                "code": "0799",
                                "city": "上栗",
                                "pinyin": "Shangli",
                                "label": "Shangli0799"
                            },
                            {
                                "code": "0771",
                                "city": "上林",
                                "pinyin": "Shanglin",
                                "label": "Shanglin0771"
                            },
                            {
                                "code": "0793",
                                "city": "上饶",
                                "pinyin": "Shangrao",
                                "label": "Shangrao0793"
                            },
                            {
                                "code": "0797",
                                "city": "上犹",
                                "pinyin": "Shangyou",
                                "label": "Shangyou0797"
                            },
                            {
                                "code": "0575",
                                "city": "上虞",
                                "pinyin": "Shangyu",
                                "label": "Shangyu0575"
                            },
                            {
                                "code": "0451",
                                "city": "尚志",
                                "pinyin": "Shangzhi",
                                "label": "Shangzhi0451"
                            },
                            {
                                "code": "0599",
                                "city": "邵武",
                                "pinyin": "Shaowu",
                                "label": "Shaowu0599"
                            },
                            {
                                "code": "0575",
                                "city": "绍兴",
                                "pinyin": "Shaoxing",
                                "label": "Shaoxing0575"
                            },
                            {
                                "code": "0598",
                                "city": "沙县",
                                "pinyin": "Shaxian",
                                "label": "Shaxian0598"
                            },
                            {
                                "code": "0580",
                                "city": "嵊泗",
                                "pinyin": "Shengsi",
                                "label": "Shengsi0580"
                            },
                            {
                                "code": "0575",
                                "city": "嵊州",
                                "pinyin": "Shengzhou",
                                "label": "Shengzhou0575"
                            },
                            {
                                "code": "0635",
                                "city": "莘县",
                                "pinyin": "Shenxian",
                                "label": "Shenxian0635"
                            },
                            {
                                "code": "0311",
                                "city": "深泽",
                                "pinyin": "Shenze",
                                "label": "Shenze0311"
                            },
                            {
                                "code": "0559",
                                "city": "歙县",
                                "pinyin": "Shexian",
                                "label": "Shexian0559"
                            },
                            {
                                "code": "0515",
                                "city": "射阳",
                                "pinyin": "Sheyang",
                                "label": "Sheyang0515"
                            },
                            {
                                "code": "0797",
                                "city": "石城",
                                "pinyin": "Shicheng",
                                "label": "Shicheng0797"
                            },
                            {
                                "code": "0871",
                                "city": "石林",
                                "pinyin": "Shilin",
                                "label": "Shilin0871"
                            },
                            {
                                "code": "0595",
                                "city": "石狮",
                                "pinyin": "Shishi",
                                "label": "Shishi0595"
                            },
                            {
                                "code": "0566",
                                "city": "石台",
                                "pinyin": "Shitai",
                                "label": "Shitai0566"
                            },
                            {
                                "code": "0751",
                                "city": "始兴",
                                "pinyin": "Shixing",
                                "label": "Shixing0751"
                            },
                            {
                                "code": "023",
                                "city": "石柱",
                                "pinyin": "Shizhu",
                                "label": "Shizhu023"
                            },
                            {
                                "code": "0536",
                                "city": "寿光",
                                "pinyin": "Shouguang",
                                "label": "Shouguang0536"
                            },
                            {
                                "code": "0593",
                                "city": "寿宁",
                                "pinyin": "Shouning",
                                "label": "Shouning0593"
                            },
                            {
                                "code": "0564",
                                "city": "寿县",
                                "pinyin": "Shouxian",
                                "label": "Shouxian0564"
                            },
                            {
                                "code": "0451",
                                "city": "双城",
                                "pinyin": "Shuangcheng",
                                "label": "Shuangcheng0451"
                            },
                            {
                                "code": "028",
                                "city": "双流",
                                "pinyin": "Shuangliu",
                                "label": "Shuangliu028"
                            },
                            {
                                "code": "0564",
                                "city": "舒城",
                                "pinyin": "Shucheng",
                                "label": "Shucheng0564"
                            },
                            {
                                "code": "0423",
                                "city": "舒兰",
                                "pinyin": "Shulan",
                                "label": "Shulan0423"
                            },
                            {
                                "code": "0599",
                                "city": "顺昌",
                                "pinyin": "Shunchang",
                                "label": "Shunchang0599"
                            },
                            {
                                "code": "0527",
                                "city": "沭阳",
                                "pinyin": "Shuyang",
                                "label": "Shuyang0527"
                            },
                            {
                                "code": "0527",
                                "city": "泗洪",
                                "pinyin": "Sihong",
                                "label": "Sihong0527"
                            },
                            {
                                "code": "0758",
                                "city": "四会",
                                "pinyin": "Sihui",
                                "label": "Sihui0758"
                            },
                            {
                                "code": "0537",
                                "city": "泗水",
                                "pinyin": "Sishui",
                                "label": "Sishui0537"
                            },
                            {
                                "code": "0557",
                                "city": "泗县",
                                "pinyin": "Sixian",
                                "label": "Sixian0557"
                            },
                            {
                                "code": "0527",
                                "city": "泗阳",
                                "pinyin": "Siyang",
                                "label": "Siyang0527"
                            },
                            {
                                "code": "0871",
                                "city": "嵩明",
                                "pinyin": "Songming",
                                "label": "Songming0871"
                            },
                            {
                                "code": "0599",
                                "city": "松溪",
                                "pinyin": "Songxi",
                                "label": "Songxi0599"
                            },
                            {
                                "code": "0379",
                                "city": "嵩县",
                                "pinyin": "Songxian",
                                "label": "Songxian0379"
                            },
                            {
                                "code": "0578",
                                "city": "松阳",
                                "pinyin": "Songyang",
                                "label": "Songyang0578"
                            },
                            {
                                "code": "0578",
                                "city": "遂昌",
                                "pinyin": "Suichang",
                                "label": "Suichang0578"
                            },
                            {
                                "code": "0796",
                                "city": "遂川",
                                "pinyin": "Suichuan",
                                "label": "Suichuan0796"
                            },
                            {
                                "code": "0516",
                                "city": "睢宁",
                                "pinyin": "Suining",
                                "label": "Suining0516"
                            },
                            {
                                "code": "0561",
                                "city": "濉溪",
                                "pinyin": "Suixi",
                                "label": "Suixi0561"
                            },
                            {
                                "code": "0759",
                                "city": "遂溪",
                                "pinyin": "Suixi",
                                "label": "Suixi0759"
                            },
                            {
                                "code": "0556",
                                "city": "宿松",
                                "pinyin": "Susong",
                                "label": "Susong0556"
                            },
                            {
                                "code": "0527",
                                "city": "宿豫",
                                "pinyin": "Suyu",
                                "label": "Suyu0527"
                            }
                        ]
                    },
                    {
                        "initial": "T",
                        "list": [
                            {
                                "code": "0901",
                                "city": "塔城地",
                                "pinyin": "Tachengdi",
                                "label": "Tachengdi0901"
                            },
                            {
                                "code": "022",
                                "city": "天津",
                                "pinyin": "Tianjin",
                                "label": "Tianjin022"
                            },
                            {
                                "code": "0576",
                                "city": "台州",
                                "pinyin": "Taizhou",
                                "label": "Taizhou0576"
                            },
                            {
                                "code": "0315",
                                "city": "唐山",
                                "pinyin": "Tangshan",
                                "label": "Tangshan0315"
                            },
                            {
                                "code": "0538",
                                "city": "泰安",
                                "pinyin": "Taian",
                                "label": "Taian0538"
                            },
                            {
                                "code": "0351",
                                "city": "太原",
                                "pinyin": "Taiyuan",
                                "label": "Taiyuan0351"
                            },
                            {
                                "code": "0523",
                                "city": "泰州",
                                "pinyin": "Taizhou",
                                "label": "Taizhou0523"
                            },
                            {
                                "code": "0938",
                                "city": "天水",
                                "pinyin": "Tianshui",
                                "label": "Tianshui0938"
                            },
                            {
                                "code": "0410",
                                "city": "铁岭",
                                "pinyin": "Tieling",
                                "label": "Tieling0410"
                            },
                            {
                                "code": "0919",
                                "city": "铜川",
                                "pinyin": "Tongchuan",
                                "label": "Tongchuan0919"
                            },
                            {
                                "code": "0435",
                                "city": "通化",
                                "pinyin": "Tonghua",
                                "label": "Tonghua0435"
                            },
                            {
                                "code": "0475",
                                "city": "通辽",
                                "pinyin": "Tongliao",
                                "label": "Tongliao0475"
                            },
                            {
                                "code": "0562",
                                "city": "铜陵",
                                "pinyin": "Tongling",
                                "label": "Tongling0562"
                            },
                            {
                                "code": "0856",
                                "city": "铜仁",
                                "pinyin": "Tongren",
                                "label": "Tongren0856"
                            },
                            {
                                "code": "0995",
                                "city": "吐鲁番",
                                "pinyin": "Tulufan",
                                "label": "Tulufan0995"
                            },
                            {
                                "code": "0512",
                                "city": "太仓",
                                "pinyin": "Taicang",
                                "label": "Taicang0512"
                            },
                            {
                                "code": "0558",
                                "city": "太和",
                                "pinyin": "Taihe",
                                "label": "Taihe0558"
                            },
                            {
                                "code": "0796",
                                "city": "泰和",
                                "pinyin": "Taihe",
                                "label": "Taihe0796"
                            },
                            {
                                "code": "0556",
                                "city": "太湖",
                                "pinyin": "Taihu",
                                "label": "Taihu0556"
                            },
                            {
                                "code": "0598",
                                "city": "泰宁",
                                "pinyin": "Taining",
                                "label": "Taining0598"
                            },
                            {
                                "code": "0750",
                                "city": "台山",
                                "pinyin": "Taishan",
                                "label": "Taishan0750"
                            },
                            {
                                "code": "0577",
                                "city": "泰顺",
                                "pinyin": "Taishun",
                                "label": "Taishun0577"
                            },
                            {
                                "code": "0523",
                                "city": "泰兴",
                                "pinyin": "Taixing",
                                "label": "Taixing0523"
                            },
                            {
                                "code": "0539",
                                "city": "郯城",
                                "pinyin": "Tancheng",
                                "label": "Tancheng0539"
                            },
                            {
                                "code": "0315",
                                "city": "唐海",
                                "pinyin": "Tanghai",
                                "label": "Tanghai0315"
                            },
                            {
                                "code": "0623",
                                "city": "滕州",
                                "pinyin": "Tengzhou",
                                "label": "Tengzhou0623"
                            },
                            {
                                "code": "0550",
                                "city": "天长",
                                "pinyin": "Tianchang",
                                "label": "Tianchang0550"
                            },
                            {
                                "code": "0576",
                                "city": "天台",
                                "pinyin": "Tiantai",
                                "label": "Tiantai0576"
                            },
                            {
                                "code": "0556",
                                "city": "桐城",
                                "pinyin": "Tongcheng",
                                "label": "Tongcheng0556"
                            },
                            {
                                "code": "0795",
                                "city": "铜鼓",
                                "pinyin": "Tonggu",
                                "label": "Tonggu0795"
                            },
                            {
                                "code": "0451",
                                "city": "通河",
                                "pinyin": "Tonghe",
                                "label": "Tonghe0451"
                            },
                            {
                                "code": "023",
                                "city": "铜梁",
                                "pinyin": "Tongliang",
                                "label": "Tongliang023"
                            },
                            {
                                "code": "0562",
                                "city": "铜陵",
                                "pinyin": "Tongling",
                                "label": "Tongling0562"
                            },
                            {
                                "code": "0571",
                                "city": "桐庐",
                                "pinyin": "Tonglu",
                                "label": "Tonglu0571"
                            },
                            {
                                "code": "023",
                                "city": "潼南",
                                "pinyin": "Tongnan",
                                "label": "Tongnan023"
                            },
                            {
                                "code": "0516",
                                "city": "铜山",
                                "pinyin": "Tongshan",
                                "label": "Tongshan0516"
                            },
                            {
                                "code": "0573",
                                "city": "桐乡",
                                "pinyin": "Tongxiang",
                                "label": "Tongxiang0573"
                            },
                            {
                                "code": "0513",
                                "city": "通州",
                                "pinyin": "Tongzhou",
                                "label": "Tongzhou0513"
                            }
                        ]
                    },
                    {
                        "initial": "W",
                        "list": [
                            {
                                "code": "0913",
                                "city": "渭南",
                                "pinyin": "Weinan",
                                "label": "Weinan0913"
                            },
                            {
                                "code": "0536",
                                "city": "潍坊",
                                "pinyin": "Weifang",
                                "label": "Weifang0536"
                            },
                            {
                                "code": "0631",
                                "city": "威海",
                                "pinyin": "Weihai",
                                "label": "Weihai0631"
                            },
                            {
                                "code": "027",
                                "city": "武汉",
                                "pinyin": "Wuhan",
                                "label": "Wuhan027"
                            },
                            {
                                "code": "0510",
                                "city": "无锡",
                                "pinyin": "Wuxi",
                                "label": "Wuxi0510"
                            },
                            {
                                "code": "0876",
                                "city": "文山",
                                "pinyin": "Wenshan",
                                "label": "Wenshan0876"
                            },
                            {
                                "code": "0577",
                                "city": "温州",
                                "pinyin": "Wenzhou",
                                "label": "Wenzhou0577"
                            },
                            {
                                "code": "0473",
                                "city": "乌海",
                                "pinyin": "Wuhai",
                                "label": "Wuhai0473"
                            },
                            {
                                "code": "0553",
                                "city": "芜湖",
                                "pinyin": "Wuhu",
                                "label": "Wuhu0553"
                            },
                            {
                                "code": "0474",
                                "city": "乌兰察布",
                                "pinyin": "Wulanchabu",
                                "label": "Wulanchabu0474"
                            },
                            {
                                "code": "0991",
                                "city": "乌鲁木齐",
                                "pinyin": "Wulumuqi",
                                "label": "Wulumuqi0991"
                            },
                            {
                                "code": "0935",
                                "city": "武威",
                                "pinyin": "Wuwei",
                                "label": "Wuwei0935"
                            },
                            {
                                "code": "0953",
                                "city": "吴忠",
                                "pinyin": "Wuzhong",
                                "label": "Wuzhong0953"
                            },
                            {
                                "code": "0774",
                                "city": "梧州",
                                "pinyin": "Wuzhou",
                                "label": "Wuzhou0774"
                            },
                            {
                                "code": "0411",
                                "city": "瓦房店",
                                "pinyin": "Wafangdian",
                                "label": "Wafangdian0411"
                            },
                            {
                                "code": "0796",
                                "city": "万安",
                                "pinyin": "Wanan",
                                "label": "Wanan0796"
                            },
                            {
                                "code": "0731",
                                "city": "望城",
                                "pinyin": "Wangcheng",
                                "label": "Wangcheng0731"
                            },
                            {
                                "code": "0556",
                                "city": "望江",
                                "pinyin": "Wangjiang",
                                "label": "Wangjiang0556"
                            },
                            {
                                "code": "0793",
                                "city": "万年",
                                "pinyin": "Wannian",
                                "label": "Wannian0793"
                            },
                            {
                                "code": "0795",
                                "city": "万载",
                                "pinyin": "Wanzai",
                                "label": "Wanzai0795"
                            },
                            {
                                "code": "0537",
                                "city": "微山",
                                "pinyin": "Weishan",
                                "label": "Weishan0537"
                            },
                            {
                                "code": "0577",
                                "city": "文成",
                                "pinyin": "Wencheng",
                                "label": "Wencheng0577"
                            },
                            {
                                "code": "0631",
                                "city": "文登",
                                "pinyin": "Wendeng",
                                "label": "Wendeng0631"
                            },
                            {
                                "code": "0751",
                                "city": "翁源",
                                "pinyin": "Wengyuan",
                                "label": "Wengyuan0751"
                            },
                            {
                                "code": "0576",
                                "city": "温岭",
                                "pinyin": "Wenling",
                                "label": "Wenling0576"
                            },
                            {
                                "code": "0537",
                                "city": "汶上",
                                "pinyin": "Wenshang",
                                "label": "Wenshang0537"
                            },
                            {
                                "code": "0391",
                                "city": "温县",
                                "pinyin": "Wenxian",
                                "label": "Wenxian0391"
                            },
                            {
                                "code": "0558",
                                "city": "涡阳",
                                "pinyin": "Woyang",
                                "label": "Woyang0558"
                            },
                            {
                                "code": "0451",
                                "city": "五常",
                                "pinyin": "Wuchang",
                                "label": "Wuchang0451"
                            },
                            {
                                "code": "0534",
                                "city": "武城",
                                "pinyin": "Wucheng",
                                "label": "Wucheng0534"
                            },
                            {
                                "code": "0759",
                                "city": "吴川",
                                "pinyin": "Wuchuan",
                                "label": "Wuchuan0759"
                            },
                            {
                                "code": "0543",
                                "city": "无棣",
                                "pinyin": "Wudi",
                                "label": "Wudi0543"
                            },
                            {
                                "code": "0552",
                                "city": "五河",
                                "pinyin": "Wuhe",
                                "label": "Wuhe0552"
                            },
                            {
                                "code": "0553",
                                "city": "芜湖",
                                "pinyin": "Wuhu",
                                "label": "Wuhu0553"
                            },
                            {
                                "code": "0753",
                                "city": "五华",
                                "pinyin": "Wuhua",
                                "label": "Wuhua0753"
                            },
                            {
                                "code": "0311",
                                "city": "无极",
                                "pinyin": "Wuji",
                                "label": "Wuji0311"
                            },
                            {
                                "code": "0512",
                                "city": "吴江",
                                "pinyin": "Wujiang",
                                "label": "Wujiang0512"
                            },
                            {
                                "code": "0633",
                                "city": "五莲",
                                "pinyin": "Wulian",
                                "label": "Wulian0633"
                            },
                            {
                                "code": "023",
                                "city": "武隆",
                                "pinyin": "Wulong",
                                "label": "Wulong023"
                            },
                            {
                                "code": "0771",
                                "city": "武鸣",
                                "pinyin": "Wuming",
                                "label": "Wuming0771"
                            },
                            {
                                "code": "0792",
                                "city": "武宁",
                                "pinyin": "Wuning",
                                "label": "Wuning0792"
                            },
                            {
                                "code": "0597",
                                "city": "武平",
                                "pinyin": "Wuping",
                                "label": "Wuping0597"
                            },
                            {
                                "code": "023",
                                "city": "巫山",
                                "pinyin": "Wushan",
                                "label": "Wushan023"
                            },
                            {
                                "code": "0565",
                                "city": "无为",
                                "pinyin": "Wuwei",
                                "label": "Wuwei0565"
                            },
                            {
                                "code": "023",
                                "city": "巫溪",
                                "pinyin": "Wuxi",
                                "label": "Wuxi023"
                            },
                            {
                                "code": "0579",
                                "city": "武义",
                                "pinyin": "Wuyi",
                                "label": "Wuyi0579"
                            },
                            {
                                "code": "0599",
                                "city": "武夷山",
                                "pinyin": "Wuyishan",
                                "label": "Wuyishan0599"
                            },
                            {
                                "code": "0793",
                                "city": "婺源",
                                "pinyin": "Wuyuan",
                                "label": "Wuyuan0793"
                            },
                            {
                                "code": "0391",
                                "city": "武陟",
                                "pinyin": "Wuzhi",
                                "label": "Wuzhi0391"
                            }
                        ]
                    },
                    {
                        "initial": "X",
                        "list": [
                            {
                                "code": "0710",
                                "city": "襄樊",
                                "pinyin": "Xiangfan",
                                "label": "Xiangfan0710"
                            },
                            {
                                "code": "0592",
                                "city": "厦门",
                                "pinyin": "Xiamen",
                                "label": "Xiamen0592"
                            },
                            {
                                "code": "029",
                                "city": "西安",
                                "pinyin": "Xian",
                                "label": "Xian029"
                            },
                            {
                                "code": "0374",
                                "city": "许昌",
                                "pinyin": "Xuchang",
                                "label": "Xuchang0374"
                            },
                            {
                                "code": "0516",
                                "city": "徐州",
                                "pinyin": "Xuzhou",
                                "label": "Xuzhou0516"
                            },
                            {
                                "code": "0732",
                                "city": "湘潭",
                                "pinyin": "Xiangtan",
                                "label": "Xiangtan0732"
                            },
                            {
                                "code": "0743",
                                "city": "湘西",
                                "pinyin": "Xiangxi",
                                "label": "Xiangxi0743"
                            },
                            {
                                "code": "0715",
                                "city": "咸宁",
                                "pinyin": "Xianning",
                                "label": "Xianning0715"
                            },
                            {
                                "code": "029",
                                "city": "咸阳",
                                "pinyin": "Xianyang",
                                "label": "Xianyang029"
                            },
                            {
                                "code": "0712",
                                "city": "孝感",
                                "pinyin": "Xiaogan",
                                "label": "Xiaogan0712"
                            },
                            {
                                "code": "0479",
                                "city": "锡林郭勒盟",
                                "pinyin": "Xilinguolemeng",
                                "label": "Xilinguolemeng0479"
                            },
                            {
                                "code": "0482",
                                "city": "兴安盟",
                                "pinyin": "Xinganmeng",
                                "label": "Xinganmeng0482"
                            },
                            {
                                "code": "0319",
                                "city": "邢台",
                                "pinyin": "Xingtai",
                                "label": "Xingtai0319"
                            },
                            {
                                "code": "0971",
                                "city": "西宁",
                                "pinyin": "Xining",
                                "label": "Xining0971"
                            },
                            {
                                "code": "0373",
                                "city": "新乡",
                                "pinyin": "Xinxiang",
                                "label": "Xinxiang0373"
                            },
                            {
                                "code": "0376",
                                "city": "信阳",
                                "pinyin": "Xinyang",
                                "label": "Xinyang0376"
                            },
                            {
                                "code": "0790",
                                "city": "新余",
                                "pinyin": "Xinyu",
                                "label": "Xinyu0790"
                            },
                            {
                                "code": "0350",
                                "city": "忻州",
                                "pinyin": "Xinzhou",
                                "label": "Xinzhou0350"
                            },
                            {
                                "code": "0691",
                                "city": "西双版纳",
                                "pinyin": "Xishuangbanna",
                                "label": "Xishuangbanna0691"
                            },
                            {
                                "code": "0563",
                                "city": "宣城",
                                "pinyin": "Xuancheng",
                                "label": "Xuancheng0563"
                            },
                            {
                                "code": "0796",
                                "city": "峡江",
                                "pinyin": "Xiajiang",
                                "label": "Xiajiang0796"
                            },
                            {
                                "code": "0534",
                                "city": "夏津",
                                "pinyin": "Xiajin",
                                "label": "Xiajin0534"
                            },
                            {
                                "code": "0574",
                                "city": "象山",
                                "pinyin": "Xiangshan",
                                "label": "Xiangshan0574"
                            },
                            {
                                "code": "0515",
                                "city": "响水",
                                "pinyin": "Xiangshui",
                                "label": "Xiangshui0515"
                            },
                            {
                                "code": "0576",
                                "city": "仙居",
                                "pinyin": "Xianju",
                                "label": "Xianju0576"
                            },
                            {
                                "code": "0594",
                                "city": "仙游",
                                "pinyin": "Xianyou",
                                "label": "Xianyou0594"
                            },
                            {
                                "code": "0557",
                                "city": "萧县",
                                "pinyin": "Xiaoxian",
                                "label": "Xiaoxian0557"
                            },
                            {
                                "code": "0593",
                                "city": "霞浦",
                                "pinyin": "Xiapu",
                                "label": "Xiapu0593"
                            },
                            {
                                "code": "0851",
                                "city": "息烽",
                                "pinyin": "Xifeng",
                                "label": "Xifeng0851"
                            },
                            {
                                "code": "0379",
                                "city": "新安",
                                "pinyin": "Xinan",
                                "label": "Xinan0379"
                            },
                            {
                                "code": "0575",
                                "city": "新昌",
                                "pinyin": "Xinchang",
                                "label": "Xinchang0575"
                            },
                            {
                                "code": "0797",
                                "city": "信丰",
                                "pinyin": "Xinfeng",
                                "label": "Xinfeng0797"
                            },
                            {
                                "code": "0751",
                                "city": "新丰",
                                "pinyin": "Xinfeng",
                                "label": "Xinfeng0751"
                            },
                            {
                                "code": "0796",
                                "city": "新干",
                                "pinyin": "Xingan",
                                "label": "Xingan0796"
                            },
                            {
                                "code": "0797",
                                "city": "兴国",
                                "pinyin": "Xingguo",
                                "label": "Xingguo0797"
                            },
                            {
                                "code": "0523",
                                "city": "兴化",
                                "pinyin": "Xinghua",
                                "label": "Xinghua0523"
                            },
                            {
                                "code": "0753",
                                "city": "兴宁",
                                "pinyin": "Xingning",
                                "label": "Xingning0753"
                            },
                            {
                                "code": "0311",
                                "city": "行唐",
                                "pinyin": "Xingtang",
                                "label": "Xingtang0311"
                            },
                            {
                                "code": "0371",
                                "city": "荥阳",
                                "pinyin": "Xingyang",
                                "label": "Xingyang0371"
                            },
                            {
                                "code": "0792",
                                "city": "星子",
                                "pinyin": "Xingzi",
                                "label": "Xingzi0792"
                            },
                            {
                                "code": "0311",
                                "city": "辛集",
                                "pinyin": "Xinji",
                                "label": "Xinji0311"
                            },
                            {
                                "code": "0791",
                                "city": "新建",
                                "pinyin": "Xinjian",
                                "label": "Xinjian0791"
                            },
                            {
                                "code": "028",
                                "city": "新津",
                                "pinyin": "Xinjin",
                                "label": "Xinjin028"
                            },
                            {
                                "code": "0311",
                                "city": "新乐",
                                "pinyin": "Xinle",
                                "label": "Xinle0311"
                            },
                            {
                                "code": "024",
                                "city": "新民",
                                "pinyin": "Xinmin",
                                "label": "Xinmin024"
                            },
                            {
                                "code": "0371",
                                "city": "新密",
                                "pinyin": "Xinmi",
                                "label": "Xinmi0371"
                            },
                            {
                                "code": "0538",
                                "city": "新泰",
                                "pinyin": "Xintai",
                                "label": "Xintai0538"
                            },
                            {
                                "code": "0766",
                                "city": "新兴",
                                "pinyin": "Xinxing",
                                "label": "Xinxing0766"
                            },
                            {
                                "code": "0516",
                                "city": "新沂",
                                "pinyin": "Xinyi",
                                "label": "Xinyi0516"
                            },
                            {
                                "code": "0668",
                                "city": "信宜",
                                "pinyin": "Xinyi",
                                "label": "Xinyi0668"
                            },
                            {
                                "code": "0371",
                                "city": "新郑",
                                "pinyin": "Xinzheng",
                                "label": "Xinzheng0371"
                            },
                            {
                                "code": "0559",
                                "city": "休宁",
                                "pinyin": "Xiuning",
                                "label": "Xiuning0559"
                            },
                            {
                                "code": "023",
                                "city": "秀山",
                                "pinyin": "Xiushan",
                                "label": "Xiushan023"
                            },
                            {
                                "code": "0792",
                                "city": "修水",
                                "pinyin": "Xiushui",
                                "label": "Xiushui0792"
                            },
                            {
                                "code": "0851",
                                "city": "修文",
                                "pinyin": "Xiuwen",
                                "label": "Xiuwen0851"
                            },
                            {
                                "code": "0391",
                                "city": "修武",
                                "pinyin": "Xiuwu",
                                "label": "Xiuwu0391"
                            },
                            {
                                "code": "0871",
                                "city": "寻甸",
                                "pinyin": "Xundian",
                                "label": "Xundian0871"
                            },
                            {
                                "code": "0797",
                                "city": "寻乌",
                                "pinyin": "Xunwu",
                                "label": "Xunwu0797"
                            },
                            {
                                "code": "0759",
                                "city": "徐闻",
                                "pinyin": "Xuwen",
                                "label": "Xuwen0759"
                            },
                            {
                                "code": "0517",
                                "city": "盱眙",
                                "pinyin": "Xuyi",
                                "label": "Xuyi0517"
                            }
                        ]
                    },
                    {
                        "initial": "Y",
                        "list": [
                            {
                                "code": "0835",
                                "city": "雅安",
                                "pinyin": "Yaan",
                                "label": "Yaan0835"
                            },
                            {
                                "code": "0514",
                                "city": "扬州",
                                "pinyin": "Yangzhou",
                                "label": "Yangzhou0514"
                            },
                            {
                                "code": "0535",
                                "city": "烟台",
                                "pinyin": "Yantai",
                                "label": "Yantai0535"
                            },
                            {
                                "code": "0911",
                                "city": "延安",
                                "pinyin": "Yanan",
                                "label": "Yanan0911"
                            },
                            {
                                "code": "0433",
                                "city": "延边",
                                "pinyin": "Yanbian",
                                "label": "Yanbian0433"
                            },
                            {
                                "code": "0515",
                                "city": "盐城",
                                "pinyin": "Yancheng",
                                "label": "Yancheng0515"
                            },
                            {
                                "code": "0662",
                                "city": "阳江",
                                "pinyin": "Yangjiang",
                                "label": "Yangjiang0662"
                            },
                            {
                                "code": "0353",
                                "city": "阳泉",
                                "pinyin": "Yangquan",
                                "label": "Yangquan0353"
                            },
                            {
                                "code": "0831",
                                "city": "宜宾",
                                "pinyin": "Yibin",
                                "label": "Yibin0831"
                            },
                            {
                                "code": "0717",
                                "city": "宜昌",
                                "pinyin": "Yichang",
                                "label": "Yichang0717"
                            },
                            {
                                "code": "0458",
                                "city": "伊春",
                                "pinyin": "Yichun",
                                "label": "Yichun0458"
                            },
                            {
                                "code": "0795",
                                "city": "宜春",
                                "pinyin": "Yichun",
                                "label": "Yichun0795"
                            },
                            {
                                "code": "0999",
                                "city": "伊犁哈萨克",
                                "pinyin": "Yilihasake",
                                "label": "Yilihasake0999"
                            },
                            {
                                "code": "0951",
                                "city": "银川",
                                "pinyin": "Yinchuan",
                                "label": "Yinchuan0951"
                            },
                            {
                                "code": "0417",
                                "city": "营口",
                                "pinyin": "Yingkou",
                                "label": "Yingkou0417"
                            },
                            {
                                "code": "0701",
                                "city": "鹰潭",
                                "pinyin": "Yingtan",
                                "label": "Yingtan0701"
                            },
                            {
                                "code": "0737",
                                "city": "益阳",
                                "pinyin": "Yiyang",
                                "label": "Yiyang0737"
                            },
                            {
                                "code": "0746",
                                "city": "永州",
                                "pinyin": "Yongzhou",
                                "label": "Yongzhou0746"
                            },
                            {
                                "code": "0730",
                                "city": "岳阳",
                                "pinyin": "Yueyang",
                                "label": "Yueyang0730"
                            },
                            {
                                "code": "0775",
                                "city": "玉林",
                                "pinyin": "Yulin",
                                "label": "Yulin0775"
                            },
                            {
                                "code": "0912",
                                "city": "榆林",
                                "pinyin": "Yulin",
                                "label": "Yulin0912"
                            },
                            {
                                "code": "0359",
                                "city": "运城",
                                "pinyin": "Yuncheng",
                                "label": "Yuncheng0359"
                            },
                            {
                                "code": "0766",
                                "city": "云浮",
                                "pinyin": "Yunfu",
                                "label": "Yunfu0766"
                            },
                            {
                                "code": "0976",
                                "city": "玉树",
                                "pinyin": "Yushu",
                                "label": "Yushu0976"
                            },
                            {
                                "code": "0877",
                                "city": "玉溪",
                                "pinyin": "Yuxi",
                                "label": "Yuxi0877"
                            },
                            {
                                "code": "0662",
                                "city": "阳春",
                                "pinyin": "Yangchun",
                                "label": "Yangchun0662"
                            },
                            {
                                "code": "0662",
                                "city": "阳东",
                                "pinyin": "Yangdong",
                                "label": "Yangdong0662"
                            },
                            {
                                "code": "0635",
                                "city": "阳谷",
                                "pinyin": "Yanggu",
                                "label": "Yanggu0635"
                            },
                            {
                                "code": "0763",
                                "city": "阳山",
                                "pinyin": "Yangshan",
                                "label": "Yangshan0763"
                            },
                            {
                                "code": "0543",
                                "city": "阳信",
                                "pinyin": "Yangxin",
                                "label": "Yangxin0543"
                            },
                            {
                                "code": "0662",
                                "city": "阳西",
                                "pinyin": "Yangxi",
                                "label": "Yangxi0662"
                            },
                            {
                                "code": "0511",
                                "city": "扬中",
                                "pinyin": "Yangzhong",
                                "label": "Yangzhong0511"
                            },
                            {
                                "code": "0379",
                                "city": "偃师",
                                "pinyin": "Yanshi",
                                "label": "Yanshi0379"
                            },
                            {
                                "code": "0451",
                                "city": "延寿",
                                "pinyin": "Yanshou",
                                "label": "Yanshou0451"
                            },
                            {
                                "code": "0537",
                                "city": "兖州",
                                "pinyin": "Yanzhou",
                                "label": "Yanzhou0537"
                            },
                            {
                                "code": "0379",
                                "city": "伊川",
                                "pinyin": "Yichuan",
                                "label": "Yichuan0379"
                            },
                            {
                                "code": "0795",
                                "city": "宜丰",
                                "pinyin": "Yifeng",
                                "label": "Yifeng0795"
                            },
                            {
                                "code": "0794",
                                "city": "宜黄",
                                "pinyin": "Yihuang",
                                "label": "Yihuang0794"
                            },
                            {
                                "code": "0451",
                                "city": "依兰",
                                "pinyin": "Yilan",
                                "label": "Yilan0451"
                            },
                            {
                                "code": "0871",
                                "city": "宜良",
                                "pinyin": "Yiliang",
                                "label": "Yiliang0871"
                            },
                            {
                                "code": "0539",
                                "city": "沂南",
                                "pinyin": "Yinan",
                                "label": "Yinan0539"
                            },
                            {
                                "code": "0763",
                                "city": "英德",
                                "pinyin": "Yingde",
                                "label": "Yingde0763"
                            },
                            {
                                "code": "0558",
                                "city": "颍上",
                                "pinyin": "Yingshang",
                                "label": "Yingshang0558"
                            },
                            {
                                "code": "0539",
                                "city": "沂水",
                                "pinyin": "Yishui",
                                "label": "Yishui0539"
                            },
                            {
                                "code": "0579",
                                "city": "义乌",
                                "pinyin": "Yiwu",
                                "label": "Yiwu0579"
                            },
                            {
                                "code": "0559",
                                "city": "黟县",
                                "pinyin": "Yixian",
                                "label": "Yixian0559"
                            },
                            {
                                "code": "0510",
                                "city": "宜兴",
                                "pinyin": "Yixing",
                                "label": "Yixing0510"
                            },
                            {
                                "code": "0793",
                                "city": "弋阳",
                                "pinyin": "Yiyang",
                                "label": "Yiyang0793"
                            },
                            {
                                "code": "0379",
                                "city": "宜阳",
                                "pinyin": "Yiyang",
                                "label": "Yiyang0379"
                            },
                            {
                                "code": "0533",
                                "city": "沂源",
                                "pinyin": "Yiyuan",
                                "label": "Yiyuan0533"
                            },
                            {
                                "code": "0514",
                                "city": "仪征",
                                "pinyin": "Yizheng",
                                "label": "Yizheng0514"
                            },
                            {
                                "code": "0598",
                                "city": "永安",
                                "pinyin": "Yongan",
                                "label": "Yongan0598"
                            },
                            {
                                "code": "023",
                                "city": "永川",
                                "pinyin": "Yongchuan",
                                "label": "Yongchuan023"
                            },
                            {
                                "code": "0595",
                                "city": "永春",
                                "pinyin": "Yongchun",
                                "label": "Yongchun0595"
                            },
                            {
                                "code": "0931",
                                "city": "永登",
                                "pinyin": "Yongdeng",
                                "label": "Yongdeng0931"
                            },
                            {
                                "code": "0597",
                                "city": "永定",
                                "pinyin": "Yongding",
                                "label": "Yongding0597"
                            },
                            {
                                "code": "0796",
                                "city": "永丰",
                                "pinyin": "Yongfeng",
                                "label": "Yongfeng0796"
                            },
                            {
                                "code": "0423",
                                "city": "永吉",
                                "pinyin": "Yongji",
                                "label": "Yongji0423"
                            },
                            {
                                "code": "0577",
                                "city": "永嘉",
                                "pinyin": "Yongjia",
                                "label": "Yongjia0577"
                            },
                            {
                                "code": "0579",
                                "city": "永康",
                                "pinyin": "Yongkang",
                                "label": "Yongkang0579"
                            },
                            {
                                "code": "0771",
                                "city": "邕宁",
                                "pinyin": "Yongning",
                                "label": "Yongning0771"
                            },
                            {
                                "code": "0591",
                                "city": "永泰",
                                "pinyin": "Yongtai",
                                "label": "Yongtai0591"
                            },
                            {
                                "code": "0796",
                                "city": "永新",
                                "pinyin": "Yongxin",
                                "label": "Yongxin0796"
                            },
                            {
                                "code": "0792",
                                "city": "永修",
                                "pinyin": "Yongxiu",
                                "label": "Yongxiu0792"
                            },
                            {
                                "code": "0598",
                                "city": "尤溪",
                                "pinyin": "Youxi",
                                "label": "Youxi0598"
                            },
                            {
                                "code": "023",
                                "city": "酉阳",
                                "pinyin": "Youyang",
                                "label": "Youyang023"
                            },
                            {
                                "code": "0311",
                                "city": "元氏",
                                "pinyin": "Yuanshi",
                                "label": "Yuanshi0311"
                            },
                            {
                                "code": "0534",
                                "city": "禹城",
                                "pinyin": "Yucheng",
                                "label": "Yucheng0534"
                            },
                            {
                                "code": "0797",
                                "city": "于都",
                                "pinyin": "Yudu",
                                "label": "Yudu0797"
                            },
                            {
                                "code": "0556",
                                "city": "岳西",
                                "pinyin": "Yuexi",
                                "label": "Yuexi0556"
                            },
                            {
                                "code": "0793",
                                "city": "余干",
                                "pinyin": "Yugan",
                                "label": "Yugan0793"
                            },
                            {
                                "code": "0576",
                                "city": "玉环",
                                "pinyin": "Yuhuan",
                                "label": "Yuhuan0576"
                            },
                            {
                                "code": "0701",
                                "city": "余江",
                                "pinyin": "Yujiang",
                                "label": "Yujiang0701"
                            },
                            {
                                "code": "0766",
                                "city": "郁南",
                                "pinyin": "Yunan",
                                "label": "Yunan0766"
                            },
                            {
                                "code": "0766",
                                "city": "云安",
                                "pinyin": "Yunan",
                                "label": "Yunan0766"
                            },
                            {
                                "code": "0530",
                                "city": "郓城",
                                "pinyin": "Yuncheng",
                                "label": "Yuncheng0530"
                            },
                            {
                                "code": "0578",
                                "city": "云和",
                                "pinyin": "Yunhe",
                                "label": "Yunhe0578"
                            },
                            {
                                "code": "0596",
                                "city": "云霄",
                                "pinyin": "Yunxiao",
                                "label": "Yunxiao0596"
                            },
                            {
                                "code": "023",
                                "city": "云阳",
                                "pinyin": "Yunyang",
                                "label": "Yunyang023"
                            },
                            {
                                "code": "0793",
                                "city": "玉山",
                                "pinyin": "Yushan",
                                "label": "Yushan0793"
                            },
                            {
                                "code": "0431",
                                "city": "榆树",
                                "pinyin": "Yushu",
                                "label": "Yushu0431"
                            },
                            {
                                "code": "0537",
                                "city": "鱼台",
                                "pinyin": "Yutai",
                                "label": "Yutai0537"
                            },
                            {
                                "code": "0315",
                                "city": "玉田",
                                "pinyin": "Yutian",
                                "label": "Yutian0315"
                            },
                            {
                                "code": "0574",
                                "city": "余姚",
                                "pinyin": "Yuyao",
                                "label": "Yuyao0574"
                            },
                            {
                                "code": "0931",
                                "city": "榆中",
                                "pinyin": "Yuzhong",
                                "label": "Yuzhong0931"
                            }
                        ]
                    },
                    {
                        "initial": "Z",
                        "list": [
                            {
                                "code": "0596",
                                "city": "漳州",
                                "pinyin": "Zhangzhou",
                                "label": "Zhangzhou0596"
                            },
                            {
                                "code": "0371",
                                "city": "郑州",
                                "pinyin": "Zhengzhou",
                                "label": "Zhengzhou0371"
                            },
                            {
                                "code": "0760",
                                "city": "中山",
                                "pinyin": "Zhongshan",
                                "label": "Zhongshan0760"
                            },
                            {
                                "code": "0756",
                                "city": "珠海",
                                "pinyin": "Zhuhai",
                                "label": "Zhuhai0756"
                            },
                            {
                                "code": "0623",
                                "city": "枣庄",
                                "pinyin": "Zaozhuang",
                                "label": "Zaozhuang0623"
                            },
                            {
                                "code": "0744",
                                "city": "张家界",
                                "pinyin": "Zhangjiajie",
                                "label": "Zhangjiajie0744"
                            },
                            {
                                "code": "0313",
                                "city": "张家口",
                                "pinyin": "Zhangjiakou",
                                "label": "Zhangjiakou0313"
                            },
                            {
                                "code": "0936",
                                "city": "张掖",
                                "pinyin": "Zhangye",
                                "label": "Zhangye0936"
                            },
                            {
                                "code": "0759",
                                "city": "湛江",
                                "pinyin": "Zhanjiang",
                                "label": "Zhanjiang0759"
                            },
                            {
                                "code": "0758",
                                "city": "肇庆",
                                "pinyin": "Zhaoqing",
                                "label": "Zhaoqing0758"
                            },
                            {
                                "code": "0870",
                                "city": "昭通",
                                "pinyin": "Zhaotong",
                                "label": "Zhaotong0870"
                            },
                            {
                                "code": "0511",
                                "city": "镇江",
                                "pinyin": "Zhenjiang",
                                "label": "Zhenjiang0511"
                            },
                            {
                                "code": "0955",
                                "city": "中卫",
                                "pinyin": "Zhongwei",
                                "label": "Zhongwei0955"
                            },
                            {
                                "code": "0394",
                                "city": "周口",
                                "pinyin": "Zhoukou",
                                "label": "Zhoukou0394"
                            },
                            {
                                "code": "0580",
                                "city": "舟山",
                                "pinyin": "Zhoushan",
                                "label": "Zhoushan0580"
                            },
                            {
                                "code": "0396",
                                "city": "驻马店",
                                "pinyin": "Zhumadian",
                                "label": "Zhumadian0396"
                            },
                            {
                                "code": "0731",
                                "city": "株洲",
                                "pinyin": "Zhuzhou",
                                "label": "Zhuzhou0731"
                            },
                            {
                                "code": "0533",
                                "city": "淄博",
                                "pinyin": "Zibo",
                                "label": "Zibo0533"
                            },
                            {
                                "code": "0813",
                                "city": "自贡",
                                "pinyin": "Zigong",
                                "label": "Zigong0813"
                            },
                            {
                                "code": "028",
                                "city": "资阳",
                                "pinyin": "Ziyang",
                                "label": "Ziyang028"
                            },
                            {
                                "code": "0852",
                                "city": "遵义",
                                "pinyin": "Zunyi",
                                "label": "Zunyi0852"
                            },
                            {
                                "code": "0311",
                                "city": "赞皇",
                                "pinyin": "Zanhuang",
                                "label": "Zanhuang0311"
                            },
                            {
                                "code": "020",
                                "city": "增城",
                                "pinyin": "Zengcheng",
                                "label": "Zengcheng020"
                            },
                            {
                                "code": "0512",
                                "city": "张家港",
                                "pinyin": "Zhangjiagang",
                                "label": "Zhangjiagang0512"
                            },
                            {
                                "code": "0597",
                                "city": "漳平",
                                "pinyin": "Zhangping",
                                "label": "Zhangping0597"
                            },
                            {
                                "code": "0596",
                                "city": "漳浦",
                                "pinyin": "Zhangpu",
                                "label": "Zhangpu0596"
                            },
                            {
                                "code": "0531",
                                "city": "章丘",
                                "pinyin": "Zhangqiu",
                                "label": "Zhangqiu0531"
                            },
                            {
                                "code": "0795",
                                "city": "樟树",
                                "pinyin": "Zhangshu",
                                "label": "Zhangshu0795"
                            },
                            {
                                "code": "0543",
                                "city": "沾化",
                                "pinyin": "Zhanhua",
                                "label": "Zhanhua0543"
                            },
                            {
                                "code": "0311",
                                "city": "赵县",
                                "pinyin": "Zhaoxian",
                                "label": "Zhaoxian0311"
                            },
                            {
                                "code": "0535",
                                "city": "招远",
                                "pinyin": "Zhaoyuan",
                                "label": "Zhaoyuan0535"
                            },
                            {
                                "code": "0311",
                                "city": "正定",
                                "pinyin": "Zhengding",
                                "label": "Zhengding0311"
                            },
                            {
                                "code": "0599",
                                "city": "政和",
                                "pinyin": "Zhenghe",
                                "label": "Zhenghe0599"
                            },
                            {
                                "code": "0593",
                                "city": "柘荣",
                                "pinyin": "Zherong",
                                "label": "Zherong0593"
                            },
                            {
                                "code": "0371",
                                "city": "中牟",
                                "pinyin": "Zhongmou",
                                "label": "Zhongmou0371"
                            },
                            {
                                "code": "023",
                                "city": "忠县",
                                "pinyin": "Zhongxian",
                                "label": "Zhongxian023"
                            },
                            {
                                "code": "0593",
                                "city": "周宁",
                                "pinyin": "Zhouning",
                                "label": "Zhouning0593"
                            },
                            {
                                "code": "029",
                                "city": "周至",
                                "pinyin": "Zhouzhi",
                                "label": "Zhouzhi029"
                            },
                            {
                                "code": "0411",
                                "city": "庄河",
                                "pinyin": "Zhuanghe",
                                "label": "Zhuanghe0411"
                            },
                            {
                                "code": "0536",
                                "city": "诸城",
                                "pinyin": "Zhucheng",
                                "label": "Zhucheng0536"
                            },
                            {
                                "code": "0575",
                                "city": "诸暨",
                                "pinyin": "Zhuji",
                                "label": "Zhuji0575"
                            },
                            {
                                "code": "0762",
                                "city": "紫金",
                                "pinyin": "Zijin",
                                "label": "Zijin0762"
                            },
                            {
                                "code": "0794",
                                "city": "资溪",
                                "pinyin": "Zixi",
                                "label": "Zixi0794"
                            },
                            {
                                "code": "0537",
                                "city": "邹城",
                                "pinyin": "Zoucheng",
                                "label": "Zoucheng0537"
                            },
                            {
                                "code": "0543",
                                "city": "邹平",
                                "pinyin": "Zouping",
                                "label": "Zouping0543"
                            },
                            {
                                "code": "0315",
                                "city": "遵化",
                                "pinyin": "Zunhua",
                                "label": "Zunhua0315"
                            }
                        ]
                    }
                ]
            }
        },
        mounted() {
            window.console.log(this.areaList)
        },
        methods: {
            chooseCity(item) {
                localStorage.setItem('selectcity',JSON.stringify(item))
                this.$router.push({ path: 'ToHome' })
            }
        }
    }
</script>

<style scoped>

</style>
