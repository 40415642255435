<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                完善信息
            </div>
            <van-form>
                <van-field
                        v-model="realname"
                        name="realname"
                        label="真实姓名"
                        placeholder="真实姓名"
                        :rules="[{ required: true, message: '请填写真实姓名' }]"
                />
                <van-field name="radio" label="性别">
                    <template #input>
                        <van-radio-group v-model="sex" direction="horizontal">
                            <van-radio name="1">男</van-radio>
                            <van-radio name="2">女</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-cell title="出生日期" is-link :value="birthday" @click="todateshow = true" />
                <van-popup v-model="todateshow" position="bottom">
                    <van-datetime-picker
                            type="date"
                            :min-date="minDate"
                            :max-date="maxDate"
                            @confirm="onToDateConfirm"
                            @cancel="todateshow = false"
                    />
                </van-popup>
            </van-form>

        </div>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" @click="completeinfo">
                保存信息
            </van-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { RadioGroup, Radio,DatetimePicker,Notify} from 'vant';
    Vue.use(RadioGroup);
    Vue.use(Radio);
    Vue.use(DatetimePicker);
    Vue.use(Notify);
    var that
    export default {
        name: "CompleteInfo",
        data () {
            return {
                realname: '',
                sex: '1',
                minDate: new Date(1949, 1, 1),
                maxDate: new Date(2015, 1, 1),
                birthday: '',
                todateshow: false
            }
        },
        mounted() {
            that = this
        },
        methods: {
            onToDateConfirm (dates) {
                window.console.log(dates)
                that.birthday = that.formatDate(dates)
                that.todateshow = false
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                return `${date.getFullYear()}-${monthstr}-${date.getDate()}`;
            },
            completeinfo () {
                if (that.realname !== '' && that.sex !== '' && that.birthday !== '') {
                    var updata = {
                        openid: localStorage.getItem('openid'),
                        realname: that.realname,
                        sex: that.sex.toString(),
                        birthday: that.birthday
                    }
                    that.$http({
                        url: 'https://api.yunshangyunkeji.com/completeinfo',
                        method: 'post',
                        data: updata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                Notify({ type: 'success', message: '更新完毕' });
                                // 把相关数据缓存到本地
                            } else {
                                window.console.log('获取用户失败')
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '信息不完整' });
                }
            }
        }
    }
</script>

<style scoped>

</style>
