<template>
    <div class="allbg" :style="'height:' + colheight + 'px;'">
        <!-- 引入GPS -->
        <div style="height:260px; background-color: #fff; margin-left: 20px;margin-right: 20px;  margin-top: 220px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <div style="height: 40px; display: flex; justify-content: space-between; align-items: center;">
                <div style="font-size: 12px;  height: 40px;width:100px;display: flex;align-items: center;" @click="selectcity">
                    <van-icon style="margin-left:15px; margin-right: 5px; font-size: 20px;" name="https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E4%BD%8D%E7%BD%AE.png" />
                    {{curpos}}
                </div>
                <div @click="showxz" style="font-size: 12px;color: #cccccc;display: flex;align-items: center;">
                    预定须知
                    <van-icon style="margin-left:5px;margin-right: 10px; font-size: 14px;" name="info-o" />
                </div>
            </div>
            <div style="margin-top: 15px; display: flex; justify-content: center;">
                <van-field label-width="50" @blur="getfromgps" label-class="lefttxt" type="textarea" rows="2" left-icon="smile-o" style="font-size:12px;background-color: #F9F9F9;height: 80px;line-height: 30px; width:95%;" v-model="from_address" label="寄件" placeholder="请输入寄件地址" >
                    <template #left-icon>
                        <van-icon size="16" style="margin-top: 6px;" name="https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E5%AF%84%E4%BB%B6%E5%8A%9F%E8%83%BD.png" />
                    </template>
                    <template #button>
                        <van-icon size="18" name="search" @click="fromquerypos"/>
                    </template>
                </van-field>
            </div>
            <div style="margin-top: 15px; display: flex; justify-content: center;">
                <van-field label-width="50" @blur="gettogps" label-class="lefttxt" type="textarea" rows="2" left-icon="smile-o" style="font-size:12px;background-color: #F9F9F9;height: 80px;line-height: 30px; width:95%;" v-model="to_address" label="收货" placeholder="请输入收货地址" >
                    <template #left-icon>
                        <van-icon size="16" style="margin-top: 6px;" name="https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E5%9C%B0%E5%9D%80.png" />
                    </template>
                    <template #button>
                        <van-icon size="18" name="search" @click="toquerypos"/>
                    </template>
                </van-field>
            </div>
        </div>
         <div style="margin-top:20px; display: flex; justify-content: center;">
             <van-button style="width:300px;" color="#3c71d9" type="primary" round @click="startorderin" >下一步</van-button>
         </div>
        <van-dialog v-model="pacbz" title="行李配送服务标准及要求">
            <div style="padding: 30px; font-size: 14px; color: #2c3e50;height: 450px; overflow-y: auto;">
                <p>一、服务内容：</p>
                <p> 1.行李服务时间：每日早7:00-晚24:00。</p>
                <p> 2.行李服务按件收费。</p>
                <p> 3.限实际承运的国内航班，国际航班暂不支持办理行李配送服务。</p>
                <p> 4.开通城市：北京、郑州、厦门、成都。</p>
                <p> 5.仅支持购买适用区域范围内的行李服务。</p>
                <p> 6.工作人员提取行李后，交付至指定位置。 （如果需交付至酒店，建议您提前咨询酒店方，确认酒店方面是否可以代收及存放行李）</p>
                <p> 7.可实时查看行李运送状态。</p>
                <p> 8.若需开具发票，请联系客服申请开具发票。</p>
                <p style="margin-top: 30px;">二、温馨提示</p>
                <p> 1.此服务下单后不支持修改原订单中的航班信息，若发生行程变动（包括非自愿），需退订当前订单，并依据新的航班信息重新下单。如果未及时退订而造成损失，由您自行承担。</p>
                <p> 2.关于取消订单的说明：
                    在工作人员未取行李时，支持取消订单，全额退款；在工作人员取到行李后，您的订单状态变为“运输中”时，不能申请取消和退款；如确需取消，需联系客服（客服电话 4006-620610），客服根据行李运输情况进行相应的取消建议和处理。</p>
                <p> 3.如您的航班发生延误，超出当天行李服务时间，此运输服务将顺延至第二天。第二天行李取件时间最早于8点开始。</p>
                <p> 4.当行李发生随机未到或少到情况，按照少收行李流程处置。如因您本人原因，导致行李未能同机抵达目的地，或本人误机而行李装机抵达目的地，相关责任以及期间可能产生的费用，均由您本人承担。</p>
                <p> 5.如因我司原因，导致行李晚到指定位置（家门/酒店/机场）交付，由我司物流员或者客服人员联系您；如果导致客诉或者发生赔付，由我司全权负责受理和赔偿。</p>
                <p> 6.办理“行李配送”服务时，选择行李数量与实际配送不符合，工作人员有权要求您重新下单或拒绝配送，由此造成的损失由您本人承担。</p>
                <p style="margin-top: 30px;">三、不属于增值服务保障的说明</p>
                <p> 下列行李或物品不属于本增值服务的保障标的:</p>
                <p> (一)货币、票证等有价证券;</p>
                <p> (二)纪念币、金银制品、首饰、珠宝、钻石、玉器、古书、古玩、字画、艺术品等贵重物品;</p>
                <p> (三)文件、账册、技术资料、图表、存储数据、动物、植物等不易鉴定价值的物品;</p>
                <p> (四)枪支器械、毒品、易燃易爆等违法违规物品;玻璃陶瓷等易碎品。</p>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form, Field, CellGroup } from 'vant';
    import { Button } from 'vant';
    import axios from 'axios'
    import { Notify } from 'vant';
    import { DropdownMenu, DropdownItem } from 'vant';
    import { Icon } from 'vant';
    import { Radio } from 'vant';

    Vue.use(Radio)
    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Icon);
    Vue.use(Notify);
    Vue.use(Field);
    Vue.use(CellGroup);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.prototype.$http = axios
    var that
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    }
    export default {
        name: "ToHome",
        data () {
            return {
                curpos: '北京',
                from_address: '',
                to_address: '',
                ifhx: '',
                pacbz: false,
                colheight: '',
                ordertype: ''
            }
        },
        mounted () {
            that = this
            that.colheight = window.innerHeight
            that.ordertype = this.$route.query.ordertype
            that.ifhx = this.$route.query.ifhx
            if (that.ifhx === '1') {
                // 航信订单
                window.console.log('航信订单')
            } else {
                if (localStorage.getItem('userphone') === '' || localStorage.getItem('userphone') === undefined || localStorage.getItem('userphone') === null) {
                    that.$router.push({ path: 'RegMember' })
                }
            }
            if (localStorage.getItem('selectcity') !== null) {
                var item = JSON.parse(localStorage.getItem('selectcity'))
                that.curpos = item.city
            } else {
                that.getcurpos()
            }

            /*
            if (localStorage.getItem('fromaddress') !== null && localStorage.getItem('fromaddress') !== '') {
                var item1 = JSON.parse(localStorage.getItem('fromaddress'))
                window.console.log(item1.pname)
                if (item1.pname !== undefined) {
                    that.from_address = item1.pname + item1.adname + item1.name
                } else {
                    that.from_address = item1.province + item1.city + item1.district + item1.format_address
                }
                that.getfromgps()
            }
            if (localStorage.getItem('toaddress') !== null && localStorage.getItem('toaddress') !== '') {
                var item2 = JSON.parse(localStorage.getItem('toaddress'))
                if (item2.pname !== undefined) {
                    that.to_address = item2.pname + item2.adname + item2.name
                } else {
                    that.to_address = item2.province + item2.city + item2.district + item2.format_address
                }
                that.gettogps()
            }*/
            if (localStorage.getItem('from_address') !== null && localStorage.getItem('from_address') !== '') {
                that.from_address = localStorage.getItem('from_address')
                that.getfromgps()
            } else {
                that.getdefaultaddress()
            }
            if (localStorage.getItem('to_address') !== null && localStorage.getItem('to_address') !== '') {
                that.to_address = localStorage.getItem('to_address')
                that.gettogps()
            }
            setTimeout(function() {
                that.pacbz = true
            }, 1000);
        },
        methods: {
            showxz () {
                that.pacbz = true
            },
            success(pos) {
                var crd = pos.coords;
                window.console.log('Your current position is:');
                window.console.log('Latitude : ' + crd.latitude);
                window.console.log('Longitude: ' + crd.longitude);
                var tmppos = crd.longitude + ',' + crd.latitude
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/regeo?key=22f85a8aed7e77da085713450af984cf&location=' + tmppos,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.from_address = res.data.regeocode.formatted_address
                        localStorage.setItem('fromaddress',JSON.stringify(res.data.regeocode.addressComponent))
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getdefaultaddress () {
                if (navigator.geolocation){
                    window.console.log('支持位置')
                    navigator.geolocation.getCurrentPosition(this.success, error, options);
                }else {
                    window.console.log("您的设备不支持定位功能");
                }
            },
            getcurpos () {
                var that = this
                that.$http({
                    url: 'https://restapi.amap.com/v3/ip?key=22f85a8aed7e77da085713450af984cf',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('当前的位置')
                        window.console.log(res)
                        that.curpos = res.data.city
                        localStorage.setItem('selectcity',JSON.stringify(res.data))
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            showPosition(position) {
                window.console.log( position.coords.latitude + position.coords.longitude);
            },
            startorderin () {
                if (that.from_address !== '' && that.to_address !== '') {
                    localStorage.setItem('from_address',that.from_address)
                    localStorage.setItem('to_address',that.to_address)
                    that.$router.push({ path: 'OrderIn?ordertype=' + that.ordertype + '&ifhx=' + this.ifhx })
                } else {
                    Notify({ type: 'primary', message: '地址信息不完整'});
                }
                that.getfromgps()
            },
            selectcity () {
                that.$router.push({ path: 'SelectCity' })
            },
            fromquerypos () {
                that.$router.push({ path: 'QueryPos?fyway=1&qway=0' })
            },
            toquerypos () {
                that.$router.push({ path: 'QueryPos?fyway=1&qway=1' })
            },
            getfromaddress (pos) {
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/regeo?key=22f85a8aed7e77da085713450af984cf&location=' + pos,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        localStorage.setItem('fromaddress',JSON.stringify(res.data.regeocode.addressComponent))
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            gettoaddress (pos) {
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/regeo?key=22f85a8aed7e77da085713450af984cf&location=' + pos,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        localStorage.setItem('toaddress',JSON.stringify(res.data.regeocode.addressComponent))
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getfromgps () {
                var that = this
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/geo?key=22f85a8aed7e77da085713450af984cf&address=' + that.from_address,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        localStorage.setItem('fromgps',res.data.geocodes[0].location)
                        that.getfromaddress(res.data.geocodes[0].location)
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            gettogps () {
                var that = this
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/geo?key=22f85a8aed7e77da085713450af984cf&address=' + that.to_address,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        localStorage.setItem('togps',res.data.geocodes[0].location)
                        that.gettoaddress(res.data.geocodes[0].location)
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
    function error(err) {
        window.console.warn('ERROR(' + err.code + '): ' + err.message);
    }
</script>
<style>
    .allbg {
        background-color: #f1f1f1;
        background: url("https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221025130804.jpg") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top:0;
        width:100%;
    }
    .lefttxt {
        font-size: 14px;
    }
</style>
