<template>
    <div style="margin: 30px">
        <van-button type="primary" @click="openmainpage">打开首页</van-button>
    </div>
</template>

<script>
    export default {
        name: "TestOpenMain",
        data () {
            return {
            }
        },
        methods: {
            openmainpage () {
                window.open('https://ty.yunshangyunkeji.com/#/?order_no=test111','newwindow',
                    'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=460,height=860')
            }
        }
    }
</script>

<style scoped>

</style>