<template>
    <div class="allorderbg">
        <!--服务选择-->
        <!--
        <div style="height:150px; background-color: #fff; margin-left: 10px;margin-right: 10px;  margin-top: 220px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <div style="height: 40px; display: flex; justify-content: space-between; align-items: center;">
                <div style="font-size: 12px;  height: 40px;width:100px;display: flex;align-items: center;">
                    <van-icon style="margin-left:15px;margin-right: 5px; font-size: 16px;" name="flag-o" />
                    服务选择
                </div>
            </div>
            <div>
                <van-grid>
                    <van-grid-item icon="photo-o" text="服务A" />
                    <van-grid-item icon="photo-o" text="服务B" />
                    <van-grid-item icon="photo-o" text="服务C" />
                    <van-grid-item icon="photo-o" text="服务D" />
                </van-grid>
            </div>
        </div>
        -->
        <!-- 开始下单 -->
        <div style="background-color: #fff; margin-left: 10px;margin-right: 10px;  margin-top: 5px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <div style=" display: flex; justify-content: center;">
                <van-field label-width="70" @blur="getfromgps" label-class="lefttxt" type="textarea" rows="2" style="font-size:12px;margin-top:5px;height: 40px;line-height: 15px; width:100%;" v-model="from_address" label="寄件地址:" placeholder="" >
                    <template #left-icon>
                        <van-icon style="margin-right: 3px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/dizhi.png" />
                    </template>
                    <template #button>
                        <van-icon size="18" style="margin-bottom: 20px;" name="search" @click="fromquerypos"/>
                    </template>
                </van-field>
            </div>
            <div style=" display: flex; justify-content: center; border-bottom: 1px solid #f1f1f1;">
                <van-field label-width="70" @blur="getfromgps" label-class="lefttxt" type="text" style="font-size:11px; width:100%;" v-model="from_address_ex" label="详细地址:" placeholder="" >
                    <template #left-icon>
                        <van-icon style="margin-top:3px;margin-right: 3px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/dizhi%20%281%29.png" />
                    </template>
                </van-field>
            </div>
            <div style=" display: flex; justify-content: center;">
                <van-field label-width="70" @blur="gettogps" label-class="lefttxt" type="textarea" rows="2" style="font-size:12px;margin-top:5px;height: 40px;line-height: 15px; width:100%;" v-model="to_address" label="收货地址:" placeholder="" >
                    <template #left-icon>
                        <van-icon style="margin-right: 3px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/dizhi.png" />
                    </template>
                    <template #button>
                        <van-icon size="18" style="margin-bottom: 20px;" name="search" @click="toquerypos"/>
                    </template>
                </van-field>
            </div>
            <div style=" display: flex; justify-content: center;">
                <van-field label-width="70" @blur="gettogps" label-class="lefttxt" type="text" style="font-size:11px;width:100%;" v-model="to_address_ex" label="详细地址:" placeholder="" >
                    <template #left-icon>
                        <van-icon style="margin-top: 3px;margin-right: 3px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/dizhi%20%281%29.png" />
                    </template>
                </van-field>
            </div>
        </div>
        <div style="background-color: #fff; margin-left: 10px;margin-right: 10px;  margin-top: 10px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <div style="display: flex; justify-content: center;align-items: center;border-bottom: 1px solid #f1f1f1;">
                <van-field readonly @click="showfromman" label-class="lefttxt" style="width:100%;line-height: 30px;margin-top:5px;" v-model="from_man"  label="寄件人" placeholder="请输入寄件人信息" >
                    <template #left-icon>
                        <van-icon style="margin-top: 6px;margin-right: 3px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/jijian_jihuo.png" />
                    </template>
                    <template #button>
                        <van-button color="#F8BB4A" icon="manager-o" @click="showmanmana(1)" size="small">管理</van-button>
                    </template>
                </van-field>
            </div>
            <div style="display: flex; justify-content: center; align-items: center;">
                <van-field readonly @click="showtoman" label-class="lefttxt" style="line-height: 30px; width:100%;" v-model="to_man" label="收件人" placeholder="请输入收件人信息" >
                    <template #left-icon>
                        <van-icon style="margin-top: 6px;margin-right: 3px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/shoujian.png" />
                    </template>
                    <template #button>
                        <van-button color="#F8BB4A" icon="manager-o" @click="showmanmana(2)"  size="small">管理</van-button>
                    </template>
                </van-field>
            </div>
        </div>
        <div style="background-color: #fff; margin-left: 10px;margin-right: 10px; margin-top: 10px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <van-field v-if="ordertype !== '1'" label-width="70" @blur="checkorder" label-class="lefttxt" type="text" style="margin-top: 5px;font-size:12px;line-height: 30px; width:100%;" v-model="flight_no" label="航班号:" placeholder="如CA1301" >
                <template #left-icon>
                    <van-icon style="margin-top: 6px;margin-right: 3px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/yangshi_icon_tongyong_departure.png" />
                </template>
            </van-field>
            <div v-if="ordertype !== '1'" style="margin-top: 5px; display: flex; justify-content: center;">
                <van-cell  title="起飞日期" label-class="lefttxt" style=" width:100%;" is-link :value="fly_date" @click="flydateshow = true">
                    <template #icon>
                        <van-icon style="margin-top: 5px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/yangshi_icon_tongyong_departure.png" />
                    </template>
                </van-cell>
                <van-calendar v-model="flydateshow" @confirm="onFlyDateConfirm" />
            </div>
            <div v-if="ordertype !== '1'" style="margin-top: 5px; display: flex; justify-content: center;border-bottom: 1px solid #f1f1f1;">
                <van-cell title="起飞时间" label-class="lefttxt" style=" width:100%;" is-link :value="fly_time" @click="showflyPicker = true">
                    <template #icon>
                        <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/shijian.png" />
                    </template>
                </van-cell>
                <van-popup v-model="showflyPicker" position="bottom">
                    <van-datetime-picker
                            type="time"
                            :min-hour="0"
                            :max-hour="23"
                            @confirm="onFlyTimeConfirm"
                            @cancel="showflyPicker = false"
                    />
                </van-popup>
            </div>
            <div v-if="ordertype !== '1'">
                <div style="margin-top: 5px; display: flex; justify-content: center;">
                    <van-cell title="上门取件日期" label-class="lefttxt" style=" width:100%;" is-link :value="from_date" @click="fromdateshow = true">
                        <template #icon>
                            <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/riqi.png" />
                        </template>
                    </van-cell>
                    <van-calendar v-model="fromdateshow" @confirm="onFromDateConfirm" />
                </div>
                <div style="margin-top: 5px; display: flex; justify-content: center;border-bottom: 1px solid #f1f1f1;">
                    <van-cell title="期望上门时间" label-class="lefttxt" style=" width:100%;" is-link :value="from_time" @click="showfromPicker = true">
                        <template #icon>
                            <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/shoujianchenggong.png" />
                        </template>
                    </van-cell>
                    <van-popup v-model="showfromPicker" position="bottom">
                        <van-datetime-picker
                                type="time"
                                :min-hour="7"
                                :max-hour="23"
                                :max-minute="0"
                                @confirm="onFromConfirm"
                                @cancel="showfromPicker = false"
                        />
                    </van-popup>
                </div>
                <!--
                <div style="margin-top: 5px; display: flex; justify-content: center;">
                    <van-cell title="行李送达日期" label-class="lefttxt" style=" width:100%;" is-link :value="to_date" @click="todateshow = true">
                        <template #icon>
                            <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/jijian_jihuo.png" />
                        </template>
                    </van-cell>
                    <van-calendar v-model="todateshow" @confirm="onToDateConfirm" />
                </div>
                <div style="margin-top: 5px; display: flex; justify-content: center;border-bottom: 1px solid #f1f1f1;">
                    <van-cell title="期望送达时间" label-class="lefttxt" style=" width:100%;" is-link :value="to_time" @click="showtoPicker = true">
                        <template #icon>
                            <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/shijian.png" />
                        </template>
                    </van-cell>
                    <van-popup v-model="showtoPicker" position="bottom">
                        <van-datetime-picker
                                swipe-duration="200"
                                type="time"
                                :min-hour="mintohour"
                                :max-hour="maxtohour"
                                @confirm="onToConfirm"
                                @cancel="showtoPicker = false"
                        />
                    </van-popup>
                </div>
                -->
            </div>
            <div v-if="ordertype !== '2'">
                <div style="margin-top: 5px; display: flex; justify-content: center;">
                    <van-cell title="寄件日期" label-class="lefttxt" style=" width:100%;" is-link :value="from_date" @click="fromdateshow = true">
                        <template #icon>
                            <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/riqi.png" />
                        </template>
                    </van-cell>
                    <van-calendar v-model="fromdateshow" @confirm="onFromDateConfirm" />
                </div>
                <div style="margin-top: 5px; display: flex; justify-content: center;border-bottom: 1px solid #f1f1f1;">
                    <van-cell title="期望寄件时间" label-class="lefttxt" style=" width:100%;" is-link :value="from_time" @click="showfromPicker = true">
                        <template #icon>
                            <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/shoujianchenggong.png" />
                        </template>
                    </van-cell>
                    <van-popup v-model="showfromPicker" position="bottom">
                        <van-datetime-picker
                                type="time"
                                :min-hour="7"
                                :max-hour="23"
                                @confirm="onFromConfirm"
                                @cancel="showfromPicker = false"
                        />
                    </van-popup>
                </div>
                <div style="margin-top: 5px; display: flex; justify-content: center;">
                    <van-cell title="送达日期" label-class="lefttxt" style=" width:100%;" is-link :value="to_date" @click="todateshow = true">
                        <template #icon>
                            <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/jijian_jihuo.png" />
                        </template>
                    </van-cell>
                    <van-calendar v-model="todateshow" @confirm="onToDateConfirm" />
                </div>
                <div style="margin-top: 5px; display: flex; justify-content: center;border-bottom: 1px solid #f1f1f1;">
                    <van-cell title="期望送达时间" label-class="lefttxt" style=" width:100%;" is-link :value="to_time" @click="showtoPicker = true">
                        <template #icon>
                            <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/shijian.png" />
                        </template>
                    </van-cell>
                    <van-popup v-model="showtoPicker" position="bottom">
                        <van-datetime-picker
                                swipe-duration="200"
                                type="time"
                                :min-hour="mintohour"
                                :max-hour="maxtohour"
                                @confirm="onToConfirm"
                                @cancel="showtoPicker = false"
                        />
                    </van-popup>
                </div>
            </div>
        </div>

        <div style="overflow:auto; background-color: #fff; margin-left: 10px;margin-right: 10px;  margin-top: 10px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <div style="border-bottom:1px solid #f3f3f3; height: 40px; display: flex; justify-content: space-between; align-items: center;">
                <div style="font-size: 12px;  height: 40px;width:100px;display: flex;align-items: center;">
                    <div style="font-size: 16px;font-weight:500;margin-left: 15px; display: flex; align-items: center; justify-content: center;">
                        <van-icon style="margin-right: 5px;margin-top: 2px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/hanglixiang.png" />
                        行李信息
                    </div>
                </div>
                <div @click="lookxlbz" style="font-size: 12px;color: #cccccc;display: flex;align-items: center;">
                    查看行李标准
                    <van-icon style="margin-left:5px;margin-right: 10px; font-size: 14px;" name="info-o" />
                </div>
            </div>
            <div style="margin-top: 5px; display: flex; justify-content: center;">
                <van-field label-width="70" name="stepper" label-class="lefttxt" label="行李件数" style="height: 50px;line-height: 30px; width:100%;">
                    <template #input>
                        <van-stepper @change="savecount" v-model="gcount" />
                    </template>
                </van-field>
            </div>
            <div style="margin-top: 5px; display: flex; justify-content: center;">
                <van-field label-width="70" name="uploader" label-class="lefttxt" label="行李照片" style="width:100%;">
                    <template #input>
                        <van-uploader v-model="fileList" accept="image/*" multiple :after-read="fnUploadRequest"/>
                    </template>
                </van-field>
            </div>
            <div>
                <van-progress v-if="percentageType == true" :percentage="percentage" color="#3fb776" style="margin-left: 90px; margin-right:20px; margin-top: 5px; margin-bottom: 5px;"/>
            </div>
        </div>
        <div style="height:46px; overflow:auto; background-color: #fff; margin-left: 10px;margin-right: 10px;  margin-top: 10px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <van-coupon-cell
                    :coupons="coupons"
                    :chosen-coupon="chosenCoupon"
                    @click="showyhq"
            />
            <!-- 优惠券列表 -->
            <van-popup
                    v-model="showList"
                    round
                    position="bottom"
                    style="height: 90%; padding-top: 4px;"
            >
                <van-coupon-list
                        :coupons="coupons"
                        :chosen-coupon="chosenCoupon"
                        :disabled-coupons="disabledCoupons"
                        @change="onYHQChange"
                        @exchange="onYHQExchange"
                />
            </van-popup>
        </div>
        <div style="height:160px; background-color: #fff; margin-left: 10px;margin-right: 10px;  margin-top: 10px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <div style="margin-top: 5px; display: flex; justify-content: center;">
                <van-field
                        style="width:100%;"
                        v-model="orderdes"
                        rows="2"
                        @blur="savedes"
                        autosize
                        label="备注"
                        type="textarea"
                        maxlength="50"
                        placeholder="请输入备注"
                />
            </div>
        </div>
        <van-dialog v-model="pricebz" title="计费标准">
            <div style="padding: 30px; font-size: 14px; color: #2c3e50">
                <p>里程计费：</p>
                <p>1、普通时段：50公里内起步价99元起。</p>
                <p>2、分段价：超出50公里范围3.0元/公里，不足一公里按一公里计费。</p>
                <p style="margin-top: 30px;">托运行李标准：</p>
                <p>1、托运行李重量标准为：26公斤。</p>
                <p>2、行李外箱尺寸：≤28寸。</p>
                <p>3、异形物品超出尺寸范围加收49元。</p>
            </div>
        </van-dialog>
        <van-dialog v-model="pacbz" title="行李标准">
            <div style="padding: 30px; font-size: 14px; color: #2c3e50;height: 450px; overflow-y: auto;">
                <p>在您使用“行李配送”服务之前，请阅读并知晓以下内容：</p>
                <p>1.待运输的行李必须包装完善、锁扣完好，能承受一定的压力，能够在正常的操作条件下安全装卸和运输，并应符合下列条件和要求</p>
                <p>（1）旅行箱、旅行袋和手提包等必须加锁；</p>
                <p>（2）两件以上的包件，不能捆为一件；</p>
                <p>（3）行李上不能附插其他物品；</p>
                <p>（4）竹篮、网兜、草绳、草袋、塑料袋等不能作为行李的外包装物。</p>
                <p>（5）托运物品中如有干冰需提前告知，并包装完好留有气孔，以防干冰产生二氧化碳发生危险。</p>
                <p>（6）托运贵重物品、生鲜、易碎物品时，视为旅客您已完全知晓托运（含运输）时，托运方全部免责内容。</p>
                <p style="margin-top: 30px;">2.不可运输行李：</p>
                <p>（1）精密仪器、电器、精致乐器、贵重金属等；</p>
                <p>（2）用于狩猎和体育运动的枪支弹药；</p>
                <p>（3）小动物；</p>
                <p>（4）干冰；</p>
                <p>（5）管制刀具类利器、钝器、各类火种和点火装置、易爆品等。</p>
                <p style="margin-top: 30px;">3.若您对于上述规则有所疑问，请拨打领客服热线 4006-620610寻求建议。</p>
                <p style="margin-top: 30px;">4.若您选择继续使用“行李配送”服务，则代表您已确认所托运行李符合上述规定。</p>
            </div>
        </van-dialog>
        <van-submit-bar custom-class="mysubmit" :disabled="disorder" :tip="ordertip" button-text="提交订单" @submit="onSubmit">
            <template #default>
                <div style=" width: 240px; padding-left: 10px; display: flex; flex-direction: column; justify-content: flex-start;">
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <div>
                            <van-icon @click="showbz" style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/%21.png" />
                        </div>
                        <div style="font-size: 21px;">合计运费: <span style="font-size: 22px;color: #EA2F1E;font-weight: 600"> ￥{{(jscount/100).toFixed(1)}} </span></div>
                    </div>
                    <div @click="showbz" style="display: flex; flex-direction: row; color: #8a8a8a; font-size: 13px;margin-top: 5px;align-items: center;">
                        <div>超重超公里数另加费用,详情点击</div>
                        <div style="margin-left: 5px;margin-top: 3px;">
                            <van-icon style="margin-right: 5px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/%EF%BC%9F.png" />
                        </div>
                    </div>
                </div>
            </template>
        </van-submit-bar>
        <van-overlay style="display: flex; align-items: center; justify-content: center;" :show="loadshow">
            <div style="display: flex; align-items: center; flex-direction: column; justify-content: center;">
                <van-loading type="spinner" />
                <div style="color:#fff;margin-top: 10px;">支付中...</div>
            </div>
        </van-overlay>
    </div>

</template>

<script>
    import Vue from 'vue';
    import Utils from '../../util/utils'
    import { Form, Field, CellGroup } from 'vant';
    import { Button } from 'vant';
    import { Icon } from 'vant';
    import { SubmitBar } from 'vant';
    import { Stepper } from 'vant';
    import { Grid, GridItem } from 'vant';
    import { Uploader } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Notify } from 'vant';
    import { Progress } from 'vant';
    import { Calendar } from 'vant';
    import { CouponCell, CouponList } from 'vant';
    import { Loading } from 'vant';
    import { Overlay } from 'vant';

    Vue.use(Overlay);
    Vue.use(Loading);
    // import jsBridge from '@zebing/js-bridge';
    Vue.use(DatetimePicker);
    Vue.use(Calendar);
    Vue.use(Notify);
    Vue.use(CouponCell);
    Vue.use(CouponList);
    Vue.use(Progress);
    Vue.use(Popup);
    Vue.use(Stepper);
    Vue.use(Picker);
    Vue.use(Grid);
    Vue.use(Uploader);
    Vue.use(GridItem);
    Vue.use(SubmitBar);
    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Icon);
    Vue.use(Field);
    Vue.use(CellGroup);
    var OSS = require('ali-oss')
    var that
    export default {
        name: "OrderIn",
        data () {
            return {
                curpos: '北京市',
                orderdes: '',
                showList: false,
                from_address_ex: '',
                to_address_ex: '',
                loadshow: false,
                showfromPicker: false,
                showtoPicker: false,
                flydateshow: false,
                pricebz: false,
                pacbz: false,
                showflyPicker: false,
                disorder: true,
                ordertip: '',
                fly_date: '',
                fly_time: '',
                ifhx: '',
                ordertype: '',
                jscount: 0,
                from_date: '',
                to_date: '',
                from_address: '',
                showhbh: false,
                flightno: '',
                mintohour: 7,
                maxtohour: 23,
                fromdateshow: false,
                todateshow: false,
                totimedisable: false,
                to_address: '',
                from_man: '',
                to_man: '',
                colheight: '',
                flight_no: '',
                gcount: 1,
                from_time: '',
                to_time: '',
                hxorder_no: '',
                proxy_phone: '',
                user_phone: '',
                percentageType: false,
                percentage: 0,
                fileList: [],
                upfileList: [],
                fileurls: [],
                fileIds: [],
                selectpath: 'cus',
                xlimgs: '',
                jlinfo: '',
                min_price: 0,
                min_kilometer: 0,
                next_kilometer_price: 0,
                hxmin_price: 0,
                hxmin_kilometer: 0,
                hxnext_kilometer_price: 0,
                if_hb: '0',
                hb_orderno: '',
                coupons: [],
                disabledCoupons: [],
                coupon: '',
                chosenCoupon: -1,
                hyqid: '',
                yhje: 0,
                accesstoken: '',
                wxaccesstoken: '',
                curorder: '',
                curdriver: '',
                indrivers: ''
            }
        },
        async mounted() {
            that = this
            that.from_address = localStorage.getItem('from_address')
            that.to_address = localStorage.getItem('to_address')
            that.ordertype = this.$route.query.ordertype
            that.ifhx = this.$route.query.ifhx
            that.getfromgps()
            that.gettogps()
            that.getcouponuse()
            if (localStorage.getItem('lxrfrominfo') !== undefined && localStorage.getItem('lxrfrominfo') !== null && localStorage.getItem('lxrfrominfo') !== '') {
                var tmpdata = JSON.parse(localStorage.getItem('lxrfrominfo'))
                window.console.log('寄件人' + tmpdata)
                that.from_man = tmpdata.manname
            } else {
                that.getfman()
            }
            if (localStorage.getItem('lxrtoinfo') !== undefined && localStorage.getItem('lxrtoinfo') !== null && localStorage.getItem('lxrtoinfo') !== '') {
                var tmpdata1 = JSON.parse(localStorage.getItem('lxrtoinfo'))
                window.console.log('收件人' + tmpdata1)
                that.to_man = tmpdata1.manname
            } else {
                that.gettman()
            }
            that.colheight =  window.innerHeight
            var myDate = new Date();
            that.from_date = that.formatDate(myDate)
            that.to_date = that.formatDate(myDate)
            that.fly_date = that.formatDate(myDate)
            if (localStorage.getItem('flight_no') !== undefined && localStorage.getItem('flight_no') !== null && localStorage.getItem('flight_no') !== '') {
                that.flight_no = localStorage.getItem('flight_no')
            } else {
                that.flight_no = ''
            }
            if (localStorage.getItem('fly_date') !== undefined && localStorage.getItem('fly_date') !== null && localStorage.getItem('fly_date') !== '') {
                that.fly_date = localStorage.getItem('fly_date')
            } else {
                that.fly_date = ''
            }
            if (localStorage.getItem('fly_time') !== undefined && localStorage.getItem('fly_time') !== null && localStorage.getItem('fly_time') !== '') {
                that.fly_time = localStorage.getItem('fly_time')
            } else {
                that.fly_time = ''
            }
            if (localStorage.getItem('from_date') !== undefined && localStorage.getItem('from_date') !== null && localStorage.getItem('from_date') !== '') {
                that.from_date = localStorage.getItem('from_date')
            } else {
                that.from_date = ''
            }
            if (localStorage.getItem('from_time') !== undefined && localStorage.getItem('from_time') !== null && localStorage.getItem('from_time') !== '') {
                that.from_time = localStorage.getItem('from_time')
            } else {
                that.from_time = ''
            }
            if (localStorage.getItem('to_date') !== undefined && localStorage.getItem('to_date') !== null && localStorage.getItem('to_date') !== '') {
                that.to_date = localStorage.getItem('to_date')
            } else {
                that.to_date = ''
            }
            if (localStorage.getItem('to_time') !== undefined && localStorage.getItem('to_time') !== null && localStorage.getItem('to_time') !== '') {
                that.to_time = localStorage.getItem('to_time')
            } else {
                that.to_time = ''
            }
            if (localStorage.getItem('gcount') !== undefined && localStorage.getItem('gcount') !== null && localStorage.getItem('gcount') !== '') {
                that.gcount = localStorage.getItem('gcount')
            } else {
                that.gcount = 1
            }
            if (localStorage.getItem('orderdes') !== undefined && localStorage.getItem('orderdes') !== null && localStorage.getItem('orderdes') !== '') {
                that.orderdes = localStorage.getItem('orderdes')
            } else {
                that.orderdes = ''
            }
            that.checkdate()
            if (localStorage.getItem('fileList') !== undefined && localStorage.getItem('fileList') !== null && localStorage.getItem('fileList') !== '') {
                that.fileList = JSON.parse(localStorage.getItem('fileList'))
                window.console.log('文件信息',that.fileList)
            } else {
                that.fileList = []
            }

            var at = await getaccesstoken()
            that.accesstoken = at
            that.wxaccesstoken = await getwxaccesstoken()
            /*
            if (localStorage.getItem('hyqid') !== undefined && localStorage.getItem('hyqid') !== null && localStorage.getItem('hyqid') !== '') {
                that.hyqid = localStorage.getItem('hyqid')
                this.coupons = JSON.parse(localStorage.getItem('coupons'))
                this.chosenCoupon = localStorage.getItem('hyqindex')
            } else {
                that.hyqid = ''
            }
            */
            if (localStorage.getItem('yhje') !== undefined && localStorage.getItem('yhje') !== null && localStorage.getItem('yhje') !== '') {
                that.yhje = localStorage.getItem('yhje')
            } else {
                that.yhje = ''
            }
            /*判定默认是否可以提交*/
            if (that.ordertype === 1) {
                if (that.from_man !== '' && that.to_man !== '' && that.from_time !== '' && that.to_time !== '' && that.xlimgs !== '') {
                    window.console.log('数据校验完成')
                } else {
                    that.disorder = true
                }
            }
            if (that.ordertype === 2) {
                if (that.from_man !== '' && that.to_man !== '' && that.from_time !== '' && that.fly_time !== '' && that.xlimgs !== '') {
                    window.console.log('数据校验完成')
                } else {
                    that.disorder = true
                }
            }
            localStorage.setItem('hyqid','')
            localStorage.setItem('hyqindex','')
            localStorage.setItem('yhje','')
        },
        methods: {
            showbz () {
                this.pricebz = true
            },
            lookxlbz () {
                this.pacbz = true
            },
            sendmsg () {
                var that = this
                let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                var updata = {
                    accesstoken: this.wxaccesstoken,
                    touser: localStorage.getItem('openid'),
                    template_id: 'uv94HONw3lkWc92K01uVapmGA0VDs-ov5kA1lFG8oAA',
                    url: '',
                    data: {
                        keyword1: {
                            value: this.from_address //发货地
                        },
                        keyword2: {
                            value: this.to_address // 收货地
                        },
                        keyword3: {
                            value: nowStr // 时间
                        }
                    },
                    color: '#000000'
                }
                that.$http({
                    url: 'https://wx.yunshangyunkeji.com/wx/sendtmpmsg',
                    method: 'POST',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                    })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            window.console.log('消息推送成功')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getfman () {
                var qstr = {
                    whereinfo: "ownuser='" + localStorage.getItem('userphone') + "' and mantype = 1",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querylxr',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            that.from_man = res.data[0].manname
                            localStorage.setItem('lxrfrominfo',JSON.stringify(res.data[0]))
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            gettman () {
                var qstr = {
                    whereinfo: "ownuser='" + localStorage.getItem('userphone') + "' and mantype = 2",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querylxr',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            that.to_man = res.data[0].manname
                            localStorage.setItem('lxrtoinfo',JSON.stringify(res.data[0]))
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            checkyf () {
                if (that.ifhx === '0') {
                    that.getdj()
                } else {
                    // 航信订单，需要获取当前下单人的运价方式
                    that.getdlsjg()
                }
            },
            savecount () {
                window.console.log(that.jlinfo)
                window.console.log(that.min_kilometer)
                window.console.log(that.min_price)
                window.console.log(that.gcount)
                if (that.ifhx === '1') {
                    if (parseInt(that.jlinfo) <= parseInt(that.hxmin_kilometer)) {
                        that.jscount = parseInt(that.hxmin_price*that.gcount) * 100
                    } else {
                        var jltmp1 = parseInt(that.jlinfo - that.hxmin_kilometer)
                        that.jscount = (parseInt(that.hxmin_price*that.gcount) + parseInt(jltmp1 * that.hxnext_kilometer_price)) * 100
                    }
                    if (localStorage.getItem('oldje') !== null && localStorage.getItem('oldje') !== 'NaN' && localStorage.getItem('yhje') !== 'NaN' && localStorage.getItem('yhje') !== null && localStorage.getItem('yhje') !== '' && localStorage.getItem('yhje') !== undefined && localStorage.getItem('oldje') !== undefined && localStorage.getItem('oldje') !== '') {
                        that.jscount = that.jscount - localStorage.getItem('yhje')
                    }
                    localStorage.setItem('gcount',this.gcount)
                } else {
                    if (parseInt(that.jlinfo) <= parseInt(that.min_kilometer)) {
                        that.jscount = parseInt(that.min_price*that.gcount) * 100
                    } else {
                        var jltmp = parseInt(that.jlinfo - that.min_kilometer)
                        that.jscount = (parseInt(that.min_price * that.gcount) + parseInt(jltmp * that.next_kilometer_price)) * 100
                    }
                    if (localStorage.getItem('oldje') !== null && localStorage.getItem('oldje') !== 'NaN' && localStorage.getItem('yhje') !== 'NaN' && localStorage.getItem('yhje') !== null && localStorage.getItem('yhje') !== '' && localStorage.getItem('yhje') !== undefined && localStorage.getItem('oldje') !== undefined && localStorage.getItem('oldje') !== '') {
                        that.jscount = that.jscount - localStorage.getItem('yhje')
                    }
                    localStorage.setItem('gcount',this.gcount)
                }

            },
            savedes () {
                localStorage.setItem('orderdes',this.orderdes)
            },
            fromquerypos () {
                that.$router.push({ path: 'QueryPos?fyway=1&qway=0' })
            },
            toquerypos () {
                that.$router.push({ path: 'QueryPos?fyway=1&qway=1' })
            },
            getfromaddress (pos) {
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/regeo?key=22f85a8aed7e77da085713450af984cf&location=' + pos,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        localStorage.setItem('fromaddress',JSON.stringify(res.data.regeocode.addressComponent))
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            gettoaddress (pos) {
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/regeo?key=22f85a8aed7e77da085713450af984cf&location=' + pos,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        localStorage.setItem('toaddress',JSON.stringify(res.data.regeocode.addressComponent))
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getfromgps () {
                var that = this
                localStorage.setItem('from_address',that.from_address + ' ' + that.from_address_ex)
                /*
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/geo?key=22f85a8aed7e77da085713450af984cf&address=' + that.from_address + ' ' + that.from_address_ex,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        localStorage.setItem('fromgps',res.data.geocodes[0].location)
                        that.checkyf()
                        that.getfromaddress(res.data.geocodes[0].location)
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                    */
                that.$http({
                    url: 'https://restapi.amap.com/v3/place/text?key=22f85a8aed7e77da085713450af984cf&keywords=' + that.from_address + ' ' + that.from_address_ex,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        localStorage.setItem('fromgps',res.data.pois[0].location)
                        that.checkyf()
                        that.getfromaddress(res.data.pois[0].location)
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            gettogps () {
                var that = this
                localStorage.setItem('to_address',that.to_address + ' ' + that.to_address_ex)
                /*
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/geo?key=22f85a8aed7e77da085713450af984cf&address=' + that.to_address + ' ' + that.to_address_ex,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        localStorage.setItem('togps',res.data.geocodes[0].location)
                        that.checkyf()
                        that.gettoaddress(res.data.geocodes[0].location)
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                    */
                that.$http({
                    url: 'https://restapi.amap.com/v3/place/text?key=22f85a8aed7e77da085713450af984cf&keywords=' + that.to_address + ' ' + that.to_address_ex,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        localStorage.setItem('togps',res.data.pois[0].location)
                        that.checkyf()
                        that.gettoaddress(res.data.pois[0].location)
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getdlsjg () {
                var qstr = {
                    whereinfo: " dlsphone='13011111111'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryfreighthxinfo',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            var tmpdata = res.data[0]
                            that.hxmin_price = tmpdata.min_price
                            that.hxmin_kilometer = tmpdata.min_kilometre
                            that.hxnext_kilometer_price = tmpdata.next_kilometre_price
                            that.hxz_min_kilometre = tmpdata.z_min_kilometre
                            that.allowpaccount = tmpdata.allowpaccount
                            that.getdistancebyhx()
                        } else {
                            that.hxmin_price = 0
                            that.hxmin_kilometer = 0
                            that.hxnext_kilometer_price = 0
                            Notify({ type: 'danger', message: '基价获取失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            showyhq () {
                this.chosenCoupon = parseInt(localStorage.getItem('hyqindex'))
                window.console.log('取消优惠券')
                if (localStorage.getItem('oldje') !== null && localStorage.getItem('oldje') !== 'NaN' && localStorage.getItem('yhje') !== 'NaN' && localStorage.getItem('yhje') !== null && localStorage.getItem('yhje') !== '' && localStorage.getItem('yhje') !== undefined && localStorage.getItem('oldje') !== undefined && localStorage.getItem('oldje') !== '') {
                    if (parseInt(localStorage.getItem('oldje')) <= parseInt(localStorage.getItem('yhje'))) {
                        window.console.log('小于优惠金额')
                        this.jscount = parseInt(localStorage.getItem('oldje'))
                    } else {
                        window.console.log('超过优惠金额')
                        this.jscount = this.jscount + parseInt(localStorage.getItem('yhje'))
                    }
                    window.console.log(this.jscount)
                    localStorage.setItem('hyqid','')
                    localStorage.setItem('hyqindex','')
                    localStorage.setItem('yhje','')
                    localStorage.setItem('oldje','')
                }
                this.showList = true
            },
            onYHQChange (index) {
                this.showList = false;
                window.console.log(index)
                if (index >= 0) {
                    window.console.log('选择优惠券')
                    this.chosenCoupon = index;
                    window.console.log(this.coupons[index])
                    var tmpcoupons = this.coupons
                    var tmpdata = tmpcoupons[index]
                    this.hyqid = tmpdata.id
                    tmpdata.available = 1
                    this.coupons = tmpcoupons
                    this.yhje = parseInt(tmpdata.value)
                    if (parseInt(this.hyqid) === parseInt(localStorage.getItem('hyqid'))) {
                        Notify({ type: 'warning', message: '卡券已使用' })
                    } else {
                        var jetmp = this.jscount
                        localStorage.setItem('oldje',this.jscount)
                        if (jetmp - this.yhje < 0) {
                            jetmp = 0
                        } else {
                            jetmp = jetmp - this.yhje
                        }
                        this.jscount = jetmp
                        localStorage.setItem('hyqid',this.hyqid)
                        localStorage.setItem('hyqindex',index)
                        localStorage.setItem('yhje',this.yhje)
                        localStorage.setItem('coupons',JSON.stringify(this.coupons))
                    }
                } else {
                    if (localStorage.getItem('oldje') !== null && localStorage.getItem('oldje') !== 'NaN' && localStorage.getItem('yhje') !== 'NaN' && localStorage.getItem('yhje') !== null && localStorage.getItem('yhje') !== '' && localStorage.getItem('yhje') !== undefined && localStorage.getItem('oldje') !== undefined && localStorage.getItem('oldje') !== '') {
                        window.console.log('取消优惠券')
                        if (parseInt(localStorage.getItem('oldje')) <= parseInt(localStorage.getItem('yhje'))) {
                            window.console.log('小于优惠金额')
                            this.jscount = parseInt(localStorage.getItem('oldje'))
                        } else {
                            window.console.log('超过优惠金额')
                            this.jscount = this.jscount + parseInt(localStorage.getItem('yhje'))
                        }
                        window.console.log(this.jscount)
                        localStorage.setItem('hyqid','')
                        localStorage.setItem('hyqindex','')
                        localStorage.setItem('yhje','')
                        localStorage.setItem('oldje','')
                    }
                }
            },
            onYHQExchange (code) {
                this.coupons.push(code);
            },
            getcouponuse () {
                let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                var qstr = {
                    whereinfo: " userid='" + localStorage.getItem('userphone') + "' and startdate <='" + nowStr + "' and enddate >='" + nowStr + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querycouponuse',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('获取的优惠券数据')
                        window.console.log(res)
                        window.console.log(res.data)
                        localStorage.setItem('hyqid','')
                        if (res.data.length > 0) {
                            that.coupons = []
                            that.disabledCoupons = []
                            for (var i = 0; i < res.data.length; i++) {
                                var tmpdata = res.data[i]
                                window.console.log('时间戳',tmpdata.startdate)
                                if (tmpdata.is_use === 0) {
                                    var adata = {
                                        id: tmpdata.id,
                                        available: tmpdata.is_use,
                                        condition: '无门槛',
                                        reason: '',
                                        value: tmpdata.couponprice,
                                        name: tmpdata.couponname,
                                        startAt: parseInt(new Date(tmpdata.startdate.replace(/-/g, '/')).getTime() / 1000),
                                        endAt: parseInt(new Date(tmpdata.startdate.replace(/-/g, '/')).getTime() / 1000),
                                        valueDesc: tmpdata.couponprice / 100,
                                        unitDesc: '元',
                                    }
                                    that.coupons.push(adata)
                                }
                                if (tmpdata.is_use === 1) {
                                    var adata1 = {
                                        available: tmpdata.is_use,
                                        condition: '无门槛',
                                        reason: '',
                                        value: tmpdata.couponprice,
                                        name: tmpdata.couponname,
                                        startAt: new Date(tmpdata.startdate.replace(/-/g, '/')).getTime().format('YYYY/MM/DD HH:mm:ss'),
                                        endAt: new Date(tmpdata.startdate.replace(/-/g, '/')).getTime().format('YYYY/MM/DD HH:mm:ss'),
                                        valueDesc: tmpdata.couponprice,
                                        unitDesc: '元',
                                    }
                                    that.disabledCoupons.push(adata1)
                                }
                            }
                            window.console.log('赋值后优惠券',that.coupons)
                        } else {
                            that.coupons = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })

            },
            checkorder () {
                localStorage.setItem('flight_no',this.flight_no)
                var qstr = {
                    whereinfo: " userphone='" + localStorage.getItem('userphone') + "' and flightno='" + this.flight_no + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryorder',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            var tmpdata = res.data[0]
                            that.if_hb = '1'
                            that.hb_orderno = tmpdata.orderno
                            // Notify({ type: 'warning', message: '已存在相关订单，将标注为合并订单' })
                        } else {
                            that.if_hb = '0';
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            onFlyDateConfirm (dates) {
                that.fly_date = that.formatDate(dates)
                that.flydateshow = false
                localStorage.setItem('fly_date',that.fly_date)
                that.checkdate()
            },
            checkdate () {
                window.console.log('时间检测')
                var datatmp1 = ''
                var tmptime = ''
                if (that.to_time === '') {
                    tmptime = '23: 59'
                } else {
                    tmptime = that.to_time
                }
                var datatmp2 = that.from_date + ' ' + that.from_time
                var datatmp3 = that.to_date + ' ' + tmptime
                window.console.log(parseInt(this.ordertype) === 1)
                if (parseInt(this.ordertype) === 1) {
                    var sjc1 = howLong(new Date(datatmp3),new Date(datatmp2))
                    window.console.log(sjc1)
                    if (sjc1.hours < 4 && sjc1.day <= 0) {
                        that.disorder = true
                        that.from_date = ''
                        that.from_time  = ''
                        that.to_date = ''
                        that.to_time  = ''
                        localStorage.setItem('to_date','')
                        localStorage.setItem('to_time','')
                        localStorage.setItem('from_date','')
                        localStorage.setItem('from_time','')
                        Notify({ type: 'warning', message: '送达时间不允许小于寄件时间' })
                    } else {
                        that.disorder = false
                    }
                    if (that.from_time !== '' && that.to_time !== '') {
                        that.disorder = false
                    } else {
                        that.disorder = true
                    }
                } else {
                    if (that.fly_date === '' || that.fly_time === '') {
                        return
                    }
                    datatmp1 = that.fly_date + ' ' + that.fly_time
                    var sjc3 = howLong(new Date(datatmp1),new Date())
                    window.console.log(sjc3.hours > 8 || sjc3.day >= 0)
                    if (sjc3 !== undefined) {
                        if (sjc3.hours > 8 || sjc3.day > 0) {
                            window.console.log(sjc3)
                        } else {
                            that.disorder = true
                            that.fly_date = ''
                            that.fly_time  = ''
                            that.from_date = ''
                            that.from_time  = ''
                            localStorage.setItem('fly_date','')
                            localStorage.setItem('fly_time','')
                            localStorage.setItem('from_date','')
                            localStorage.setItem('from_time','')
                            Notify({ type: 'warning', message: '起飞时间距离现在时间必须超过8个小时' })
                        }
                    }
                    if (that.from_date === '' || that.from_time === '') {
                        return
                    }
                    var sjc2 = howLong(new Date(datatmp1),new Date(datatmp2))
                    window.console.log(sjc2)
                    window.console.log(sjc2.hours >= 6 || sjc2.day > 0)
                    if (sjc2 !== undefined) {
                        if (sjc2.hours >= 6 || sjc2.day > 0) {
                            that.disorder = false
                        } else {
                            that.disorder = true
                            that.fly_date = ''
                            that.fly_time  = ''
                            that.from_date = ''
                            that.from_time  = ''
                            localStorage.setItem('fly_date','')
                            localStorage.setItem('fly_time','')
                            localStorage.setItem('from_date','')
                            localStorage.setItem('from_time','')
                            Notify({ type: 'warning', message: '取件时间距离起飞时间至少6个小时' })
                        }
                    }
                    var sjc5 = howLong(new Date(datatmp2),new Date())
                    if (sjc5.hours > 8 || sjc5.day >= 0) {
                        window.console.log(sjc5)
                    } else {
                        that.disorder = true
                        that.from_date = ''
                        that.from_time  = ''
                        localStorage.setItem('from_date','')
                        localStorage.setItem('from_time','')
                        Notify({ type: 'warning', message: '下单时间距离现在时间必须超过8个小时' })
                    }
                }
            },
            onFlyTimeConfirm (value) {
                that.fly_time = value
                localStorage.setItem('fly_time',that.fly_time)
                var datatmp = that.fly_date + ' ' + that.fly_time
                window.console.log(datatmp)
                that.showflyPicker = false
                that.checkdate()
            },
            getdj () {
                var qstr = {
                    whereinfo: ' cusid=1',
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryfreightinfo',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            var tmpdata = res.data[0]
                            that.min_price = tmpdata.min_price
                            that.min_kilometer = tmpdata.min_kilometre
                            that.next_kilometer_price = tmpdata.next_kilometre_price
                            that.getdistance()
                        } else {
                            that.min_price = 0
                            that.min_kilometer = 0
                            that.next_kilometer_price = 0
                            Notify({ type: 'danger', message: '基价获取失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                return `${date.getFullYear()}/${monthstr}/${date.getDate()}`;
            },
            onFromDateConfirm (dates) {
                window.console.log(dates)
                that.from_date = that.formatDate(dates)
                localStorage.setItem('from_date',that.from_date)
                that.fromdateshow = false
                that.checkdate()
            },
            onToDateConfirm (dates) {
                window.console.log(dates)
                that.to_date = that.formatDate(dates)
                localStorage.setItem('to_date',that.to_date)
                that.todateshow = false
                that.mintohour = 7
                that.checkdate()
            },
            showfromman () {
                that.$router.push({ path: 'SelectMan?way=1' })
            },
            showtoman () {
                that.$router.push({ path: 'SelectMan?way=2' })
            },
            showmanmana (index) {
                that.$router.push({ path: 'ManMana?way=' + index })
            },
            getdistancebyhx () {
                var that = this
                that.$http({
                    url: 'https://restapi.amap.com/v5/direction/driving?key=22f85a8aed7e77da085713450af984cf&origin=' + localStorage.getItem('fromgps') + '&destination=' + localStorage.getItem('togps'),
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log('两地距离：' + (res.data.route.paths[0].distance / 1000) + '公里')
                        that.jlinfo = res.data.route.paths[0].distance / 1000
                        window.console.log(that.hxmin_kilometer)
                        window.console.log(that.hxmin_price)
                        window.console.log(that.hxnext_kilometer_price)
                        if (localStorage.getItem('if_free') !== null && localStorage.getItem('if_free') !== undefined && localStorage.getItem('if_free') !== '') {
                            if (localStorage.getItem('if_free') === '1') {
                                // 免费的情况下
                                if (parseInt(that.jlinfo) <= parseInt(that.hxz_min_kilometre)) {
                                    // 在免费公里范围内
                                    if (parseInt(that.gcount) <= parseInt(that.allowpaccount)) {
                                        that.jscount = 0
                                    } else {
                                        Notify({ type: 'warning', message: '超出免费配送件数' })
                                    }
                                } else {
                                    Notify({ type: 'warning', message: '超出免费配送范围' })
                                }
                            } else {
                                // 非免费的情况
                                if (parseInt(that.jlinfo) <= parseInt(that.hxmin_kilometer)) {
                                    that.jscount = parseInt(that.hxmin_price) * 100
                                } else {
                                    var jltmp = parseInt(that.jlinfo - that.hxmin_kilometer)
                                    that.jscount = (parseInt(that.hxmin_price) + parseInt(jltmp * that.hxnext_kilometer_price)) * 100
                                }
                            }
                        } else {
                            // 非免费的情况
                            if (parseInt(that.jlinfo) <= parseInt(that.hxmin_kilometer)) {
                                that.jscount = parseInt(that.hxmin_price) * 100
                            } else {
                                var jltmp1 = parseInt(that.jlinfo - that.hxmin_kilometer)
                                that.jscount = (parseInt(that.hxmin_price) + parseInt(jltmp1 * that.hxnext_kilometer_price)) * 100
                            }
                        }
                        /*需要补足免运费前提下的特殊情况，由于描述不清晰，暂时不更新*/
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getdistance () {
                var that = this
                that.$http({
                    url: 'https://restapi.amap.com/v5/direction/driving?key=22f85a8aed7e77da085713450af984cf&origin=' + localStorage.getItem('fromgps') + '&destination=' + localStorage.getItem('togps'),
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log('两地距离：' + (res.data.route.paths[0].distance / 1000) + '公里')
                        that.jlinfo = res.data.route.paths[0].distance / 1000
                        window.console.log(that.min_kilometer)
                        window.console.log(that.min_price)
                        window.console.log(that.next_kilometer_price)
                        if (parseInt(that.jlinfo) <= parseInt(that.min_kilometer)) {
                            that.jscount = parseInt(that.min_price*that.gcount) * 100
                        } else {
                            var jltmp = parseInt(that.jlinfo - that.min_kilometer)
                            that.jscount = (parseInt(that.min_price*that.gcount) + parseInt(jltmp * that.next_kilometer_price)) * 100
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                // 全新的计算距离，计算费用
            },
            onFromConfirm (value) {
                window.console.log('FromConfirm Run')
                that.from_time = value
                localStorage.setItem('from_time',that.from_time)
                that.showfromPicker = false
                var datatmp = that.from_date + ' ' + that.from_time
                var datatmp1 = that.fly_date + ' ' + that.fly_time
                window.console.log(datatmp)
                var sjc = howLong(new Date(datatmp),new Date())
                var sjc1 = howLong(new Date(datatmp1),new Date(datatmp))
                window.console.log(sjc)
                window.console.log(sjc.hours > 2 || sjc.day > 0)
                window.console.log(sjc1)
                window.console.log(sjc1.hours > 6 || sjc1.day > 0)
                if (parseInt(this.ordertype) === 2) {
                    window.console.log('执行')
                    if ((sjc.hours >= 2 || sjc.day > 0) && (sjc1.hours > 6 || sjc1.day > 0)) {
                        that.disorder = false
                        that.ordertip = ''
                    } else {
                        that.disorder = true
                        that.from_date = ''
                        that.from_time = ''
                        Notify({ type: 'warning', message: '取件时间距离当前下单时间至少2小时，距离起飞时间至少6小时您的行李正常情况下将于起飞时间3小时内进行送达' })
                    }
                } else {
                    var tmpdate4 = new Date()
                    var sjc10 = howLong(new Date(datatmp),new Date(tmpdate4))
                    window.console.log('寄件时间距离当前时间',sjc10)
                    if (sjc10.hours < 2 && sjc10.day <= 0) {
                        that.disorder = true
                        that.from_date = ''
                        that.from_time  = ''
                        that.to_date = ''
                        that.to_time  = ''
                        Notify({ type: 'warning', message: '寄件时间距离当前时间最少2个小时' })
                        return
                    }
                }
                if (parseInt(this.ordertype) === 1) {
                    if (that.from_time === '' || that.to_time === '') {
                        that.disorder = true
                    } else {
                        that.disorder = false
                    }
                }
                if (parseInt(this.ordertype) === 2) {
                    if (that.fly_time === '' || that.from_time === '') {
                        that.disorder = true
                    } else {
                        that.disorder = false
                    }
                }
            },
            onToConfirm (value) {
                window.console.log('ToComfirm Run')
                that.to_time = value
                localStorage.setItem('to_time',that.to_time)
                that.showtoPicker = false
                var datatmp2 = that.from_date + ' ' + that.from_time
                var datatmp1 = that.to_date + ' ' + that.to_time
                if (parseInt(this.ordertype) === 1) {
                    var tmpdate4 = new Date()
                    var sjc1 = howLong(new Date(datatmp2),new Date(tmpdate4))
                    window.console.log('寄件时间距离当前时间',sjc1)
                    if (sjc1.hours < 2 && sjc1.day <= 0) {
                        that.disorder = true
                        that.from_date = ''
                        that.from_time  = ''
                        Notify({ type: 'warning', message: '寄件时间距离当前时间最少2个小时' })
                        return
                    } else {
                        that.ordertip = ''
                        var sjc = howLong(new Date(datatmp1),new Date(datatmp2))
                        window.console.log('送达时间距离取件时间',sjc)
                        if (sjc.hours < 4 && sjc.day <= 0) {
                            that.disorder = true
                            Notify({ type: 'warning', message: '送达时间距离取件时间最少4小时' })
                            that.to_date = ''
                            that.to_time  = ''
                            return
                        } else {
                            that.disorder = false
                            that.ordertip = ''
                        }
                    }
                }
            },
            uporderstate () {
                var updata = {
                    id: this.hyqid.toString(),
                    is_use: '1'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/upcouponstate',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            window.console.log('优惠券成功使用')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            onSubmit () {
                window.console.log('图片数据',that.xlimgs)
                if (that.ordertype === 1) {
                    if (that.from_man !== '' && that.to_man !== '' && that.from_time !== '' && that.to_time !== '' && that.xlimgs !== '') {
                        window.console.log('数据校验完成')
                    } else {
                        Notify({ type: 'danger', message: '数据不完整，请检查后再提交' });
                        return;
                    }
                }
                if (that.ordertype === 2) {
                    if (that.from_man !== '' && that.to_man !== '' && that.from_time !== '' && that.fly_time !== '' && that.xlimgs !== '') {
                        window.console.log('数据校验完成')
                    } else {
                        Notify({ type: 'danger', message: '数据不完整，请检查后再提交' });
                        return;
                    }
                }
                if (that.xlimgs !== '' || that.ifhx === '1') {
                    that.loadshow = true
                    that.disorder = true
                    let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                    var tmporderno = 'TY' + Utils.dateFormat(new Date(), 'yyyyMMddhhmmss')
                    var suborderno = Utils.dateFormat(new Date(), 'mmss')
                    if (this.if_hb === '1') {
                        tmporderno = this.hb_orderno + "Sub" + suborderno
                    }
                    var fromman = JSON.parse(localStorage.getItem('lxrfrominfo'))
                    if (fromman.mancontact !== undefined && fromman.mancontact !== null && fromman.mancontact !== '') {
                        localStorage.setItem('sendsmsphone',fromman.mancontact)
                    } else {
                        localStorage.setItem('sendsmsphone','')
                    }
                    var toman = JSON.parse(localStorage.getItem('lxrtoinfo'))
                    var fromaddress = localStorage.getItem('from_address')
                    var fromaddressobj = JSON.parse(localStorage.getItem('fromaddress'))
                    var toaddress = localStorage.getItem('to_address')
                    var toaddressobj = JSON.parse(localStorage.getItem('toaddress'))
                    var hxorder = ''
                    var userphone = ''
                    var proxyphone = ''
                    if (that.ordertype === 1) {
                        hxorder = ''
                        proxyphone = localStorage.getItem('userphone')
                    }
                    if (that.ifhx === '1') {
                        that.xlimgs = ''
                        if (localStorage.getItem('hxorder_no') !== undefined && localStorage.getItem('hxorder_no') !== null && localStorage.getItem('hxorder_no') !== '') {
                            hxorder = localStorage.getItem('hxorder_no')
                        }
                        if (localStorage.getItem('proxy_phone') !== undefined && localStorage.getItem('proxy_phone') !== null && localStorage.getItem('proxy_phone') !== '') {
                            proxyphone = localStorage.getItem('proxy_phone')
                            userphone = localStorage.getItem('proxy_phone')
                        } else {
                            userphone = localStorage.getItem('userphone')
                        }
                    } else {
                        userphone = localStorage.getItem('userphone')
                    }
                    if (parseInt(that.jlinfo) > 90 && that.ifhx === '1') {
                        Notify({ type: 'danger', message: '超过90公里无法配送' });
                        // that.disorder = true
                        return
                    } else {
                        window.console.log('运价模式小于90公里')
                    }
                    var zjje = that.jscount
                    if (localStorage.getItem('if_free') !== null && localStorage.getItem('if_free') !== undefined && localStorage.getItem('if_free') !== '') {
                        if (parseInt(localStorage.getItem('if_free')) === 1) {
                            zjje = 0
                        }
                    }
                    /*
                    if (fromaddressobj.streetNumber.location !== '' && fromaddressobj.streetNumber.location !== undefined) {
                        window.console.log('寄件地址正确')
                    } else {
                        Notify({ type: 'danger', message: '请检查寄件地址' });
                        return
                    }
                    if (toaddressobj.streetNumber.location !== '' && toaddressobj.streetNumber.location !== undefined) {
                        window.console.log('收货地址正确')
                    } else {
                        Notify({ type: 'danger', message: '请检查收货地址' });
                        return
                    }*/
                    if (parseInt(zjje) > 0 || parseInt(localStorage.getItem('if_free')) === 1) {
                        var adata = {
                            orderno: tmporderno,
                            ordertype: that.ordertype.toString(),
                            orderstate: '1',
                            fromaddressdes: localStorage.getItem('fromaddress'),
                            fromaddress: fromaddress.toString(),
                            frompos: localStorage.getItem('fromgps'),
                            toaddressdes: localStorage.getItem('toaddress'),
                            toaddress: toaddress,
                            topos: localStorage.getItem('togps'),
                            fromarea: fromaddressobj.township,
                            toarea: toaddressobj.township,
                            paccount: that.gcount.toString(),
                            pacimgs: '',
                            cuspacimgs: that.xlimgs,
                            fromman: fromman.manname,
                            fromcontact: fromman.mancontact,
                            toman: toman.manname,
                            tocontact: toman.mancontact,
                            fromdate: that.from_date,
                            fromtime: that.from_time.toString(),
                            totime: that.to_time.toString(),
                            todate: that.to_date,
                            flytime: that.fly_time.toString(),
                            flydate: that.fly_date,
                            orderprice: zjje.toString(),
                            priceex: '0',
                            wxorderno: '',
                            wxordernoex: '',
                            pacweight: '',
                            fromdistance: that.jlinfo.toString(),
                            flight_no: that.flight_no,
                            adddate: nowStr,
                            hxorder: hxorder,
                            userphone: userphone,
                            proxyphone: proxyphone,
                            orderdes: that.orderdes,
                            if_hb: that.if_hb,
                            if_free: localStorage.getItem('if_free'),
                            hx_mode: localStorage.getItem('mode'),
                            buildFlag: localStorage.getItem('buildFlag'),
                            parid: localStorage.getItem('parid'),
                            agentid: localStorage.getItem('agentid')
                        }
                        window.console.log(adata)
                        that.curorder = adata
                        that.$http({
                            url: 'https://api.yunshangyunkeji.com/addorder',
                            method: 'post',
                            data: adata,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(function (res) {
                                if (res.data.result === 'success') {
                                    if (parseInt(zjje) === 0) {
                                        that.loadshow = false
                                        window.console.log('金额为0，无需支付')
                                        // 支付金额为0，无需支付，航信免单
                                        that.uporderpaystate(tmporderno)
                                        if (parseInt(localStorage.getItem('mode')) === 0) {
                                            window.console.log('运价')
                                            that.returnhxyj(tmporderno,0,2)
                                        }
                                        if (parseInt(localStorage.getItem('mode')) === 1) {
                                            window.console.log('分销')
                                            that.returnhx(tmporderno,0,2)
                                        }
                                    } else {
                                        if (localStorage.getItem('mode') !== undefined && localStorage.getItem('mode') !== null && localStorage.getItem('mode') !== '') {
                                            if (parseInt(localStorage.getItem('if_free')) === 0) {
                                                window.console.log('航信订单，收费')
                                                if (parseInt(localStorage.getItem('mode')) === 0) {
                                                    window.console.log('运价模式')
                                                    that.returnhxyj(tmporderno, zjje.toString(), 1)
                                                }
                                                if (parseInt(localStorage.getItem('mode')) === 1) {
                                                    window.console.log('航信订单，收费')
                                                    if (parseInt(localStorage.getItem('buildFlag')) === 4) {
                                                        that.returnhx(tmporderno, zjje.toString(), 1)
                                                    }
                                                    if (parseInt(localStorage.getItem('buildFlag')) === 10) {
                                                        that.returnhxbyflag(tmporderno, zjje.toString(), 1)
                                                        // window.location.replace('https://bluesky.travelsky.com.cn/blueskydc/uat/bluesky194/obt/wxobt/#/pay?sono=' + localStorage.getItem('hxorder_no') + '&pac_order_no=' + tmporderno)
                                                    }

                                                }
                                            } else {
                                                window.console.log('航信订单，免费')
                                                that.loadshow = true
                                                that.disorder = false
                                                that.uporderpaystate(tmporderno)
                                            }
                                        } else {
                                             that.pay(tmporderno,zjje,localStorage.getItem('sendsmsphone'))
                                        }
                                    }
                                    // that.$router.replace({ path: 'OrderInfo?state=0' })
                                }
                            })
                            .catch(function (error) {
                                window.console.log(error)
                            })
                    } else {
                        Notify({ type: 'danger', message: '金额不能小于0' });
                    }

                } else {
                    Notify({ type: 'danger', message: '没有完成拍照不可以提交订单' });
                }
            },
            returnhx (tmporderno,price,state) {
                var updata = {
                    pacOrderNo: tmporderno,
                    buildFlag: localStorage.getItem('buildFlag'),
                    parid: localStorage.getItem('parid'),
                    agentid: localStorage.getItem('agentid'),
                    phone: localStorage.getItem('proxy_phone'),
                    status: state.toString(),
                    price: price.toString(),
                    opration: '1',
                    mode: localStorage.getItem('mode'),
                    oriSono: localStorage.getItem('hxorder_no'),
                    cagentid: localStorage.getItem('cagentid'),
                    travelType: localStorage.getItem('travelType')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxinputorder',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('航信反馈',res)
                        if (res.data.data.success) {
                            Notify({ type: 'success', message: '回传航信成功' });
                            if (parseInt(localStorage.getItem('if_free')) === 0) {
                                // 需要跳转到航信的支付页面
                                that.loadshow = false
                                that.disorder = false
                                window.console.log('需要跳转到航信的支付')
                                that.uphxorderno(tmporderno,res.data.data.sono)
                                window.JSBridge.call("openPayment",
                                    {
                                        order_no: res.data.data.sono
                                    },
                                    function() {
                                        // 回调函数
                                        that.loadshow = false
                                        that.$router.push({ path: 'OrderInfo?mode =' + localStorage.getItem('mode') + '&phone=' + localStorage.getItem('proxy_phone') + '&flight_no=' + localStorage.getItem('flight_no') + '&if_free=' + localStorage.getItem('if_free') + '&freight_price=' + localStorage.getItem('freight_price') + '&buildFlag=' + localStorage.getItem('buildFlag') + '&parid=' + localStorage.getItem('parid') + '&agentid=' + localStorage.getItem('agentid') + '&oriSono=' + res.data.data.sono + '&cagentid=' + localStorage.getItem('cagentid') + '&travelType=' + localStorage.getItem('travelType') })
                                    }
                                )
                            } else {
                                window.console.log('无需支付')
                                that.sendsmsmsg(localStorage.getItem('sendsmsphone'))
                            }
                        } else {
                            // that.$router.push({ path: 'PayError' })
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '回传航信失败' });
                    })
            },
            returnhxbyflag (tmporderno,price,state) {
                var updata = {
                    pacOrderNo: tmporderno,
                    buildFlag: localStorage.getItem('buildFlag'),
                    parid: localStorage.getItem('parid'),
                    agentid: localStorage.getItem('agentid'),
                    phone: localStorage.getItem('proxy_phone'),
                    status: state.toString(),
                    price: price.toString(),
                    opration: '1',
                    mode: localStorage.getItem('mode'),
                    oriSono: localStorage.getItem('hxorder_no'),
                    cagentid: localStorage.getItem('cagentid'),
                    travelType: localStorage.getItem('travelType')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxinputorder',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('航信反馈',res)
                        if (res.data.data.success) {
                            Notify({ type: 'success', message: '回传航信成功' });
                            if (parseInt(localStorage.getItem('if_free')) === 0) {
                                // 需要跳转到航信的支付页面
                                that.loadshow = false
                                that.disorder = false
                                window.console.log('需要跳转到航信的支付')
                                that.uphxorderno(tmporderno,res.data.data.sono)
                                window.location.replace('https://bluesky.travelsky.com.cn/blueskydc/uat/bluesky194/obt/wxobt/#/pay?sono=' + res.data.data.sono + '&pac_order_no=' + tmporderno)
                            } else {
                                window.console.log('无需支付')
                                that.sendsmsmsg(localStorage.getItem('sendsmsphone'))
                            }
                        } else {
                            // that.$router.push({ path: 'PayError' })
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '回传航信失败' });
                    })
            },
            sendsmsmsg (userphone) {
                var datatmp = {
                    phone: userphone,
                    signname: '航空行李管家',
                    tempcode: 'SMS_462245402',
                    is_send: '1'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/getvercode',
                    method: 'post',
                    data: datatmp,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result==='success') {
                            window.console.log('发送完毕')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            returnhxyj (tmporderno,price,state) {
                var fromman = JSON.parse(localStorage.getItem('lxrfrominfo'))
                var updata = {
                    pacOrderNo: tmporderno,
                    buildFlag: localStorage.getItem('buildFlag'),
                    parid: localStorage.getItem('parid'),
                    agentid: localStorage.getItem('agentid'),
                    phone: fromman.mancontact,
                    status: state.toString(),
                    price: price.toString(),
                    opration: '1',
                    mode: localStorage.getItem('mode'),
                    oriSono: localStorage.getItem('hxorder_no'),
                    cagentid: localStorage.getItem('cagentid'),
                    travelType: localStorage.getItem('travelType')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxyjorder',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('航信反馈',res)
                        if (res.data.data.success) {
                            Notify({ type: 'success', message: '回传航信成功' });
                            that.sendsmsmsg(fromman.mancontact)
                            // that.uphxorderno(tmporderno,res.data.data.sono)
                        } else {
                            // that.$router.push({ path: 'PayError' })
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '回传航信失败' });
                    })
            },
            uphxorderno (orderno,hxorderno) {
                var updata = {
                    orderno: orderno,
                    hxorder: hxorderno
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/uphxorderno',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '下单完毕' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            pay (tmporderno,jscount,userphone) {
                var qdata = {
                    description: '托运订单',
                    amount: jscount,
                    openid: localStorage.getItem('openid'),
                    orderno: tmporderno,
                }
                that.$http({
                    url: 'https://wx.yunshangyunkeji.com/wx/paysign',
                    method: 'post',
                    data: qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.code === 0) {
                            var paytmpdata = res.data.result
                            window.console.log(paytmpdata)
                            window.console.log(paytmpdata.appId)
                            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                                    "appId": paytmpdata.appId,
                                    "timeStamp": paytmpdata.timeStamp,
                                    "nonceStr": paytmpdata.nonceStr,
                                    "package": paytmpdata.package,
                                    "signType": "RSA",// 微信签名方式：
                                    "paySign":paytmpdata.paySign // 微信签名
                                },
                                (res) => {
                                    window.console.log(res)
                                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                                        window.console.log(res)
                                        that.sendmsg()
                                        that.sendsmsmsg(userphone)
                                        // 更新优惠券
                                        if (that.yhje > 0) {
                                            that.uporderstate()
                                        }
                                        that.uporderpaystate(tmporderno)
                                        // 自动分单
                                        that.runautofd()
                                        that.$router.push({ path: 'PaySuccess' })

                                    } else if (res.err_msg == "get_brand_wcpay_request:cancel"){
                                        that.$router.push({ path: 'PayError' })
                                    }
                                });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', that.onTestPay, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', that.onTestPay);
                        document.attachEvent('onWeixinJSBridgeReady', that.onTestPay);
                    }
                } else {
                    window.console.log('支持微信支付')
                }
            },
            uporderpaystate (orderno) {
                var updata = {
                    orderno: orderno,
                    state: '2'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/uporderstate',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.$router.replace({ path: 'OrderInfo?state=2' })
                        if (res.data.result === 'success') {
                            // that.$router.replace({ path: 'OrderInfo?state=1' })
                        } else {
                            // that.$router.push({ path: 'PayError' })
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            /*  执行自动分单寻找司机下单 */
            runautofd () {
                this.getareadrvers(that.curorder)
            },
            getareadrvers (item) {
                var that = this
                var areainfo = ''
                if (item.ordertype === 1) {
                    areainfo = item.fromarea
                } else {
                    areainfo = item.toarea
                }
                var qstr = {
                    whereinfo: " street = '" + areainfo + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryareadriver',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log(res.data)
                        if (res.data.length > 0 && res.data !== '') {
                            var tmpdriverids = '('
                            for (var i = 0; i < res.data.length; i++) {
                                if (i > 0) {
                                    tmpdriverids = tmpdriverids + ",'" + res.data[i].areadriverid + "'"
                                } else {
                                    tmpdriverids = tmpdriverids + "'" + res.data[i].areadriverid + "'"
                                }
                            }
                            tmpdriverids = tmpdriverids + ')'
                            that.indrivers = tmpdriverids
                            that.getdriverorderandpos(item)
                        } else {
                            window.console.log('分单失败，则有调度手动来分')
                            /* 是否需要记录分单失败的订单 */
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getdriverorderandpos (item) {
                var qstr = {
                    whereinfo: ' userid in ' + this.indrivers + ' group by userid'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querydriverordercount',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            window.console.log('起始坐标',item.frompos)
                            var pos1 = item.frompos.split(',')
                            var tmpdatas1 = []
                            var tmpdatas2 = []
                            for (var i = 0; i < res.data.length; i++) {
                                var tmpdata = res.data[i]
                                window.console.log('目的地坐标',tmpdata.fin_location)
                                var pos2 = tmpdata.fin_location.split(',')
                                tmpdata.distance = (that.GetDistance(pos1[0],pos1[1],pos2[0],pos2[1]) / 1000).toFixed(2);
                                if (tmpdata.ordercount === 0) {
                                    tmpdatas1.push(tmpdata)
                                } else {
                                    tmpdatas2.push(tmpdata)
                                }
                            }
                            that.noorderdriver = tmpdatas1
                            that.haveorderdriver = tmpdatas2
                            that.noorderdriver.sort((a, b) => parseInt(a.distance) - parseInt(b.distance))
                            that.haveorderdriver.sort((a, b) => parseInt(a.distance) - parseInt(b.distance))
                            if (that.noorderdriver.length > 0) {
                                window.console.log('如果有未分配的选择最前面的一个')
                                that.curdriver = that.noorderdriver[0]
                            } else {
                                window.console.log('如果都有订单的情况，选择距离最近的一个')
                                that.curdriver = that.haveorderdriver[0]
                            }
                            that.addpsorder()
                        } else {
                            window.console.log('如果分单失败，则有调度手动来分')
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            /* 增加派送单 */
            addpsorder () {
                let dateTime = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                var psordertmp = 'PS' + Utils.dateFormat(new Date(), 'yyyyMMddhhmmss') + that.curdriver.userid
                var adata = {
                    psorderno: psordertmp,
                    orderno: that.curorder.orderno,
                    driver_id: that.curdriver.userid.toString(),
                    truck_id: '0',
                    psstate: '0',
                    psdate: dateTime
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/addpsorderinfo',
                    method: 'post',
                    data: adata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            that.uporderzpstate()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            /* 更改订单状态为5 */
            uporderzpstate () {
                let dateTime = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                var updata = {
                    orderno: that.curorder.orderno,
                    if_zp: '1',
                    zpdate: dateTime,
                    orderstate: '5'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/uporderzp',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            that.sendmsgtodriver()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            /* 给司机推送消息 */
            sendmsgtodriver () {
                var senddata = {
                    accesstoken: that.accesstoken,
                    touser: that.curdriver.openid,
                    template_id: 'WYwEC1nw4YGMZvVZOWHn-I7SscU96D6mOpf_xAyozxk',
                    page: 'pages/ordermana/index',
                    data: {
                        phrase1: {value: '等待托送'},
                        thing2: {value: '托运订单'},
                        character_string3: {value: that.curorder.orderno},
                        thing4: {value: '您有新订单'}
                    }
                }
                that.$http({
                    url: 'https://wx.yunshangyunkeji.com/wx/sendmintmpmsg',
                    method: 'post',
                    data:senddata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            window.console.log('自动分单成功')
                        }
                        window.console.log(res)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            createOssClient () {
                return new Promise((resolve, reject) => {
                    let client = new OSS({
                        region: 'oss-cn-beijing',
                        accessKeyId: 'LTAI5tJM64qXREWmyVUptvME',
                        accessKeySecret: 'dMW60JhA2JStLVSUD5sA5GBhueFkDE',
                        bucket: 'tuoyun'
                    })
                    resolve(client)
                    window.console.log(reject)
                }).catch( err =>  {
                    window.console.log(err)
                })
            },
            GetDistance( lat1,  lng1,  lat2,  lng2){
                var radLat1 = lat1*Math.PI / 180.0;
                var radLat2 = lat2*Math.PI / 180.0;
                var a = radLat1 - radLat2;
                var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
                var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
                    Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
                s = s *6378.137 ;// EARTH_RADIUS;
                s = Math.round(s * 10000) / 10000;
                return s.toFixed(1);
            },
            async fnUploadRequest (option) {
                var that = this
                if (option.file) {
                    window.console.log('单文件')
                    let file = option.file
                    const self = this
                    that.fileList[0].status = 'uploading'
                    that.fileList[0].message = '上传中'
                    this.percentageType = true;
                    return new Promise((resolve, reject) => {
                        let nowStr = Utils.dateFormat(new Date(), 'yyyyMMddhhmmss')
                        let fileName = that.selectpath + '/' + nowStr + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        window.console.log(fileName)
                        self.createOssClient().then(client => {
                            // 异步上传,返回数据
                            resolve({
                                fileName: file.name,
                                fileUrl: fileName
                            })
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    window.console.log(e.percent)
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    window.console.log(that.upfileList)
                                    that.fileurls.push('https://tuoyun.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.xlimgs = that.fileurls.join(',').toString()
                                    that.fileList[0].status = 'done'
                                    that.fileList[0].message = '上传完成'
                                    localStorage.setItem('fileList',JSON.stringify(that.fileList))
                                    return val
                                } else {
                                    that.fileList[0].status = 'failed'
                                    that.fileList[0].message = '上传失败'
                                }
                            }, err => {
                                that.fileList[0].status = 'failed'
                                that.fileList[0].message = '上传失败'
                                window.console.log(err)
                                window.console.log(reject)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    })
                } else {
                    window.console.log('多文件')
                    for (var i = 0; i < option.length; i++ ) {
                        window.console.log(option)
                        let file = option[i].file
                        window.console.log(that.fileList.length - option.length)
                        that.fileList[i].status = 'uploading'
                        that.fileList[i].message = '上传中'
                        const self = this
                        this.percentageType = true;
                        file.index = i
                        let nowStr = Utils.dateFormat(new Date(), 'yyyyMMddhhmmss')
                        let fileName = that.selectpath + '/' + nowStr + i + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        self.createOssClient().then(client => {
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    if (e.percent === 100) {
                                        that.fileList[file.index].status = 'success'
                                        that.fileList[file.index].message = '上传完毕'
                                        localStorage.setItem('fileList',that.fileList.join(','))
                                    }
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    that.fileurls.push('https://tuoyun.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.xlimgs = that.fileurls.join(',').toString()
                                    window.console.log(that.upfileList)
                                    return val
                                } else {
                                    window.console.log('上传失败')
                                }
                            }, err => {
                                window.console.log(err)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    }
                }

            }
        }
    }
    function howLong(time1, time2){
        time1 = time1.getTime();
        time2 = time2.getTime();
        var cha     = time1 - time2;
        var day     = Math.floor(cha / (24 * 3600 * 1000));
        var hours   = Math.floor(cha % (24 * 3600 * 1000) / (3600 * 1000));
        var minutes = Math.floor(cha % (24 * 3600 * 1000) % (3600 * 1000) / (60 * 1000));
        var seconds = Math.floor(cha % (24 * 3600 * 1000) % (3600 * 1000) % (60 * 1000) / 1000);
        return {
            day: day,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    }
    async function getaccesstoken () {
        return new Promise(resolve => {
            that.$http({
                url: 'https://wx.yunshangyunkeji.com/wx/getminproaccesstoken',
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    window.console.log(res)
                    if (res.status === 200) {
                        window.console.log(res.data)
                        resolve(res.data)
                    } else {
                        window.console.log('返回错误')
                        resolve('')
                    }
                })
                .catch(function (error) {
                    window.console.log(error)
                    resolve('')
                })
        })
    }
    async function getwxaccesstoken () {
        return new Promise(resolve => {
            that.$http({
                url: 'https://wx.yunshangyunkeji.com/wx/getaccesstoken',
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    window.console.log(res)
                    if (res.status === 200) {
                        window.console.log(res.data)
                        resolve(res.data)
                    } else {
                        window.console.log('返回错误')
                        resolve('')
                    }
                })
                .catch(function (error) {
                    window.console.log(error)
                    resolve('')
                })
        })
    }
    const JsBridge = function () {};
    const clearWindowsEnv = (callback) => {
        setTimeout(() => {
            delete(window)[callback];
        }, 10);
    };
    const sendNative = function (name, params, callback) {
        if(typeof params === 'function') {
            callback = params
            params = {}
        }
        const paramsToJson = JSON.stringify({
            name,
            params
        })

        if (window.ENV === 'IOS') {
            const pre = "IOS_CALLBACK_"
            window[pre + name] = (data) => {
                callback(data ? data : null)
                clearWindowsEnv(pre + name)
            }
            window.webkit.messageHandlers.IOS.postMessage(
                paramsToJson
            )
        }
        if (window.ENV === 'Android') {
            const data =  window.JsBridgeAndroid.postMessageAndroid(
                paramsToJson
            )
            callback(JSON.parse(data));
        }
    }
    JsBridge.prototype.call = function (name, params, callback) {
        sendNative(name, params, callback)
    }

    window.JSBridge = new JsBridge();
</script>

<style>
    .van-submit-bar__bar {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        height: 80px;
        padding: 0 16px;
        font-size: 14px;
    }
    .allorderbg {
        background-color: #f1f1f1;
        background-size: 100% 100%;
        padding-top: 10px;
        padding-bottom: 90px;
        width:100%;
    }
    .mysubmit {
        height: 200px;
    }
    .lefttxt {
        font-size: 14px;
    }
</style>
