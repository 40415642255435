<template>
    <div style="background-color:#fff;border: 1px solid #f3f3f3;margin: 6px; padding-bottom: 8px;padding: 6px; margin: 6px 6px 16px 6px; border-radius:10px;" :style="'min-height:' + colheight + 'px;'">
        <div style="display:flex; padding: 10px 15px 10px 15px; flex-direction: row; justify-content: flex-start; align-items: center; font-size: 28px;">
            <van-icon style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/logo.png" />
            <div style="color: #000; font-size: 16px; margin-left: 5px;">航空行李管家</div>
        </div>
        <div style="display: flex; flex-direction: row;">
            <div style="display:flex; flex:1; padding-left: 15px;padding-right: 15px; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 13px;">
                <div>
                    订单号：{{curitem.orderno}}
                </div>
                <div style="margin-top: 5px; margin-left: -2px;">
                    下单时间：{{curitem.adddate}}
                </div>
                <div style="margin: 5px 0 0px 0px; display: flex; flex-direction: row;" v-if="curitem.pacnos !== null && curitem.pacnos !== null">
                    <div>
                        行李代码：
                    </div>
                    <div style="font-size: 12px;">
                        <div style="display: flex; flex-direction: column;">
                            <div style="margin: 2px 0 0 0px" v-for="(subitem,index) in curitem.pacnoarr" :key="index">
                                {{subitem}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-if="curitem.orderstate >= 2 && curitem.orderstate !== 7 && curitem.orderstate !== 9 && curitem.orderstate !== 10">
                已支付
            </div>
            <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-else-if="curitem.orderstate === 9">
                已取消
            </div>
            <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-else-if="curitem.orderstate === 10">
                已退订
            </div>
            <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-else-if="curitem.orderstate === 7">
                已退款
            </div>
            <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center;color: #F8BB4A" v-else>
                未支付
            </div>
        </div>
        <div style="padding: 5px 10px 5px 0px; margin-top: 14px; display: flex; flex-direction: column; border-top: 1px solid #f1f1f1; border-bottom: 1px solid #f1f1f1; margin-bottom: 10px;">
            <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                <div class="addressicon">
                    <div style="background-color: darkgray;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">寄</div>
                </div>
                <div class="addressicon" style="width:100%;display:flex; flex-direction: column;align-items: flex-start;">
                    <div style="font-size: 14px; font-weight: 500;">{{curitem.fromman}}: {{curitem.fromcontact}}</div>
                    <div style="margin-top:5px;font-size: 12px; color: rgb(177, 175, 175); text-align: left; width: 220px;">{{curitem.fromaddress}}</div>
                </div>
            </div>
            <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                <div class="addressicon">
                    <div style="background-color: darkblue;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">收</div>
                </div>
                <div class="addressicon" style="width:100%;flex-direction: column;align-items: flex-start;">
                    <div style="font-size: 14px; font-weight: 500;">{{curitem.toman}}: {{curitem.tocontact}}</div>
                    <div style="margin-top:5px; font-size: 12px; color: rgb(177, 175, 175); text-align: left;">{{curitem.toaddress}}</div>
                </div>
            </div>
        </div>
        <div style="margin-bottom:10px; padding-bottom: 10px; display:flex; padding-left: 20px;padding-right: 15px;  border-bottom: 1px solid #f3f3f3; justify-content: space-between; align-items: center; font-size: 14px;">
            <div>
                包裹数：{{curitem.paccount}}
            </div>
            <div>
                价格：{{curitem.orderprice/100}}
            </div>
        </div>
        <div v-if="curitem.orderdes !== null && curitem.orderdes !== ''" style="padding-bottom: 10px;margin-bottom:10px;display:flex; padding-left: 20px; border-bottom: 1px solid #f3f3f3; align-items: center; font-size: 14px;">
            <div>备注：</div>
            <div>{{curitem.orderdes}}</div>
        </div>
        <div v-if="curitem.cuspacimgs !== null && curitem.cuspacimgs !== ''">
            <div style="font-size: 14px;margin-left: 20px;">行李照片:</div>
            <div style="display: flex; flex-direction: row; margin: 10px;">
                <viewer>
                    <img style="margin-left:10px;width:64px;height:64px;" :src="subitem" v-for="(subitem,index) in curitem.cuspacimgs.split(',')" :key="index"/>
                </viewer>
            </div>
        </div>
        <div v-if="curitem.orderstate === 6" style="padding: 10px 10px 10px 10px; border-top: 1px solid #f1f1f1; display: flex; justify-content: center;">
            <van-button color="#808069" style="width: 100px;" size="small" round @click="pjorder(item)">立即评价</van-button>
        </div>
        <div v-if="curitem.orderstate === 8" style="padding: 10px 10px 10px 10px; border-top: 1px solid #f1f1f1; display: flex; justify-content: center;">
            已评价
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios'
    import { Notify } from 'vant';
    Vue.prototype.$http = axios
    Vue.use(Notify);
    var that
    export default {
        name: "OrderDes",
        data () {
            return {
                curitem: '',
                colheight: ''
            }
        },
        mounted() {
            that = this
            that.colheight =  window.innerHeight
            if (this.$route.query.pac_order_no !== undefined && this.$route.query.pac_order_no !== null && this.$route.query.pac_order_no !== '') {
                // 更新订单状态，并调转state 2
                localStorage.setItem('curorderno',this.$route.query.pac_order_no)
            }
            that.getorder()
        },
        methods: {
            getorder () {
                var winfo = " orderno = '" + localStorage.getItem('curorderno') + "'"
                var qstr = {
                    whereinfo: winfo,
                    limit: '',
                    sortinfo: ''
                }
                window.console.log(qstr)

                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryorder',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            var tmpdata = res.data[0]
                            if (res.data[0].cuspacimgs !== null) {
                                tmpdata.cuspacarr = res.data[0].cuspacimgs.split(',')
                            } else {
                                tmpdata.cuspacarr = []
                            }
                            if (res.data[0].pacimgs !== null) {
                                tmpdata.pacarr = res.data[0].pacimgs.split(',')
                            } else {
                                tmpdata.pacarr = []
                            }
                            if (res.data[0].zcimgs !== null) {
                                tmpdata.zcarr = res.data[0].zcimgs.split(',')
                            } else {
                                tmpdata.zcarr = []
                            }
                            if (res.data[0].jjimgs !== null) {
                                tmpdata.jjarr = res.data[0].jjimgs.split(',')
                            } else {
                                tmpdata.jjarr = []
                            }
                            if (res.data[0].pacnos !== null) {
                                tmpdata.pacnoarr = res.data[0].pacnos.split(',')
                            } else {
                                tmpdata.pacnoarr = []
                            }
                            that.curitem = tmpdata
                        } else {
                            that.curitem = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }

    }
</script>

<style scoped>

</style>