<template>
  <div id="app" data-title="我的">
    <router-view />
    <van-tabbar v-if="$route.meta.footshow & !ifhxmode" route>
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <img
                slot="icon"
                slot-scope="props"
                :src="props.active ? icon.mainsel : icon.main"
        >
      </van-tabbar-item>
      <van-tabbar-item replace to="/OrderInfo" >
        <span>订单</span>
        <img
                slot="icon"
                slot-scope="props"
                :src="props.active ? icon.glwsel : icon.glw"
        >
      </van-tabbar-item>
      <van-tabbar-item replace to="/My">
        <span>我的</span>
        <img
                slot="icon"
                slot-scope="props"
                :src="props.active ? icon.mysel : icon.my"
        >
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
    import Vue from 'vue';
    import { Tabbar, TabbarItem } from 'vant';
    Vue.use(Tabbar).use(TabbarItem);
    export default {
        name: 'app',
        data() {
            return {
                ifhxmode: false,
                icon: {
                    main: 'https://sqpro.oss-cn-beijing.aliyuncs.com/tea/%E9%A6%96%E9%A1%B5.png',
                    mainsel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/tea/%E9%A6%96%E9%A1%B5%20%281%29.png',
                    glw: 'https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E8%B4%AD%E7%89%A9%E8%BD%A6.png',
                    glwsel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E8%B4%AD%E7%89%A9%E8%BD%A6%20%281%29.png',
                    fl: 'https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E5%88%86%E7%B1%BB.png',
                    flsel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E5%88%86%E7%B1%BB%20%281%29.png',
                    zx: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/%E8%B5%84%E8%AE%AF.png',
                    zxsel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/s_%E8%B5%84%E8%AE%AF.png',
                    gl: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/%E8%AF%BE%E7%A8%8B.png',
                    glsel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/s_%E8%AF%BE%E7%A8%8B.png',
                    my: 'https://sqpro.oss-cn-beijing.aliyuncs.com/tea/%E6%88%91%E7%9A%84.png',
                    mysel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/tea/%E6%88%91%E7%9A%84%20%281%29.png'
                }
            }
        },
      mounted() {
          if(localStorage.getItem('mode') !== undefined && localStorage.getItem('mode') !== null && localStorage.getItem('mode') !== '') {
            this.ifhxmode = true
          } else {
            this.ifhxmode = false
          }
          window.console.log(this.ifhxmode)
      }
    }
</script>

<style>
  #app {
    font-family: 'PingFang-SC-Medium';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
</style>
