<template>
    <div>
        <div style="height:260px; background-color: #fff; margin-left: 20px;margin-right: 20px;  margin-top: 10px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <div style="height: 55px; display: flex; justify-content: space-between; align-items: center;">
                <div style="font-size: 16px;  height: 40px;width:100px;display: flex;align-items: center;">
                    <van-icon style="margin-left:20px; margin-right: 10px; font-size: 20px;" name="https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E5%AF%84%E4%BB%B6%E5%8A%9F%E8%83%BD.png" />
                    {{curtitle}}
                </div>
            </div>
            <div style="margin-top: 15px; display: flex; justify-content: center;">
                <van-field style="background-color: #F9F9F9;height: 50px;line-height: 30px; width:95%;" v-model="real_name"  label="真实姓名" placeholder="请输入真实姓名" >
                    <template #left-icon>
                        <van-icon size="16" style="margin-top: 7px;"  name="https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E4%BA%BA%E5%91%98.png" />
                    </template>
                </van-field>
            </div>
            <div style="margin-top: 15px; display: flex; justify-content: center;">
                <van-field type="tel" @blur="checkphone" style="background-color: #F9F9F9;height: 50px;line-height: 30px; width:95%;" v-model="user_phone" label="手机号码" placeholder="请输入手机号码" >
                    <template #left-icon>
                        <van-icon size="16" style="margin-top: 7px;" name="https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E6%89%8B%E6%9C%BA.png" />
                    </template>
                </van-field>
            </div>
            <div style="margin-top: 15px; display: flex; justify-content: center;">
                <van-field @blur="checksfz" style="background-color: #F9F9F9;height: 50px;line-height: 30px; width:95%;" v-model="user_sfz" label="身份证号" placeholder="请输入身份证信息" >
                    <template #left-icon>
                        <van-icon size="16" style="margin-top: 7px;" name="https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E8%BA%AB%E4%BB%BD%E8%AF%81.png" />
                    </template>
                </van-field>
            </div>
        </div>
        <div style="margin-top:20px; display: flex; justify-content: center;">
            <van-button style="width:300px;" color="#3c71d9" type="primary" round @click="addman" >确认</van-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios'
    import { Notify } from 'vant';
    import Utils from '../../util/utils'
    Vue.prototype.$http = axios
    Vue.use(Notify);
    var that
    export default {
        name: "SelectMan",
        data () {
            return {
                curtitle: '寄件人',
                way: '',
                real_name: '',
                user_phone: '',
                user_sfz: '',
                editmod: false,
                editdata: '',
                curid: '',
                if_phone: false,
                if_sfz: false
            }
        },
        mounted () {
            that = this
            localStorage.setItem('user_id',1)
            if (this.$route.query.way !== undefined) {
                that.way = this.$route.query.way
                if (parseInt(that.way) === 1) {
                    that.curtitle = '寄件人'
                    that.editdata = JSON.parse(localStorage.getItem('lxrfrominfo'))
                } else {
                    that.curtitle = '收件人'
                    that.editdata = JSON.parse(localStorage.getItem('lxrtoinfo'))
                }
            }
            if (this.$route.query.edit !== undefined) {
                that.editmod = true
                // 赋值
                that.real_name = that.editdata.manname
                that.user_phone = that.editdata.mancontact
                that.user_sfz = that.editdata.mansfz
                that.curid = that.editdata.id
            } else {
                that.editmod = false
            }
        },
        methods: {
            checksfz () {
                var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if(!reg.test(that.user_sfz))
                {
                    Notify({ type: 'primary', message: '身份证输入不合法，请重新输入' });
                    this.if_sfz = false
                    return;
                } else {
                    this.if_sfz = true
                }
            },
            checkphone () {
                let regs = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                if (!regs.test(that.user_phone)) {
                    Notify({ type: 'primary', message: '手机号码格式不正确，请重新输入' });
                    this.if_phone = false
                    return;
                } else {
                    this.if_phone = true
                }
            },
            addman () {
                if (!this.if_phone) {
                    Notify({ type: 'primary', message: '手机号码格式不正确，请重新输入' });
                    return
                }
                if (!this.if_sfz) {
                    Notify({ type: 'primary', message: '身份证输入不合法，请重新输入' });
                    return
                }
                if (that.real_name !== '' && that.user_phone !== '' && that.user_sfz !== '') {
                    let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                    var adata = ''
                    if (that.editmod) {
                        adata = {
                            id: that.curid.toString(),
                            manname: that.real_name,
                            mancontact: that.user_phone,
                            mantype: that.way.toString(),
                            mansfz: that.user_sfz,
                            ownuser: localStorage.getItem('userphone').toString(),
                            if_default: '0',
                            adddate: nowStr
                        }
                    } else {
                        adata = {
                            id: '',
                            manname: that.real_name,
                            mancontact: that.user_phone,
                            mantype: that.way.toString(),
                            mansfz: that.user_sfz,
                            ownuser: localStorage.getItem('userphone').toString(),
                            if_default: '0',
                            adddate: nowStr
                        }
                    }
                    that.$http({
                        url: 'https://api.yunshangyunkeji.com/addlxr',
                        method: 'post',
                        data: adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                localStorage.setItem('jsrinfo',JSON.stringify(adata))
                                that.$router.go(-1)
                                Notify({ type: 'success', message: '保存成功' });
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'primary', message: '信息不完整' });
                }
            }
        }
    }
</script>

<style scoped>

</style>
