<template>
    <div>
        <van-search show-action v-model="keyword" @search="onSearch" placeholder="请输入相关的位置">
            <template #action>
                <div @click="onSearch">搜索</div>
            </template>
        </van-search>
        <div style="margin:20px;" v-if="if_default">
            <van-tag style="width: 100%; height: 30px; margin-top: 5px;" @click="selpos(item)" v-for="(item,index) in taglist" :key="index" type="primary">
                <div style="width: 100%;">{{item.format_address}}</div>
            </van-tag>
        </div>
        <div>
            <van-list
                    :loading="loading"
                    :error="error"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
            >
                <van-cell v-for="(item,index) in poslist" @click="selpos(item)" :key="index" :title="item.name" :value="item.pname + item.adname" :label="item.address"/>
            </van-list>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Search } from 'vant';
    import { Cell, CellGroup } from 'vant';
    import { List } from 'vant';
    import { Tag } from 'vant';
    Vue.use(Search);
    Vue.use(Tag);
    Vue.use(Cell);
    Vue.use(CellGroup);
    Vue.use(List);
    var that
    export default {
        name: "QueryPos",
        data () {
            return {
                keyword: '',
                loading: false,
                if_default: true,
                error:false,
                poslist: [],
                from_address: '',
                to_address:'',
                taglist: [],
                fyway: '',
                qway: ''
            }
        },
        mounted () {
            that = this
            if (this.$route.query.fyway !== undefined && this.$route.query.fyway !== null && this.$route.query.fyway !== '') {
                that.fyway = this.$route.query.fyway
            }
            if (this.$route.query.qway !== undefined && this.$route.query.qway !== null && this.$route.query.qway !== '') {
                that.qway = this.$route.query.qway
                that.gettags(parseInt(that.qway) + 1)
            }
        },
        methods: {
            gettags (adtype) {
                var citytmp = JSON.parse(localStorage.getItem('selectcity'))
                var qstr = {
                    whereinfo: ' adtype = ' + adtype + " and province like '%" + citytmp.city + "%'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryaddressinfo',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.taglist = res.data
                        } else {
                            that.taglist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            onSearch () {
                that.if_default = false
                var tmpkey = JSON.parse(localStorage.getItem('selectcity')).city + that.keyword
                that.$http({
                    url: 'https://restapi.amap.com/v5/place/text?key=22f85a8aed7e77da085713450af984cf&keywords=' + tmpkey + '&types=',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.poslist = res.data.pois
                        window.console.log(that.poslist)
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            selpos (item) {
                window.console.log('选择位置',item)
                if (parseInt(that.fyway) === 1) {
                    if (parseInt(that.qway) === 0) {
                        if (item.pname !== undefined) {
                            that.from_address =  item.pname + item.adname + item.name
                        } else {
                            that.from_address =  item.format_address
                        }
                        localStorage.setItem('from_address',that.from_address)
                    }
                    if (parseInt(that.qway) === 1) {
                        if (item.pname !== undefined) {
                            that.to_address =  item.pname + item.adname + item.name
                        } else {
                            that.to_address = item.format_address
                        }
                        localStorage.setItem('to_address',that.to_address)
                    }
                }
                if (parseInt(that.fyway) === 2) {
                    if (parseInt(that.qway) === 0) {
                        if (item.pname !== undefined) {
                            that.from_address =  item.pname + item.adname + item.name
                        } else {
                            that.from_address =  item.format_address
                        }
                        localStorage.setItem('from_address',that.from_address)
                    }
                    if (parseInt(that.qway) === 1) {
                        if (item.pname !== undefined) {
                            that.to_address =  item.pname + item.adname + item.name
                        } else {
                            that.to_address =  item.format_address
                        }
                        localStorage.setItem('to_address',that.to_address)
                    }
                }
                if (parseInt(that.fyway) === 3) {
                    if (parseInt(that.qway) === 0) {
                        if (item.pname !== undefined) {
                            that.from_address =  item.pname + item.adname + item.name
                        } else {
                            that.from_address =  item.format_address
                        }
                        localStorage.setItem('faddress',that.from_address)
                    }
                    if (parseInt(that.qway) === 1) {
                        if (item.pname !== undefined) {
                            that.to_address = item.pname + item.adname + item.name
                        } else {
                            that.to_address = item.format_address
                        }
                        localStorage.setItem('taddress',that.to_address)
                    }
                }
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped>

</style>
