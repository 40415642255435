<template>
    <div>
        <van-cell-group>
            <van-swipe-cell v-for="(item,index) in manlist" :key="index">
                <van-cell @click="selman(item)" :title="'姓名：' + item.manname" :value="'手机号：' + item.mancontact" />
                <template #right>
                    <van-button square type="primary" @click="editman(item)" text="编辑" />
                    <van-button square type="danger" @click="delman(item)" text="删除" />
                </template>
            </van-swipe-cell>
        </van-cell-group>
        <div style="position: absolute; left:50%; margin-left: -150px; text-algin:center; bottom: 20px;">
            <van-button style="width:300px;" color="#3c71d9" type="primary" round @click="adduser" >添加人员</van-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios'
    import { Notify } from 'vant';
    import { SwipeCell } from 'vant';
    Vue.prototype.$http = axios
    Vue.use(SwipeCell);
    Vue.use(Notify);
    var that
    export default {
        name: "ManMana",
        data () {
            return {
                manlist: [],
                way: ''
            }
        },
        mounted() {
            that = this
            if (this.$route.query.way !== undefined) {
                that.way = this.$route.query.way
            }
            that.getmanlist()
        },
        methods: {
            editman (item) {
                if (parseInt(that.way) === 1) {
                    localStorage.setItem('lxrfrominfo',JSON.stringify(item))
                } else if (parseInt(that.way) === 2) {
                    localStorage.setItem('lxrtoinfo',JSON.stringify(item))
                }
                that.$router.push({ path: 'SelectMan?way=' + that.way + '&edit=1'})
            },
            delman (item) {
                var delrec = {
                    id: item.id.toString()
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/dellxr',
                    method: 'post',
                    data: delrec,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            that.getmanlist()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            selman (item) {
                if (parseInt(that.way) === 1) {
                    localStorage.setItem('lxrfrominfo',JSON.stringify(item))
                } else if (parseInt(that.way) === 2) {
                    localStorage.setItem('lxrtoinfo',JSON.stringify(item))
                }
                that.$router.go(-1)
            },
            getmanlist () {
                var qstr = {
                    whereinfo: "ownuser='" + localStorage.getItem('userphone') + "' and mantype = " + that.way,
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querylxr',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            that.manlist = res.data
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            adduser () {
                if (that.way !== '') {
                    that.$router.push({ path: 'SelectMan?way=' + that.way })
                } else {
                    that.$router.push({ path: 'SelectMan' })
                }
            }
        }
    }
</script>

<style scoped>

</style>
