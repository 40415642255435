/**
 * Created by yangxiaoshuo on 2019/10/15.
 */
import Vue from 'vue'
import Router from 'vue-router'
import MainPage from './components/MainPage.vue'
import ToHome from './components/service/ToHome.vue'
import FToHome from './components/service/FToHome.vue'
import SelectCity from './components/service/SelectCity.vue'
import QueryPos from './components/service/QueryPos.vue'
import SelectMan from './components/service/SelectMan.vue'
import ManMana from './components/service/ManMana.vue'
import OrderInfo from './components/order/OrderInfo.vue'
import OrderIn from './components/order/OrderIn.vue'
import RegMember from './components/reg/RegMember.vue'
import CompleteInfo from './components/reg/CompleteInfo.vue'
import My from './components/my/My.vue'
import PaySuccess from './components/pay/PaySuccess.vue'
import PayError from './components/pay/PayError.vue'
import AddressMana from './components/address/AddressMana.vue'
import AddAddress from './components/address/AddAddress.vue'
import OrderPJ from './components/order/OrderPJ.vue'
import OrderPSInfo from './components/order/OrderPSInfo.vue'
import OrderDes from './components/order/OrderDes.vue'
import OrderCancel from './components/order/OrderCancel.vue'
import OrderChangeTime from './components/order/OrderChangeTime.vue'
import TestTime from './components/test/TestTime.vue'
import TestOpenMain from './components/test/TestOpenMain.vue'
Vue.use(Router)

export default new Router ({
    routes: [
        {path:'/',name:'MainPage',meta:{title: '首页',footshow: true},component:MainPage},
        {path:'/TestTime',name:'TestTime',meta:{title: '测试'},component:TestTime},
        {path:'/TestOpenMain',name:'TestOpenMain',meta:{title: '测试打开'},component:TestOpenMain},
        {path:'/ToHome',name:'ToHome',meta:{title: '港到门'},component:ToHome},
        {path:'/SelectMan',name:'SelectMan',meta:{title: '人员信息'},component:SelectMan},
        {path:'/ManMana',name:'ManMana',meta:{title: '地址簿'},component:ManMana},
        {path:'/QueryPos',name:'QueryPos',meta:{title: '查询位置'},component:QueryPos},
        {path:'/OrderCancel',name:'OrderCancel',meta:{title: '订单取消'},component:OrderCancel},
        {path:'/OrderChangeTime',name:'OrderChangeTime',meta:{title: '订单改期'},component:OrderChangeTime},
        {path:'/OrderDes',name:'OrderDes',meta:{title: '订单详情'},component:OrderDes},
        {path:'/FToHome',name:'FToHome',meta:{title: '门到港'},component:FToHome},
        {path:'/OrderPSInfo',name:'OrderPSInfo',meta:{title: '物流信息',footshow: true},component:OrderPSInfo},
        {path:'/OrderPJ',name:'OrderPJ',meta:{title: '评论',footshow: true},component:OrderPJ},
        {path:'/OrderInfo',name:'OrderInfo',meta:{title: '订单',footshow: true},component:OrderInfo},
        {path:'/PaySuccess',name:'PaySuccess',meta:{title: '支付成功',footshow: true},component:PaySuccess},
        {path:'/PayError',name:'PayError',meta:{title: '支付失败',footshow: true},component:PayError},
        {path:'/OrderIn',name:'OrderIn',meta:{title: '行李寄送',footshow: true},component:OrderIn},
        {path:'/AddressMana',name:'AddressMana',meta:{title: '地址管理',footshow: true},component:AddressMana},
        {path:'/AddAddress',name:'AddAddress',meta:{title: '地址添加',footshow: true},component:AddAddress},
        {path:'/My',name:'My',meta:{title: '我的',footshow: true},component:My},
        {path:'/SelectCity',name:'SelectCity',meta:{title: '选择城市',footshow: true},component:SelectCity},
        {path:'/RegMember',name:'RegMember',meta:{title: '用户注册'},component:RegMember},
        {path:'/CompleteInfo',name:'CompleteInfo',meta:{title: '完善资料'},component:CompleteInfo}
    ]
})
