<template>
    <div>
        <van-tabs :active="active">
            <van-tab title="寄件地址">
                <van-cell-group>
                    <van-swipe-cell v-for="(item,index) in fromaddresslist" :key="index">
                        <van-cell :label="item.format_address">
                            <template #title>
                                <div v-if="item.is_default === 1">默认</div>
                                <div v-else></div>
                            </template>
                        </van-cell>
                        <template #right>
                            <van-button square type="primary" @click="defaultaddress(item)" text="设为默认" />
                            <van-button square type="danger" style="margin-left: 5px;" @click="deladdress(item)" text="删除" />
                        </template>
                    </van-swipe-cell>
                </van-cell-group>
            </van-tab>
            <van-tab title="收件地址">
                <van-cell-group>
                    <van-swipe-cell v-for="(item,index) in toaddresslist" :key="index">
                        <van-cell :label="item.format_address">
                            <template #title>
                                <div v-if="item.is_default === 1">默认</div>
                                <div v-else></div>
                            </template>
                        </van-cell>
                        <template #right>
                            <van-button square type="primary" @click="defaultaddress(item)" text="设为默认" />
                            <van-button square type="danger" style="margin-left: 5px;" @click="deladdress(item)" text="删除" />
                        </template>
                    </van-swipe-cell>
                </van-cell-group>
            </van-tab>
        </van-tabs>
        <div style="position: absolute; left:50%; margin-left: -150px; text-algin:center; bottom: 60px;">
            <van-button style="width:300px;" color="#3c71d9" type="primary" round @click="addaddress" >添加地址</van-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Tab, Tabs } from 'vant';
    import {Notify} from 'vant';
    Vue.use(Notify);
    Vue.use(Tab);
    Vue.use(Tabs);
    var that
    export default {
        name: "AddressMana",
        data () {
            return {
                fromaddresslist: [],
                toaddresslist: [],
                active: 0
            }
        },
        mounted() {
            that = this
            that.getfromaddress()
            that.gettoaddress()
        },
        methods: {
            defaultaddress (item) {
                window.console.log(item)
                if (that.active === 0) {
                    localStorage.setItem('from_address',item.format_address)
                }
                if (that.active === 1) {
                    localStorage.setItem('to_address',item.format_address)
                }
                var updata = {
                    id: item.id.toString(),
                    adtype: that.active.toString(),
                    user_id: localStorage.getItem('openid')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/upaddressdefault',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            that.getfromaddress()
                            that.gettoaddress()
                            Notify({ type: 'success', message: '已设置默认地址' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            deladdress (item) {
                window.console.log(item)
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/deladdressinfo',
                    method: 'post',
                    data: {
                        id: item.id.toString()
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            that.getfromaddress()
                            that.gettoaddress()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getfromaddress () {
                var qstr = {
                    whereinfo: " user_id='" + localStorage.getItem('openid') + "' and adtype = 0",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryaddressinfo',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.fromaddresslist = res.data
                        } else {
                            that.fromaddresslist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            gettoaddress () {
                var qstr = {
                    whereinfo: " user_id='" + localStorage.getItem('openid') + "' and adtype = 1",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryaddressinfo',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.toaddresslist = res.data
                        } else {
                            that.toaddresslist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            addaddress () {
                that.$router.push({ path: 'AddAddress' })
            }
        }
    }
</script>

<style scoped>

</style>
