<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                基础信息
            </div>
            <van-form>
                <van-count-down
                        ref="countDown"
                        hidden
                        second
                        time="30000"
                        :auto-start="false"
                        format="ss"
                        @change = "changetime"
                        @finish="finishok"
                />
                <van-field
                        v-model="phone"
                        name="手机号"
                        label="手机号"
                        placeholder="手机号"
                        @blur="checkuser()"
                        :rules="[{ required: true, message: '请填写用户名' }]"
                />
                <van-field
                        v-model="sms"
                        center
                        clearable
                        label="验证码"
                        placeholder="请输入短信验证码"
                >
                    <van-button slot="button" ref="countDownBtn" size="small" :disabled="yzmabled" @touchend="getcode" @click="getcode" type="primary">
                        {{btntxt}}
                    </van-button>
                </van-field>
            </van-form>
        </div>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" @click="regmember">
                绑定手机
            </van-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { CountDown } from 'vant';
    import { Notify } from 'vant';
    import Utils from '../util/utils'
    Vue.use(Notify);
    Vue.use(CountDown);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    var that
    export default {
        name: "RegMember",
        data () {
            return {
                phone: '',
                sms: '',
                password: '',
                repassword: '',
                userimg: '',
                usernick: '',
                ifreg: '',
                btntxt: '发送验证码',
                can_use: true,
                yzmabled: false
            }
        },
        mounted() {
            document.title = '个人注册'
            that = this
            window.console.log(that)
        },
        methods: {
            changetime (val) {
                this.btntxt = '等待 ' + val.seconds + ' 秒'
            },
            finishok () {
                Notify({ type: 'warning', message: '倒计时结束' });
                this.yzmabled = false
                this.btntxt = '发送验证码'
            },
            checkuser () {
                var qstr = {
                    whereinfo: ' phone=' + that.phone,
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryui',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.can_use = false
                            Notify({ type: 'warning', message: '手机号重复' });
                            // 把相关数据缓存到本地
                        } else {
                            that.can_use = true
                            window.console.log('获取用户失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                this.ifreg = false
            },
            getcode () {
                var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
                if (this.phone !== '' && reg.test(this.phone)) {
                    window.console.log(this)
                    that.$refs.countDown.start()
                    this.yzmabled = true
                    var datatmp = {
                        phone: this.phone,
                        signname: '',
                        tempcode: '',
                        is_send: '0'
                    }
                    that.$http({
                        url: 'https://api.yunshangyunkeji.com/getvercode',
                        method: 'post',
                        data: datatmp,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.result==='success') {
                                // 把相关数据缓存到本地
                                window.console.log(res)
                                that.smscode = res.data.code
                                that.sms = res.data.code
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '手机号输入不正确' });
                }
            },
            regmember () {
                // 获取注册信息，进行第一步注册，保存对应的账号ID，如果有的情况
                if (localStorage.getItem('openid') !== null && localStorage.getItem('openid') !== '' && localStorage.getItem('openid') !== undefined) {
                    if (that.smscode === that.sms && that.can_use) {
                        that.addtomember()
                    } else {
                        Notify({ type: 'warning', message: '验证码错误' });
                    }
                } else {
                    Notify({ type: 'warning', message: '请先授权后再绑定手机号' });
                }
            },
            addtomember () {
                var adddata = {
                    openid: localStorage.getItem('openid'),
                    phone: that.phone
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/upuserphone',
                    method: 'post',
                    data: adddata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            localStorage.setItem('userphone',that.phone)
                            that.checkcoupon()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            addnewcoupon () {
                let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                var adata = {
                    id: '',
                    userid: localStorage.getItem('userphone'),
                    couponid: '1',
                    is_use: '0',
                    adddate: nowStr
                }
                window.console.log(adata)
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/addcouponuse',
                    method: 'post',
                    data: adata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '恭喜获得优惠券' });
                            that.$router.go(-1)
                        } else {
                            Notify({ type: 'danger', message: '优惠券获取失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            checkcoupon () {
                var qstr = {
                    whereinfo: " userid='" + localStorage.getItem('userphone') + "' and conditionid = 1",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querycouponuse',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            // 说明存在优惠券
                            window.console.log('存在优惠券不再增加')
                            that.$router.go(-1)
                        } else {
                            that.addnewcoupon()
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            RondomPass: function (number) {
                var arr = []
                var arr1 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

                for(var i = 0; i<number; i++) {
                    var n = Math.floor(Math.random() * 10)
                    arr[i] = arr1[n]
                }
                var valinfo = arr.join('')
                return valinfo
            }
        }
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
