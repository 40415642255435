<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <van-form>
                <van-field
                        v-model="orderdes"
                        label="评价"
                        type="textarea"
                        rows="5"
                        placeholder="请输入评价"
                        autosize="150"
                />
                <div style="display: flex; height: 40px; flex-direction: row;font-size: 14px; align-items: center;">
                    <div style="margin-left: 15px;">综合评分：</div>
                    <van-rate
                            v-model="orderstar"
                            size="25"
                            color="#ffd21e"
                            void-icon="star"
                            void-color="#eee"
                            bind:change="onChange"
                    />
                </div>
            </van-form>
            <div style="margin: 16px; margin-top: 40px;">
                <van-button round block type="info" native-type="submit" @click="savepl">
                    立即评论
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Utils from '../../util/utils'
    import { Rate } from 'vant';
    import { Notify } from 'vant';
    Vue.use(Notify);
    Vue.use(Rate);
    var that
    export default {
        name: "OrderPJ",
        data () {
            return {
                orderno : '',
                orderdes: '',
                orderstar: '',
                driverid: ''
            }
        },
        mounted() {
            that = this
            window.console.log(that)
            window.console.log(this.$route.query.orderno)
            if (this.$route.query.orderno !== undefined && this.$route.query.orderno !== '' && this.$route.query.orderno !== null) {
                that.orderno = this.$route.query.orderno
            }
            if (this.$route.query.driverid !== undefined && this.$route.query.driverid !== '' && this.$route.query.driverid !== null) {
                that.driverid = this.$route.query.driverid
            }
            window.console.log(that.orderno)
            window.console.log(that.driverid)
        },
        methods: {
            uporderstate (orderno,state) {
                var updata = {
                    orderno: orderno,
                    state: state
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/uporderstate',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '评论完毕' });
                        } else {
                            Notify({ type: 'danger', message: '评论失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            savepl () {
                if (that.orderdes !== '' && that.orderdes !== undefined && that.orderdes !== null) {
                    let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                    var updata = {
                        orderno: that.orderno,
                        evaluate: that.orderdes,
                        driverid: that.driverid.toString(),
                        estar: that.orderstar.toString(),
                        adddate: nowStr
                    }
                    that.$http({
                        url: 'https://api.yunshangyunkeji.com/addorderevaluate',
                        method: 'post',
                        data: updata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                that.uporderstate(that.orderno,'8')
                                that.$router.replace({ path: '/' })
                                // 把相关数据缓存到本地
                            } else {
                                window.console.log('评论失败')
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'danger', message: '请填写评论后提交' });
                }

            }
        }
    }
</script>

<style scoped>

</style>
