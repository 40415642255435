import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {
  Form,
  Field,
  CellGroup,
  Button,
  Icon,
  SubmitBar,
  Stepper,
  Grid, GridItem,
  Uploader,
  Picker,
  Popup,
  DatetimePicker,
  Notify,
  Progress,
  Calendar,
  CouponCell,
  CouponList
} from 'vant';
Vue.use(DatetimePicker);
Vue.use(Calendar);
Vue.use(Notify);
Vue.use(CouponCell);
Vue.use(CouponList);
Vue.use(Progress);
Vue.use(Popup);
Vue.use(Stepper);
Vue.use(Picker);
Vue.use(Grid);
Vue.use(Uploader);
Vue.use(GridItem);
Vue.use(SubmitBar);
Vue.use(Button);
Vue.use(Form);
Vue.use(Icon);
Vue.use(Field);
Vue.use(CellGroup);
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
    router
}).$mount('#app')

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = ''
  }
  next()
})
