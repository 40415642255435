<template>
    <div>
        <van-datetime-picker
                swipe-duration="200"
                type="time"
                @confirm="onToConfirm"
        />
    </div>
</template>

<script>
    export default {
        name: "TestTime"
    }
</script>

<style scoped>

</style>
