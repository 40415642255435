<template>
    <div>
        <div style="background-color:#fff;border: 1px solid #f3f3f3;margin: 6px; padding-bottom: 8px;padding: 6px; margin: 6px 6px 16px 6px; border-radius:10px;" :style="'min-height:' + colheight + 'px;'">
            <div style="display:flex; padding: 10px 15px 10px 15px; flex-direction: row; justify-content: flex-start; align-items: center; font-size: 28px;">
                <van-icon style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/logo.png" />
                <div style="color: #000; font-size: 16px; margin-left: 5px;">航空行李管家</div>
            </div>
            <div style="display: flex; flex-direction: row;">
                <div style="display:flex; flex:1; padding-left: 15px;padding-right: 15px; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 13px;">
                    <div>
                        订单号：{{curitem.orderno}}
                    </div>
                    <div style="margin-top: 5px; margin-left: -2px;">
                        下单时间：{{curitem.adddate}}
                    </div>
                    <div style="margin: 5px 0 0px 0px; display: flex; flex-direction: row;" v-if="curitem.pacnos !== null && curitem.pacnos !== null">
                        <div>
                            行李代码：
                        </div>
                        <div style="font-size: 12px;">
                            <div style="display: flex; flex-direction: column;">
                                <div style="margin: 2px 0 0 0px" v-for="(subitem,index) in curitem.pacnoarr" :key="index">
                                    {{subitem}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 15px; display: flex; justify-content: center;">
                <van-cell title="寄件日期" label-class="lefttxt" style=" width:100%;" is-link :value="from_date" @click="fromdateshow = true">
                    <template #icon>
                        <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/riqi.png" />
                    </template>
                </van-cell>
                <van-calendar v-model="fromdateshow" @confirm="onFromDateConfirm" />
            </div>
            <div style="margin-top: 5px; display: flex; justify-content: center;border-bottom: 1px solid #f1f1f1;">
                <van-cell title="期望寄件时间" label-class="lefttxt" style=" width:100%;" is-link :value="from_time" @click="showfromPicker = true">
                    <template #icon>
                        <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/shoujianchenggong.png" />
                    </template>
                </van-cell>
                <van-popup v-model="showfromPicker" position="bottom">
                    <van-datetime-picker
                            type="time"
                            :min-hour="7"
                            :max-hour="23"
                            @confirm="onFromConfirm"
                            @cancel="showfromPicker = false"
                    />
                </van-popup>
            </div>
            <div style="margin-top: 5px; display: flex; justify-content: center;">
                <van-cell title="送达日期" label-class="lefttxt" style=" width:100%;" is-link :value="to_date" @click="todateshow = true">
                    <template #icon>
                        <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/jijian_jihuo.png" />
                    </template>
                </van-cell>
                <van-calendar v-model="todateshow" @confirm="onToDateConfirm" />
            </div>
            <div style="margin-top: 5px; display: flex; justify-content: center;border-bottom: 1px solid #f1f1f1;">
                <van-cell title="期望送达时间" label-class="lefttxt" style=" width:100%;" is-link :value="to_time" @click="showtoPicker = true">
                    <template #icon>
                        <van-icon style="margin-top: 4px;margin-right: 5px;" size="16" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/shijian.png" />
                    </template>
                </van-cell>
                <van-popup v-model="showtoPicker" position="bottom">
                    <van-datetime-picker
                            swipe-duration="200"
                            type="time"
                            :min-hour="mintohour"
                            :max-hour="maxtohour"
                            @confirm="onToConfirm"
                            @cancel="showtoPicker = false"
                    />
                </van-popup>
            </div>
            <van-submit-bar
                    button-text="确认改期"
                    @submit="onSubmit"
            />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form, Field, CellGroup } from 'vant';
    import { Button } from 'vant';
    import { Icon } from 'vant';
    import { SubmitBar } from 'vant';
    import { Stepper } from 'vant';
    import { Grid, GridItem } from 'vant';
    import { Uploader } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Notify } from 'vant';
    import { Progress } from 'vant';
    import { Calendar } from 'vant';
    import { CouponCell, CouponList } from 'vant';
    Vue.use(DatetimePicker);
    Vue.use(Calendar);
    Vue.use(Notify);
    Vue.use(CouponCell);
    Vue.use(CouponList);
    Vue.use(Progress);
    Vue.use(Popup);
    Vue.use(Stepper);
    Vue.use(Picker);
    Vue.use(Grid);
    Vue.use(Uploader);
    Vue.use(GridItem);
    Vue.use(SubmitBar);
    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Icon);
    Vue.use(Field);
    Vue.use(CellGroup);
    var that
    export default {
        name: "OrderChangeTime",
        data () {
            return {
                curitem: '',
                colheight: '',
                showfromPicker: false,
                showtoPicker: false,
                flydateshow: false,
                showflyPicker: false,
                disorder: true,
                ordertip: '',
                fly_date: '',
                fly_time: '',
                ifhx: '',
                ordertype: '',
                jscount: 0,
                from_date: '',
                to_date: '',
                from_address: '',
                showhbh: false,
                flightno: '',
                mintohour: 7,
                maxtohour: 23,
                fromdateshow: false,
                todateshow: false,
                totimedisable: false,
                to_address: '',
                from_man: '',
                to_man: '',
                flight_no: '',
                gcount: 1,
                from_time: '',
                to_time: '',
                hxorder_no: '',
                proxy_phone: '',
                user_phone: '',
            }
        },
        mounted() {
            that = this
            that.colheight =  window.innerHeight
            var myDate = new Date();
            that.from_date = that.formatDate(myDate)
            that.to_date = that.formatDate(myDate)
            that.getorder()
        },
        methods: {
            onSubmit () {},
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                return `${date.getFullYear()}-${monthstr}-${date.getDate()}`;
            },
            onFromDateConfirm (dates) {
                window.console.log(dates)
                that.from_date = that.formatDate(dates)
                localStorage.setItem('from_date',that.from_date)
                that.fromdateshow = false
            },
            onToDateConfirm (dates) {
                window.console.log(dates)
                that.to_date = that.formatDate(dates)
                localStorage.setItem('to_date',that.to_date)
                that.todateshow = false
                that.mintohour = 7
            },
            onFromConfirm (value) {
                that.from_time = value
                localStorage.setItem('from_time',that.from_time)
                that.showfromPicker = false
                var datatmp = that.from_date + ' ' + that.from_time
                var datatmp1 = that.fly_date + ' ' + that.fly_time
                window.console.log(datatmp)
                var sjc = howLong(new Date(datatmp),new Date())
                var sjc1 = howLong(new Date(datatmp1),new Date(datatmp))
                window.console.log(sjc)
                window.console.log(sjc1)
                if ((sjc.hours < 2 && sjc.day <= 0) || (sjc1.hours < 6 && sjc1.day <= 0)) {
                    that.disorder = true
                    that.from_date = ''
                    that.from_time = ''
                    Notify({ type: 'warning', message: '取件时间距离当前下单时间至少2小时，距离起飞时间至少6小时您的行李正常情况下将于起飞时间3小时内进行送达' })
                } else {
                    that.disorder = false
                    that.ordertip = ''
                }
                var tmphour = parseInt(that.from_time) + 3
                if (parseInt(that.from_time) >= 18) {
                    that.to_time = '次日到达'
                    var tmpdate = new Date(that.from_date)
                    tmpdate= tmpdate.setDate(tmpdate.getDate()+1);
                    that.to_date = that.formatDate(new Date(tmpdate))
                    that.totimedisable = true
                    if (that.fly_date !== '') {
                        var sjc3 = howLong(new Date(that.to_date),new Date(that.fly_date))
                        window.console.log('时间差是多少',sjc3)
                        if (sjc3.hours <= 0 && sjc3.day <= 0) {
                            that.disorder = true
                            that.from_date = ''
                            that.from_time = ''
                            Notify({ type: 'warning', message: '送达时间不能晚于起飞时间' })
                        } else {
                            that.disorder = false
                            that.ordertip = ''
                        }
                    }
                } else if (parseInt(that.from_time) < 18){
                    var tmphour1 = parseInt(that.from_time) + 3
                    that.mintohour = tmphour1
                    that.totimedisable = false
                } else {
                    that.mintohour = tmphour
                    that.totimedisable = false
                }
                if (that.from_time === '' || that.to_time === '') {
                    that.disorder = true
                }
            },
            onToConfirm (value) {
                that.to_time = value
                localStorage.setItem('to_time',that.to_time)
                that.showtoPicker = false
                var datatmp = ''
                if (that.fly_date !== '' && that.fly_time !== '') {
                    datatmp = that.fly_date + ' ' + that.fly_time
                } else {
                    datatmp = ''
                }
                var datatmp2 = that.from_date + ' ' + that.from_time
                var datatmp1 = that.to_date + ' ' + that.to_time
                window.console.log(datatmp)
                var sjc1 = howLong(new Date(datatmp1),new Date(datatmp2))
                window.console.log('送达时间距离取件时间',sjc1)
                if (sjc1.hours < 4 && sjc1.day <= 0) {
                    that.disorder = true
                    that.to_date = ''
                    that.to_time  = ''
                    Notify({ type: 'warning', message: '送达时间距离取件时间最少4个小时' })
                } else {
                    that.ordertip = ''
                    if (datatmp !== '') {
                        var sjc = howLong(new Date(datatmp),new Date(datatmp1))
                        window.console.log('送达时间距离起飞时间',sjc)
                        if (sjc.hours < 3 && sjc.day <= 0) {
                            that.disorder = true
                            Notify({ type: 'warning', message: '送达时间距离起飞时间最少3个小时' })
                            that.to_date = ''
                            that.to_time  = ''
                        } else {
                            that.disorder = false
                            that.ordertip = ''
                        }
                    } else {
                        that.disorder = false
                        that.ordertip = ''
                    }
                }
                if (that.from_time === '' || that.to_time === '') {
                    that.disorder = true
                }
            },
            getorder () {
                var winfo = " orderno = '" + localStorage.getItem('curorderno') + "'"
                var qstr = {
                    whereinfo: winfo,
                    limit: '',
                    sortinfo: ''
                }
                window.console.log(qstr)

                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryorder',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            var tmpdata = res.data[0]
                            if (res.data[0].cuspacimgs !== null) {
                                tmpdata.cuspacarr = res.data[0].cuspacimgs.split(',')
                            } else {
                                tmpdata.cuspacarr = []
                            }
                            if (res.data[0].pacimgs !== null) {
                                tmpdata.pacarr = res.data[0].pacimgs.split(',')
                            } else {
                                tmpdata.pacarr = []
                            }
                            if (res.data[0].zcimgs !== null) {
                                tmpdata.zcarr = res.data[0].zcimgs.split(',')
                            } else {
                                tmpdata.zcarr = []
                            }
                            if (res.data[0].jjimgs !== null) {
                                tmpdata.jjarr = res.data[0].jjimgs.split(',')
                            } else {
                                tmpdata.jjarr = []
                            }
                            if (res.data[0].pacnos !== null) {
                                tmpdata.pacnoarr = res.data[0].pacnos.split(',')
                            } else {
                                tmpdata.pacnoarr = []
                            }
                            that.curitem = tmpdata
                        } else {
                            that.curitem = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
    function howLong(time1, time2){
        time1 = time1.getTime();
        time2 = time2.getTime();
        var cha     = time1 - time2;
        var day     = Math.floor(cha / (24 * 3600 * 1000));
        var hours   = Math.floor(cha % (24 * 3600 * 1000) / (3600 * 1000));
        var minutes = Math.floor(cha % (24 * 3600 * 1000) % (3600 * 1000) / (60 * 1000));
        var seconds = Math.floor(cha % (24 * 3600 * 1000) % (3600 * 1000) % (60 * 1000) / 1000);
        return {
            day: day,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    }
</script>

<style scoped>

</style>