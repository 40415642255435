<template>
    <div>
        <van-tabs v-model="active" @change="ordertab" animated style="background-color: #f1f1f1" :style="'min-height:' + colheight + 'px;'">
            <van-tab title="全部" style="padding: 10px;margin-bottom: 40px;">
                <div v-for="(item,index) in allorderlist"  style="border: 1px solid #f3f3f3;background-color: #fff;margin: 6px; padding-bottom: 8px;padding: 6px; margin: 6px 6px 16px 6px; border-radius:10px;" :key="index">
                    <div @click="ordershow(item.orderno)" style="display:flex; padding: 10px 0 10px 15px; flex-direction: row; justify-content: flex-start; align-items: center; font-size: 28px;">
                        <van-icon style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/logo.png" />
                        <div style="color: #000; font-size: 16px; margin-left: 5px;">航空行李管家</div>
                    </div>
                    <div @click="ordershow(item.orderno)" style="display: flex; flex-direction: row;">
                        <div style="display:flex; flex:1; padding-left: 15px;padding-right: 15px; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 13px;">
                            <div>
                                订单号：{{item.orderno}}
                            </div>
                            <div style="margin-top: 5px; margin-left: -2px;">
                                下单时间：{{item.adddate}}
                            </div>
                            <div style="margin: 5px 0 0px 0px; display: flex; flex-direction: row;" v-if="item.pacnos !== null && item.pacnos !== null">
                                <div>
                                    行李代码：
                                </div>
                                <div style="font-size: 12px;">
                                    <div style="display: flex; flex-direction: column;">
                                        <div style="margin: 2px 0 0 0px" v-for="(subitem,index) in item.pacnoarr" :key="index">
                                            {{subitem}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-if="item.orderstate >= 2 && item.orderstate !== 7 && item.orderstate !== 9 && item.orderstate !== 10">
                            已支付
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-else-if="item.orderstate === 9">
                            已取消
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-else-if="item.orderstate === 10">
                            已退订
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-else-if="item.orderstate === 7">
                            已退款
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center;color: #F8BB4A" v-else>
                            未支付
                        </div>
                    </div>
                    <div style="padding: 5px 10px 5px 0px; margin-top: 14px; display: flex; flex-direction: column; border-top: 1px solid #f1f1f1; border-bottom: 1px solid #f1f1f1; margin-bottom: 10px;">
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkgray;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">寄</div>
                            </div>
                            <div class="addressicon" style="width:100%;display:flex; flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.fromman}}: {{item.fromcontact}}<!-- <a :href="'tel:' + item.fromcontact"></a> --> </div>
                                <div style="margin-top:5px;font-size: 12px; color: rgb(177, 175, 175); text-align: left; width: 220px;">{{item.fromaddress}}</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkblue;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">收</div>
                            </div>
                            <div class="addressicon" style="width:100%;flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.toman}}: {{item.tocontact}}</div>
                                <div style="margin-top:5px; font-size: 12px; color: rgb(177, 175, 175); text-align: left;">{{item.toaddress}}</div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-bottom:10px; padding-bottom: 10px; display:flex; padding-left: 20px;padding-right: 15px;  border-bottom: 1px solid #f3f3f3; justify-content: space-between; align-items: center; font-size: 14px;">
                        <div>
                            包裹数：{{item.paccount}}
                        </div>
                        <div>
                            价格：{{item.orderprice/100}}
                        </div>
                    </div>
                    <div v-if="item.orderdes !== null && item.orderdes !== ''" style="padding-bottom: 10px;margin-bottom:10px;display:flex; margin-top:10px;padding-left: 20px; border-bottom: 1px solid #f3f3f3; align-items: center; font-size: 14px;">
                        <div>备注：</div>
                        <div>{{item.orderdes}}</div>
                    </div>
                    <div v-if="item.cuspacimgs !== null && item.cuspacimgs !== ''">
                        <div style="font-size: 14px;margin-left: 20px;">行李照片:</div>
                        <div style="display: flex; flex-direction: row; margin: 10px;" >
                            <viewer @click="event.cancelBubble = true">
                                <img style="margin-left:10px;width:64px;height:64px;" :src="subitem" v-for="(subitem,index) in item.cuspacarr" :key="index"/>
                            </viewer>
                        </div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="待支付" style="padding: 10px;margin-bottom: 40px;">
                <div v-for="(item,index) in allorderlist"  style="background-color:#fff; border: 1px solid #f3f3f3;margin: 6px; padding-bottom: 8px;padding: 6px; margin: 6px 6px 16px 6px; border-radius:10px;" :key="index">
                    <div @click="ordershow(item.orderno)" style="display:flex; padding: 10px 15px 10px 15px; flex-direction: row; justify-content: flex-start; align-items: center; font-size: 28px;">
                        <van-icon style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/logo.png" />
                        <div style="color: #000; font-size: 16px; margin-left: 5px;">航空行李管家</div>
                    </div>
                    <div @click="ordershow(item.orderno)" style="display: flex; flex-direction: row;">
                        <div style="display:flex; flex:1; padding-left: 15px;padding-right: 15px; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 13px;">
                            <div>
                                订单号：{{item.orderno}}
                            </div>
                            <div style="margin-top: 5px; margin-left: -2px;">
                                下单时间：{{item.adddate}}
                            </div>
                            <div style="margin: 5px 0 0px 0px; display: flex; flex-direction: row;" v-if="item.pacnos !== null && item.pacnos !== null">
                                <div>
                                    行李代码：
                                </div>
                                <div style="font-size: 12px;">
                                    <div style="display: flex; flex-direction: column;">
                                        <div style="margin: 2px 0 0 0px" v-for="(subitem,index) in item.pacnoarr" :key="index">
                                            {{subitem}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-if="item.orderstate > 2">
                            已支付
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center;color: #F8BB4A" v-else>
                            未支付
                        </div>
                    </div>
                    <div style="padding: 5px 10px 5px 0px; margin-top: 14px; display: flex; flex-direction: column; border-top: 1px solid #f1f1f1; border-bottom: 1px solid #f1f1f1; margin-bottom: 10px;">
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkgray;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">寄</div>
                            </div>
                            <div class="addressicon" style="width:100%;display:flex; flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.fromman}}: {{item.fromcontact}}</div>
                                <div style="margin-top:5px;font-size: 12px; color: rgb(177, 175, 175); text-align: left; width: 220px;">{{item.fromaddress}}</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkblue;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">收</div>
                            </div>
                            <div class="addressicon" style="width:100%;flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.toman}}: {{item.tocontact}}</div>
                                <div style="margin-top:5px; font-size: 12px; color: rgb(177, 175, 175); text-align: left;">{{item.toaddress}}</div>
                            </div>
                        </div>
                    </div>
                    <div style="padding-bottom: 10px; display:flex; padding-left: 20px;padding-right: 15px;  justify-content: space-between; align-items: center; font-size: 14px;">
                        <div>
                            包裹数：{{item.paccount}}
                        </div>
                        <div>
                            价格：{{item.orderprice/100}}
                        </div>
                    </div>
                    <div v-if="item.orderdes !== null && item.orderdes !== ''" style="padding-bottom: 10px;margin-top:10px;margin-bottom:10px;display:flex; padding-left: 20px; border-bottom: 1px solid #f3f3f3; align-items: center; font-size: 14px;">
                        <div>备注：</div>
                        <div>{{item.orderdes}}</div>
                    </div>
                    <div v-if="item.cuspacimgs !== null && item.cuspacimgs !== ''">
                        <div style="font-size: 14px;margin-left: 20px;">行李照片:</div>
                        <div style="display: flex; flex-direction: row; margin: 10px;">
                            <viewer>
                                <img style="margin-left:10px;width:64px;height:64px;" :src="subitem" v-for="(subitem,index) in item.cuspacarr" :key="index"/>
                            </viewer>
                        </div>
                    </div>
                    <div style="padding: 10px 10px 10px 10px; border: 1px solid #f1f1f1; display: flex; justify-content: center;">
                        <van-button color="#808069" style="width: 100px;" size="small" round @click="qxorder(item)">取消订单</van-button>
                        <van-button v-if="item.orderprice !== null && item.orderprice !== '' && parseInt(item.orderprice) > 0" color="#EA2F1E" style="margin-left: 10px;width: 100px;" size="small" round @click="onpay(item)">立即支付</van-button>
                    </div>
                </div>
            </van-tab>
            <van-tab title="进行中" style="padding: 10px;margin-bottom: 40px;">
                <div v-for="(item,index) in allorderlist" style="background-color:#fff;border: 1px solid #f3f3f3;margin: 6px; padding-bottom: 8px;padding: 6px; margin: 6px 6px 16px 6px; border-radius:10px;" :key="index">
                    <div @click="ordershow(item.orderno)" style="display:flex; padding: 10px 15px 10px 15px; flex-direction: row; justify-content: flex-start; align-items: center; font-size: 28px;">
                        <van-icon style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/logo.png" />
                        <div style="color: #000; font-size: 16px; margin-left: 5px;">航空行李管家</div>
                    </div>
                    <div @click="ordershow(item.orderno)" style="display: flex; flex-direction: row;">
                        <div style="display:flex; flex:1; padding-left: 15px;padding-right: 15px; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 13px;">
                            <div>
                                订单号：{{item.orderno}}
                            </div>
                            <div style="margin-top: 5px; margin-left: -2px;">
                                下单时间：{{item.adddate}}
                            </div>
                            <div style="margin: 5px 0 0px 0px; display: flex; flex-direction: row;" v-if="item.pacnos !== null && item.pacnos !== null">
                                <div>
                                    行李代码：
                                </div>
                                <div style="font-size: 12px;">
                                    <div style="display: flex; flex-direction: column;">
                                        <div style="margin: 2px 0 0 0px" v-for="(subitem,index) in item.pacnoarr" :key="index">
                                            {{subitem}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-if="item.orderstate >= 2">
                            已支付
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center;color: #F8BB4A" v-else>
                            未支付
                        </div>
                    </div>
                    <div style="padding: 5px 10px 5px 0px; margin-top: 14px; display: flex; flex-direction: column; border-top: 1px solid #f1f1f1; border-bottom: 1px solid #f1f1f1; margin-bottom: 10px;">
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkgray;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">寄</div>
                            </div>
                            <div class="addressicon" style="width:100%;display:flex; flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.fromman}}: {{item.fromcontact}}</div>
                                <div style="margin-top:5px;font-size: 12px; color: rgb(177, 175, 175); text-align: left; width: 220px;">{{item.fromaddress}}</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkblue;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">收</div>
                            </div>
                            <div class="addressicon" style="width:100%;flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.toman}}: {{item.tocontact}}</div>
                                <div style="margin-top:5px; font-size: 12px; color: rgb(177, 175, 175); text-align: left;">{{item.toaddress}}</div>
                            </div>
                        </div>
                    </div>
                    <div style="padding-bottom: 10px; display:flex; padding-left: 20px;padding-right: 15px;  border-bottom: 1px solid #f3f3f3; justify-content: space-between; align-items: center; font-size: 14px;">
                        <div>
                            包裹数：{{item.paccount}}
                        </div>
                        <div>
                            价格：{{item.orderprice/100}}
                        </div>
                    </div>
                    <div v-if="item.orderdes !== null && item.orderdes !== ''" style="margin-top:10px;padding-bottom: 10px;margin-bottom:10px;display:flex; padding-left: 20px; border-bottom: 1px solid #f3f3f3; align-items: center; font-size: 14px;">
                        <div>备注：</div>
                        <div>{{item.orderdes}}</div>
                    </div>
                    <div v-if="item.cuspacimgs !== null && item.cuspacimgs !== ''">
                        <div style="font-size: 14px;margin-left: 20px;">行李照片:</div>
                        <div style="display: flex; flex-direction: row; margin: 10px;">
                            <viewer>
                                <img style="margin-left:10px;width:64px;height:64px;" :src="subitem" v-for="(subitem,index) in item.cuspacarr" :key="index"/>
                            </viewer>
                        </div>
                    </div>
                    <div style="padding: 10px 10px 10px 10px; border: 1px solid #f1f1f1; display: flex; justify-content: center;">
                        <van-button color="#808069" style="width: 80px;" size="small" round @click="ckwl(item)">查看物流</van-button>
                        <van-button v-if="item.priceex !== null && item.priceex !== '' && item.priceex !== '0' && parseInt(item.priceex) > 0" color="#EA2F1E" style="width: 80px;margin-left: 6px;" size="small" round @click="bjorder(item)">进行补价</van-button>
                        <van-button v-if="item.orderstate === 2 && item.userphone !== item.proxyphone" color="#EA2F1E" style="width: 80px;margin-left: 6px;" size="small" round @click="showrefuse(item)">申请退款</van-button>
                        <van-button v-if="item.hx_mode !== null && item.hx_mode !== ''" color="#808069" style="width: 80px;margin-left: 6px;" size="small" round @click="rqtd(item)">申请退订</van-button>
                        <van-button color="#808069" style="width: 80px;margin-left: 6px;" size="small" round @click="rqorder(item)">确认签收</van-button>
                    </div>
                </div>
            </van-tab>
            <!--
            <van-tab title="待评价" style="padding: 10px;margin-bottom: 40px;">
                <div v-for="(item,index) in allorderlist" style="background-color:#fff;border: 1px solid #f3f3f3;margin: 6px; padding-bottom: 8px;padding: 6px; margin: 6px 6px 16px 6px; border-radius:10px;" :key="index">
                    <div style="display:flex; padding: 10px 15px 10px 15px; flex-direction: row; justify-content: flex-start; align-items: center; font-size: 28px;">
                        <van-icon style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/logo.png" />
                        <div style="color: #000; font-size: 16px; margin-left: 5px;">航空行李管家</div>
                    </div>
                    <div style="display: flex; flex-direction: row;">
                        <div style="display:flex; flex:1; padding-left: 15px;padding-right: 15px; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 13px;">
                            <div>
                                订单号：{{item.orderno}}
                            </div>
                            <div style="margin-top: 5px; margin-left: -2px;">
                                下单时间：{{item.adddate}}
                            </div>
                            <div style="margin: 5px 0 0px 0px; display: flex; flex-direction: row;" v-if="item.pacnos !== null && item.pacnos !== null">
                                <div>
                                    行李代码：
                                </div>
                                <div style="font-size: 12px;">
                                    <div style="display: flex; flex-direction: column;">
                                        <div style="margin: 2px 0 0 0px" v-for="(subitem,index) in item.pacnoarr" :key="index">
                                            {{subitem}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-if="item.orderstate > 2">
                            已支付
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center;color: #F8BB4A" v-else>
                            未支付
                        </div>
                    </div>
                    <div style="padding: 5px 10px 5px 0px; margin-top: 14px; display: flex; flex-direction: column; border-top: 1px solid #f1f1f1; border-bottom: 1px solid #f1f1f1; margin-bottom: 10px;">
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkgray;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">寄</div>
                            </div>
                            <div class="addressicon" style="width:100%;display:flex; flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.fromman}}: {{item.fromcontact}}</div>
                                <div style="margin-top:5px;font-size: 12px; color: rgb(177, 175, 175); text-align: left; width: 220px;">{{item.fromaddress}}</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkblue;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">收</div>
                            </div>
                            <div class="addressicon" style="width:100%;flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.toman}}: {{item.tocontact}}</div>
                                <div style="margin-top:5px; font-size: 12px; color: rgb(177, 175, 175); text-align: left;">{{item.toaddress}}</div>
                            </div>
                        </div>
                    </div>
                    <div style="padding-bottom: 10px; display:flex; padding-left: 20px;padding-right: 15px;  border-bottom: 1px solid #f3f3f3; justify-content: space-between; align-items: center; font-size: 14px;">
                        <div>
                            包裹数：{{item.paccount}}
                        </div>
                        <div>
                            价格：{{item.orderprice/100}}
                        </div>
                    </div>
                    <div  v-if="item.orderdes !== null && item.orderdes !== ''" style="padding-bottom: 10px;margin-bottom:10px;margin-top:10px;display:flex; padding-left: 20px; border-bottom: 1px solid #f3f3f3; align-items: center; font-size: 14px;">
                        <div>备注：</div>
                        <div>{{item.orderdes}}</div>
                    </div>
                    <div v-if="item.cuspacimgs !== null && item.cuspacimgs !== ''">
                        <div style="font-size: 14px;margin-left: 20px;">行李照片:</div>
                        <div style="display: flex; flex-direction: row; margin: 10px;">
                            <img style="margin-left:10px;width:64px;height:64px;" :src="subitem" v-for="(subitem,index) in item.cuspacimgs.split(',')" :key="index"/>
                        </div>
                    </div>
                    <div style="padding: 10px 10px 10px 10px; border: 1px solid #f1f1f1; display: flex; justify-content: center;">
                        <van-button color="#808069" style="width: 100px;" size="small" round @click="pjorder(item)">立即评价</van-button>
                    </div>
                </div>
            </van-tab>
            -->
            <van-tab title="已完成" style="padding: 10px;margin-bottom: 40px;">
                <div v-for="(item,index) in allorderlist" style="background-color:#fff;border: 1px solid #f3f3f3;margin: 6px; padding-bottom: 8px;padding: 6px; margin: 6px 6px 16px 6px; border-radius:10px;" :key="index">
                    <div @click="ordershow(item.orderno)" style="display:flex; padding: 10px 15px 10px 15px; flex-direction: row; justify-content: flex-start; align-items: center; font-size: 28px;">
                        <van-icon style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/logo.png" />
                        <div style="color: #000; font-size: 16px; margin-left: 5px;">航空行李管家</div>
                    </div>
                    <div @click="ordershow(item.orderno)" style="display: flex; flex-direction: row;">
                        <div style="display:flex; flex:1; padding-left: 15px;padding-right: 15px; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 13px;">
                            <div>
                                订单号：{{item.orderno}}
                            </div>
                            <div style="margin-top: 5px; margin-left: -2px;">
                                下单时间：{{item.adddate}}
                            </div>
                            <div style="margin: 5px 0 0px 0px; display: flex; flex-direction: row;" v-if="item.pacnos !== null && item.pacnos !== null">
                                <div>
                                    行李代码：
                                </div>
                                <div style="font-size: 12px;">
                                    <div style="display: flex; flex-direction: column;">
                                        <div style="margin: 2px 0 0 0px" v-for="(subitem,index) in item.pacnoarr" :key="index">
                                            {{subitem}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-if="item.orderstate > 2">
                            已支付
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center;color: #F8BB4A" v-else>
                            未支付
                        </div>
                    </div>
                    <div style="padding: 5px 10px 5px 0px; margin-top: 14px; display: flex; flex-direction: column; border-top: 1px solid #f1f1f1; border-bottom: 1px solid #f1f1f1; margin-bottom: 10px;">
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkgray;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">寄</div>
                            </div>
                            <div class="addressicon" style="width:100%;display:flex; flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.fromman}}: {{item.fromcontact}}</div>
                                <div style="margin-top:5px;font-size: 12px; color: rgb(177, 175, 175); text-align: left; width: 220px;">{{item.fromaddress}}</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkblue;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">收</div>
                            </div>
                            <div class="addressicon" style="width:100%;flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.toman}}: {{item.tocontact}}</div>
                                <div style="margin-top:5px; font-size: 12px; color: rgb(177, 175, 175); text-align: left;">{{item.toaddress}}</div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-bottom:10px; padding-bottom: 10px; display:flex; padding-left: 20px;padding-right: 15px;  border-bottom: 1px solid #f3f3f3; justify-content: space-between; align-items: center; font-size: 14px;">
                        <div>
                            包裹数：{{item.paccount}}
                        </div>
                        <div>
                            价格：{{item.orderprice/100}}
                        </div>
                    </div>
                    <div v-if="item.orderdes !== null && item.orderdes !== ''" style="padding-bottom: 10px;margin-bottom:10px;display:flex; padding-left: 20px; border-bottom: 1px solid #f3f3f3; align-items: center; font-size: 14px;">
                        <div>备注：</div>
                        <div>{{item.orderdes}}</div>
                    </div>
                    <div v-if="item.cuspacimgs !== null && item.cuspacimgs !== ''">
                        <div style="font-size: 14px;margin-left: 20px;">行李照片:</div>
                        <div style="display: flex; flex-direction: row; margin: 10px;">
                            <viewer>
                                <img style="margin-left:10px;width:64px;height:64px;" :src="subitem" v-for="(subitem,index) in item.cuspacarr" :key="index"/>
                            </viewer>
                        </div>
                    </div>
                    <div v-if="item.orderstate === 6" style="padding: 10px 10px 10px 10px; border-top: 1px solid #f1f1f1; display: flex; justify-content: center;">
                        <van-button color="#808069" style="width: 100px;" size="small" round @click="pjorder(item)">立即评价</van-button>
                    </div>
                    <div v-if="item.orderstate === 8" style="padding: 10px 10px 10px 10px; border-top: 1px solid #f1f1f1; display: flex; justify-content: center;">
                        已评价
                    </div>
                </div>
            </van-tab>
            <van-tab title="已退款/订" style="padding: 10px;margin-bottom: 40px;">
                <div v-for="(item,index) in allorderlist" style="background-color:#fff;border: 1px solid #f3f3f3;border-radius: 5px; margin-bottom: 10px;" :key="index">
                    <div @click="ordershow(item.orderno)" style="display:flex; padding: 10px 15px 10px 15px; flex-direction: row; justify-content: flex-start; align-items: center; font-size: 28px;">
                        <van-icon style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/logo.png" />
                        <div style="color: #000; font-size: 16px; margin-left: 5px;">航空行李管家</div>
                    </div>
                    <div @click="ordershow(item.orderno)" style="display: flex; flex-direction: row;">
                        <div style="display:flex; flex:1; padding-left: 15px;padding-right: 15px; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 13px;">
                            <div>
                                订单号：{{item.orderno}}
                            </div>
                            <div style="margin-top: 5px; margin-left: -2px;">
                                下单时间：{{item.adddate}}
                            </div>
                            <div style="margin: 5px 0 0px 0px; display: flex; flex-direction: row;" v-if="item.pacnos !== null && item.pacnos !== null">
                                <div>
                                    行李代码：
                                </div>
                                <div style="font-size: 12px;">
                                    <div style="display: flex; flex-direction: column;">
                                        <div style="margin: 2px 0 0 0px" v-for="(subitem,index) in item.pacnoarr" :key="index">
                                            {{subitem}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-if="item.orderstate >= 2 && item.orderstate !== 7 && item.orderstate !== 9 && item.orderstate !== 10">
                            已支付
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-else-if="item.orderstate === 9">
                            已取消
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-else-if="item.orderstate === 10">
                            已退订
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center; color: #EA2F1E" v-else-if="item.orderstate === 7">
                            已退款
                        </div>
                        <div style="width: 50px; margin-right: 10px; display: flex; justify-content: flex-end;align-items: center;color: #F8BB4A" v-else>
                            未支付
                        </div>
                    </div>
                    <div style="padding: 5px 10px 5px 0px; margin-top: 14px; display: flex; flex-direction: column; border-top: 1px solid #f1f1f1; border-bottom: 1px solid #f1f1f1; margin-bottom: 10px;">
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkgray;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">寄</div>
                            </div>
                            <div class="addressicon" style="width:100%;display:flex; flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.fromman}}: {{item.fromcontact}}</div>
                                <div style="margin-top:5px;font-size: 12px; color: rgb(177, 175, 175); text-align: left; width: 220px;">{{item.fromaddress}}</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; padding: 5px 0 8px 0;">
                            <div class="addressicon">
                                <div style="background-color: darkblue;color: #fff; padding: 2px; border-radius:24px; width:24px; height: 24px;">收</div>
                            </div>
                            <div class="addressicon" style="width:100%;flex-direction: column;align-items: flex-start;">
                                <div style="font-size: 14px; font-weight: 500;">{{item.toman}}: {{item.tocontact}}</div>
                                <div style="margin-top:5px; font-size: 12px; color: rgb(177, 175, 175); text-align: left;">{{item.toaddress}}</div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-bottom:10px; padding-bottom: 10px; display:flex; padding-left: 20px;padding-right: 15px;  border-bottom: 1px solid #f3f3f3; justify-content: space-between; align-items: center; font-size: 14px;">
                        <div>
                            包裹数：{{item.paccount}}
                        </div>
                        <div>
                            价格：{{item.orderprice/100}}
                        </div>
                    </div>
                    <div v-if="item.orderdes !== null && item.orderdes !== ''" style="padding-bottom: 10px;margin-bottom:10px;display:flex; padding-left: 20px; border-bottom: 1px solid #f3f3f3; align-items: center; font-size: 14px;">
                        <div>备注：</div>
                        <div>{{item.orderdes}}</div>
                    </div>
                    <div v-if="item.cuspacimgs !== null && item.cuspacimgs !== ''">
                        <div style="font-size: 14px;margin-left: 20px;">行李照片:</div>
                        <div style="display: flex; flex-direction: row; margin: 10px;">
                            <viewer>
                                <img style="margin-left:10px;width:64px;height:64px;" :src="subitem" v-for="(subitem,index) in item.cuspacarr" :key="index"/>
                            </viewer>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <van-dialog v-model="isshowrefuse" title="申请退款" show-cancel-button @confirm="startrefuse">
            <div style="height: 300px;overflow:auto">
                <div v-for="(item,index) in wxpaylist" :key="index" style="padding: 20px; font-size: 14px;">
                    <div>
                        订单号：{{item.orderno}}
                    </div>
                    <div>
                        支付单号：{{item.wxpayno}}
                    </div>
                    <div>
                        支付金额：{{parseInt(item.wxpayprice/100)}} 元
                    </div>
                    <div>
                        支付时间：{{item.wxpaydate}}
                    </div>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Tab, Tabs } from 'vant';
    import { Card } from 'vant';
    import { Dialog } from 'vant';
    import { Notify } from 'vant';
    import Viewer from 'v-viewer'
    import 'viewerjs/dist/viewer.css'
    // import JsBridge from '../../util/JsBridge'
    // import jsBridge from '@zebing/js-bridge';
    // Vue.forceUpdate()
    Vue.use(Viewer)
    Viewer.setDefaults({
        // 需要配置的属性 注意属性并没有引号
        title: false,
        toolbar: false
    })
    Vue.use(Tab);
    Vue.use(Dialog);
    Vue.use(Notify);
    Vue.use(Card);
    Vue.use(Tabs);
    var that
    export default {
        name: "OrderInfo",
        data () {
            return {
                active: 0,
                allorderlist: [],
                wxpaylist: [],
                isshowrefuse: false,
                colheight: '',
                curpage: 1,
                ifend: false,
                state: 0,
                size: 10,
                tabindex: 0,
                opwaymode: false,
                hxmode: false
            }
        },
        mounted() {
            that = this
            if (this.$route.query.mode !== undefined && this.$route.query.mode !== null && this.$route.query.mode !== '') {
                if (this.$route.query.phone !== undefined && this.$route.query.phone !== null && this.$route.query.phone !== '') {
                    localStorage.setItem('proxy_phone',this.$route.query.phone)
                    localStorage.setItem('userphone',this.$route.query.phone)
                }
                if (this.$route.query.flight_no !== undefined && this.$route.query.flight_no !== null && this.$route.query.flight_no !== '') {
                    localStorage.setItem('flight_no',this.$route.query.flight_no)
                }
                if (this.$route.query.if_free !== undefined && this.$route.query.if_free !== null && this.$route.query.if_free !== '') {
                    localStorage.setItem('if_free',this.$route.query.if_free)
                }
                if (this.$route.query.freight_price !== undefined && this.$route.query.freight_price !== null && this.$route.query.freight_price !== '') {
                    localStorage.setItem('freight_price',this.$route.query.freight_price)
                }
                if (this.$route.query.mode !== undefined && this.$route.query.mode !== null && this.$route.query.mode !== '') {
                    localStorage.setItem('mode',this.$route.query.mode)
                }
                if (this.$route.query.buildFlag !== undefined && this.$route.query.buildFlag !== null && this.$route.query.buildFlag !== '') {
                    localStorage.setItem('buildFlag',this.$route.query.buildFlag)
                }
                if (this.$route.query.parid !== undefined && this.$route.query.parid !== null && this.$route.query.parid !== '') {
                    localStorage.setItem('parid',this.$route.query.parid)
                }
                if (this.$route.query.agentid !== undefined && this.$route.query.agentid !== null && this.$route.query.agentid !== '') {
                    localStorage.setItem('agentid',this.$route.query.agentid)
                }
                if (this.$route.query.oriSono !== undefined && this.$route.query.oriSono !== null && this.$route.query.oriSono !== '') {
                    localStorage.setItem('hxorder_no',this.$route.query.oriSono)
                }
                if (this.$route.query.cagentid !== undefined && this.$route.query.cagentid !== null && this.$route.query.cagentid !== '') {
                    localStorage.setItem('cagentid',this.$route.query.cagentid)
                }
                if (this.$route.query.travelType !== undefined && this.$route.query.travelType !== null && this.$route.query.travelType !== '') {
                    localStorage.setItem('travelType',this.$route.query.travelType)
                }
                that.ifhx = 1
                that.hxmode = true
            }
            if (this.$route.query.state !== undefined && this.$route.query.state !== undefined && this.$route.query.state !== '') {
                that.active = parseInt(this.$route.query.state)
            }
            if (this.$route.query.sono !== undefined && this.$route.query.sono !== null && this.$route.query.sono !== '') {
                localStorage.setItem('hxorderno',this.$route.query.sono)
            }
            if (this.$route.query.proxy_phone !== undefined && this.$route.query.proxy_phone !== null && this.$route.query.proxy_phone !== '') {
                localStorage.setItem('proxy_phone',this.$route.query.proxy_phone)
            }
            if (this.$route.query.phone !== undefined && this.$route.query.phone !== null && this.$route.query.phone !== '') {
                localStorage.setItem('proxy_phone',this.$route.query.phone)
            }
            if (this.$route.query.flight_no !== undefined && this.$route.query.flight_no !== null && this.$route.query.flight_no !== '') {
                localStorage.setItem('flight_no',this.$route.query.flight_no)
            }
            if (this.$route.query.op_way !== undefined && this.$route.query.op_way !== null && this.$route.query.op_way !== '') {
                localStorage.setItem('op_way',this.$route.query.op_way)
                if (this.$route.query.op_way.toString() === 'cancel') {
                    this.$router.push('/OrderCancel')
                }
                if (this.$route.query.op_way.toString() === 'changeTime') {
                    this.$router.push('/OrderChangeTime')
                }
                if (this.$route.query.op_way.toString() === 'paywait') {
                    that.state = 1
                    that.active = 1
                    that.opwaymode = true
                }
            } else {
                that.opwaymode = false
            }
            window.addEventListener('scroll', that.windowScroll,false);
            window.console.log('active',that.active)
            if (that.active === 0) {
                that.active = 0
                that.reloaddata()
            } else {
                window.console.log('加载数据')
                that.reloaddata()
            }
            that.colheight =  window.innerHeight
        },
        methods: {
            disclick (e) {
                e.stopPropagation();
            },
            ordershow (orderno) {
                localStorage.setItem('curorderno',orderno)
                this.$router.push('/OrderDes')
            },
            pjorder (item) {
                this.$router.push('/OrderPJ?orderno='+ item.orderno + '&driverid=' + item.driver_id)
            },
            rqtd (item) {
                window.console.log(item.hxorder)
                window.console.log(item.orderno)
                window.console.log(item.orderprice)
                that.uporderstate(item.orderno,'10','退订成功')
                that.returnhx(item.orderno,item.orderprice,10,item.hxorder)
            },
            returnhx (tmporderno,price,state,hxorder) {
                var opration = '1'
                if (parseInt(state) === 10)
                {
                    opration = '2'
                }
                var updata = {
                    pacOrderNo: tmporderno,
                    buildFlag: localStorage.getItem('buildFlag'),
                    parid: localStorage.getItem('parid'),
                    agentid: localStorage.getItem('agentid'),
                    phone: localStorage.getItem('proxy_phone'),
                    status: state.toString(),
                    price: price.toString(),
                    opration: opration,
                    mode: localStorage.getItem('mode'),
                    oriSono: hxorder,
                    sono: hxorder,
                    cagentid: localStorage.getItem('cagentid'),
                    travelType: localStorage.getItem('travelType')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxinputorder',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('航信反馈',res)
                        if (res.data.data.success) {
                            Notify({ type: 'success', message: '回传航信成功' });
                            if (parseInt(localStorage.getItem('if_free')) === 0) {
                                // 需要跳转到航信的支付页面
                                that.loadshow = true
                                window.console.log('需要跳转到航信的支付')
                                that.uphxorderno(tmporderno,res.data.data.sono)
                                if (parseInt(state) !== 10) {
                                    window.JSBridge.call("openPayment",
                                        {
                                            order_no: res.data.data.sono
                                        },
                                        function() {
                                            // 回调函数
                                            that.loadshow = false
                                            that.$router.push({ path: 'OrderInfo?mode =' + localStorage.getItem('mode') + '&phone=' + localStorage.getItem('proxy_phone') + '&flight_no=' + localStorage.getItem('flight_no') + '&if_free=' + localStorage.getItem('if_free') + '&freight_price=' + localStorage.getItem('freight_price') + '&buildFlag=' + localStorage.getItem('buildFlag') + '&parid=' + localStorage.getItem('parid') + '&agentid=' + localStorage.getItem('agentid') + '&oriSono=' + localStorage.getItem('hxorder_no') + '&cagentid=' + localStorage.getItem('cagentid') + '&travelType=' + localStorage.getItem('travelType') })
                                        }
                                    )
                                }
                            } else {
                                window.console.log('无需支付')
                                that.sendsmsmsg(localStorage.getItem('sendsmsphone'))
                            }
                        } else {
                            // that.$router.push({ path: 'PayError' })
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '回传航信失败' });
                    })
            },
            bjorder (item) {
                that.pay(item.orderno + '01',item.priceex * 100)
            },
            ckwl (item) {
                localStorage.setItem('curitem',JSON.stringify(item))
                this.$router.push('/OrderPSInfo')
            },
            qxorder (item) {
                that.uporderstate(item.orderno,'9','取消成功')
                if (parseInt(localStorage.getItem('mode')) === 0) {
                    that.uptohxyj(item.orderno,item.hxorder)
                }
                if (parseInt(localStorage.getItem('mode')) === 1) {
                    that.uptohxex(item.orderno,item.hxorder)
                }
            },
            rqorder (item) {
                Dialog.confirm({
                    title: '确认签收',
                    message: '确定要签收该订单吗？',
                })
                    .then(() => {
                        that.uporderstate(item.orderno,'6','已确认收货')
                        Dialog.confirm({
                            title: '评价订单',
                            message: '是否立即评价该订单？',
                        })
                            .then(() => {
                                this.$router.push('/OrderPJ?orderno='+ item.orderno + '&driverid=' + item.driver_id)
                                // on confirm
                            })
                            .catch(() => {
                                // on cancel
                            });
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            pay (tmporderno,jscount) {
                var qdata = {
                    description: '托运订单',
                    amount: jscount.toString(),
                    openid: localStorage.getItem('openid'),
                    orderno: tmporderno,
                }
                that.$http({
                    url: 'https://wx.yunshangyunkeji.com/wx/paysign',
                    method: 'post',
                    data: qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.code === 0) {
                            var paytmpdata = res.data.result
                            window.console.log(paytmpdata)
                            window.console.log(paytmpdata.appId)
                            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                                    "appId": paytmpdata.appId,
                                    "timeStamp": paytmpdata.timeStamp,
                                    "nonceStr": paytmpdata.nonceStr,
                                    "package": paytmpdata.package,
                                    "signType": "RSA",// 微信签名方式：
                                    "paySign":paytmpdata.paySign // 微信签名
                                },
                                (res) => {
                                    window.console.log(res)
                                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                                        window.console.log(res)
                                        that.$router.push({ path: 'PaySuccess' })
                                    } else if (res.err_msg == "get_brand_wcpay_request:cancel"){
                                        that.$router.push({ path: 'PayError' })
                                    }
                                });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', that.onTestPay, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', that.onTestPay);
                        document.attachEvent('onWeixinJSBridgeReady', that.onTestPay);
                    }
                } else {
                    window.console.log('支持微信支付')
                }
            },
            onpay(item) {
                if (localStorage.getItem('mode') !== undefined && localStorage.getItem('mode') !== null && localStorage.getItem('mode') !== '') {
                    if (parseInt(localStorage.getItem('if_free')) === 0) {
                        window.console.log('航信订单，收费')
                        if (parseInt(localStorage.getItem('mode')) === 0) {
                            window.console.log('运价模式')
                            that.returnhxyj(item.orderno, item.orderprice.toString(), 1)
                        }
                        if (parseInt(localStorage.getItem('mode')) === 1) {
                            window.console.log('航信订单，收费')
                            if (parseInt(localStorage.getItem('buildFlag')) === 4) {
                                that.returnhx(item.orderno, item.orderprice.toString(), 1,localStorage.getItem('hxorder_no'))
                            }
                            if (parseInt(localStorage.getItem('buildFlag')) === 10) {
                                that.returnhxbyflag(item.orderno, item.orderprice.toString(), 1)
                                // window.location.replace('https://bluesky.travelsky.com.cn/blueskydc/uat/bluesky194/obt/wxobt/#/pay?sono=' + localStorage.getItem('hxorder_no') + '&pac_order_no=' + tmporderno)
                            }

                        }
                    } else {
                        window.console.log('航信订单，免费')
                        that.uporderpaystate(item.orderno)
                    }
                } else {
                    that.pay(item.orderno,item.orderprice,localStorage.getItem('sendsmsphone'))
                }
            },
            returnhxyj (tmporderno,price,state) {
                var fromman = JSON.parse(localStorage.getItem('lxrfrominfo'))
                var updata = {
                    pacOrderNo: tmporderno,
                    buildFlag: localStorage.getItem('buildFlag'),
                    parid: localStorage.getItem('parid'),
                    agentid: localStorage.getItem('agentid'),
                    phone: fromman.mancontact,
                    status: state.toString(),
                    price: price.toString(),
                    opration: '1',
                    mode: localStorage.getItem('mode'),
                    oriSono: localStorage.getItem('hxorder_no'),
                    cagentid: localStorage.getItem('cagentid'),
                    travelType: localStorage.getItem('travelType')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxyjorder',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('航信反馈',res)
                        if (res.data.data.success) {
                            Notify({ type: 'success', message: '回传航信成功' });
                            that.sendsmsmsg(fromman.mancontact)
                            that.uphxorderno(tmporderno,res.data.data.sono)
                        } else {
                            // that.$router.push({ path: 'PayError' })
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '回传航信失败' });
                    })
            },
            uphxorderno (orderno,hxorderno) {
                var updata = {
                    orderno: orderno,
                    hxorder: hxorderno
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/uphxorderno',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '下单完毕' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            returnhxbyflag (tmporderno,price,state) {
                var updata = {
                    pacOrderNo: tmporderno,
                    buildFlag: localStorage.getItem('buildFlag'),
                    parid: localStorage.getItem('parid'),
                    agentid: localStorage.getItem('agentid'),
                    phone: localStorage.getItem('proxy_phone'),
                    status: state.toString(),
                    price: price.toString(),
                    opration: '1',
                    mode: localStorage.getItem('mode'),
                    oriSono: localStorage.getItem('hxorder_no'),
                    cagentid: localStorage.getItem('cagentid'),
                    travelType: localStorage.getItem('travelType')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxinputorder',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('航信反馈',res)
                        if (res.data.data.success) {
                            Notify({ type: 'success', message: '回传航信成功' });
                            if (parseInt(localStorage.getItem('if_free')) === 0) {
                                // 需要跳转到航信的支付页面
                                that.loadshow = true
                                window.console.log('需要跳转到航信的支付')
                                that.uphxorderno(tmporderno,res.data.data.sono)
                                window.location.replace('https://bluesky.travelsky.com.cn/blueskydc/uat/bluesky194/obt/wxobt/#/pay?sono=' + res.data.data.sono + '&pac_order_no=' + tmporderno)
                            } else {
                                window.console.log('无需支付')
                                that.sendsmsmsg(localStorage.getItem('sendsmsphone'))
                            }
                        } else {
                            // that.$router.push({ path: 'PayError' })
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '回传航信失败' });
                    })
            },
            startrefuse () {
                Dialog.alert({
                    title: '申请退款',
                    showCancelButton: true,
                    message: '确认要申请退款吗?',
                }).then(() => {
                    for (var i = 0; i < that.wxpaylist.length; i++) {
                        var tmpdata = that.wxpaylist[i]
                        window.console.log(tmpdata)
                        var paydata = {
                            transaction_id: tmpdata.wxpayno,
                            out_refund_no: tmpdata.orderno,
                            reason:'业务退款',
                            amount: tmpdata.wxpayprice
                        }
                        that.$http({
                            url: 'https://wx.yunshangyunkeji.com/wx/payrefund',
                            method: 'post',
                            data: paydata,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(function (res) {
                                window.console.log(res)
                                if (res.data.code === 0) {
                                    that.uppaystate(tmpdata.orderno,tmpdata.wxpayno)
                                    that.uporderstate(tmpdata.orderno,'7','退款成功')
                                } else {
                                    Notify({ type: 'danger', message: '退款失败' });
                                }
                            })
                            .catch(function (error) {
                                Notify({ type: 'danger', message: '退款超时,请联系客服进行退款' });
                                window.console.log(error)
                            })
                    }
                });
            },
            uppaystate (orderno,wxpayno) {
                var updata = {
                    orderno: orderno,
                    wxpayno: wxpayno,
                    ifrefuse: '1'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/uprefusestate',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '退款成功' });
                        } else {
                            Notify({ type: 'danger', message: '退款失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            uporderstate (orderno,state,returnstr) {
                var updata = {
                    orderno: orderno,
                    state: state
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/uporderstate',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            that.active = 0
                            that.reloaddata()
                            Notify({ type: 'success', message: returnstr });
                        } else {
                            Notify({ type: 'danger', message: '操作失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            getpayorders (orderno) {
                var qstr = {
                    whereinfo: " orderno like '%" + orderno + "%' and ifrefuse != 1",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querywxpayorders',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            that.wxpaylist = res.data
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            showrefuse (item) {
                that.isshowrefuse = true
                that.getpayorders(item.orderno)
            },
            reloaddata () {
                var val = this.active
                if (val === 0) {
                    that.state = 0
                    that.getallorder()
                }
                if (val === 1) {
                    that.state = 1
                    that.getallorder()
                }
                if (val === 2) {
                    that.state = 2
                    that.getallrunorder()
                }
                /*
                if (val === 3) {
                    that.state = 6
                    that.getalldplorder()
                }
                 */
                if (val === 3) {
                    that.state = 68
                    that.getallorder()
                }
                if (val === 4) {
                    that.state = 710
                    that.getallorder()
                }
            },
            ordertab (val) {
                window.console.log(val)
                that.tabindex = val
                if (val === 0) {
                    that.state = 0
                    that.getallorder()
                }
                if (val === 1) {
                    that.state = 1
                    that.getallorder()
                }
                if (val === 2) {
                    that.state = 2
                    that.getallrunorder()
                }
                /*
                if (val === 3) {
                    that.state = 6
                    that.getalldplorder()
                }
                 */
                if (val === 3) {
                    that.state = 68
                    that.getallorder()
                }
                if (val === 4) {
                    that.state = 710
                    that.getallorder()
                }
            },
            windowScroll(){
                var innerHeight = document.querySelector('#app').clientHeight
                var outerHeight = document.documentElement.clientHeight
                var scrollTop = document.documentElement.scrollTop?document.documentElement.scrollTop:document.body.scrollTop
                // console.log(innerHeight + '-' + outerHeight + '-' + scrollTop)
                if (that.curpage*innerHeight<=(outerHeight + scrollTop)+10) {
                    //触底
                    //当前页码＋1
                    that.curpage += 1
                    //发起请求，获取新一页的数据
                    if (!that.ifend) {
                        if (that.tabindex === 2) {
                            that.getallrunorderbypage()
                        } else if (that.tabindex === 3) {
                            that.getalldplorderbypage()
                        } else {
                            that.getorderbypage()
                        }
                    }
                }
            },
            uptohxex (curorderno,hxno) {
                var updata = {
                    pacOrderNo: curorderno,
                    buildFlag: localStorage.getItem('buildFlag'),
                    parid: localStorage.getItem('parid'),
                    agentid: localStorage.getItem('agentid'),
                    phone: localStorage.getItem('proxy_phone'),
                    status: '9',
                    price: '',
                    opration: '2',
                    mode: localStorage.getItem('mode'),
                    oriSono: hxno,
                    sono: hxno,
                    cagentid: localStorage.getItem('cagentid'),
                    travelType: localStorage.getItem('travelType')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxinputorder',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '取消成功' });
                        } else {
                            Notify({ type: 'danger', message: '取消失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            uptohxyj (curorderno,hxno) {
                var updata = {
                    pacOrderNo: curorderno,
                    agentid: localStorage.getItem('agentid'),
                    status: '9',
                    opration: '2',
                    sono: hxno
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxyjordercancel',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '取消成功' });
                        } else {
                            Notify({ type: 'danger', message: '取消失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            getallrunorder () {
                var winfo = ''
                if (that.opwaymode) {
                    winfo = " ordertype != 9 and hxorder='" + localStorage.getItem('hxorderno') + "'"
                } else {
                    winfo = " ordertype != 9 and (userphone='" + localStorage.getItem('userphone') + "' or fromcontact='" + localStorage.getItem('userphone') + "')"
                }
                if (that.state > 0) {
                    winfo = winfo + ' and orderstate >=2 and orderstate < 6'
                }
                var qstr = {
                    whereinfo: winfo,
                    limit: ' limit 0,' + that.size,
                    sortinfo: ' order by adddate desc'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryorder',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].cuspacimgs !== null) {
                                    tmpdata[i].cuspacarr = res.data[i].cuspacimgs.split(',')
                                } else {
                                    tmpdata[i].cuspacarr = []
                                }
                                if (res.data[i].pacimgs !== null) {
                                    tmpdata[i].pacarr = res.data[i].pacimgs.split(',')
                                } else {
                                    tmpdata[i].pacarr = []
                                }
                                if (res.data[i].zcimgs !== null) {
                                    tmpdata[i].zcarr = res.data[i].zcimgs.split(',')
                                } else {
                                    tmpdata[i].zcarr = []
                                }
                                if (res.data[i].jjimgs !== null) {
                                    tmpdata[i].jjarr = res.data[i].jjimgs.split(',')
                                } else {
                                    tmpdata[i].jjarr = []
                                }
                                if (res.data[i].pacnos !== null) {
                                    tmpdata[i].pacnoarr = res.data[i].pacnos.split(',')
                                } else {
                                    tmpdata[i].pacnoarr = []
                                }
                            }
                            that.allorderlist = tmpdata
                            window.console.log(that.allorderlist)
                        } else {
                            that.allorderlist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getallrunorderbypage () {
                var winfo = ''
                if (that.opwaymode) {
                    winfo = " ordertype != 9 and hxorder='" + localStorage.getItem('hxorderno') + "'"
                } else {
                    winfo = " ordertype != 9 and (userphone='" + localStorage.getItem('userphone') + "' or fromcontact='" + localStorage.getItem('userphone') + "')"
                }
                if (that.state > 0) {
                    winfo = winfo + ' and orderstate >=2 and orderstate < 6'
                }
                var qstr = {
                    whereinfo: winfo,
                    limit: ' limit ' + (that.curpage - 1)*that.size + ',' + that.size,
                    sortinfo: ' order by adddate desc'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryorder',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].cuspacimgs !== null) {
                                    tmpdata[i].cuspacarr = res.data[i].cuspacimgs.split(',')
                                } else {
                                    tmpdata[i].cuspacarr = []
                                }
                                if (res.data[i].pacimgs !== null) {
                                    tmpdata[i].pacarr = res.data[i].pacimgs.split(',')
                                } else {
                                    tmpdata[i].pacarr = []
                                }
                                if (res.data[i].zcimgs !== null) {
                                    tmpdata[i].zcarr = res.data[i].zcimgs.split(',')
                                } else {
                                    tmpdata[i].zcarr = []
                                }
                                if (res.data[i].jjimgs !== null) {
                                    tmpdata[i].jjarr = res.data[i].jjimgs.split(',')
                                } else {
                                    tmpdata[i].jjarr = []
                                }
                                if (res.data[i].pacnos !== null) {
                                    tmpdata[i].pacnoarr = res.data[i].pacnos.split(',')
                                } else {
                                    tmpdata[i].pacnoarr = []
                                }
                                that.allorderlist.push(tmpdata[i])
                            }
                            window.console.log('分页后数据',that.allorderlist)
                        } else {
                            that.ifend = true
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getallorder () {
                var winfo = ''
                if (that.opwaymode) {
                    winfo = " ordertype != 9 and hxorder='" + localStorage.getItem('hxorderno') + "'"
                } else {
                    winfo = " ordertype != 9 and (userphone='" + localStorage.getItem('userphone') + "' or fromcontact='" + localStorage.getItem('userphone') + "')"
                }
                if (that.state > 0 && that.state < 11) {
                    winfo = winfo + ' and orderstate=' + that.state
                } else if (that.state > 20 && that.state < 100) {
                    winfo = winfo + ' and (orderstate=6 or orderstate = 8)'
                } else if (that.state > 100) {
                    winfo = winfo + ' and (orderstate=7 or orderstate = 10)'
                }
                var qstr = {
                    whereinfo: winfo,
                    limit: ' limit 0,' + that.size,
                    sortinfo: ' order by adddate desc'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryorder',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].cuspacimgs !== null) {
                                    tmpdata[i].cuspacarr = res.data[i].cuspacimgs.split(',')
                                } else {
                                    tmpdata[i].cuspacarr = []
                                }
                                if (res.data[i].pacimgs !== null) {
                                    tmpdata[i].pacarr = res.data[i].pacimgs.split(',')
                                } else {
                                    tmpdata[i].pacarr = []
                                }
                                if (res.data[i].zcimgs !== null) {
                                    tmpdata[i].zcarr = res.data[i].zcimgs.split(',')
                                } else {
                                    tmpdata[i].zcarr = []
                                }
                                if (res.data[i].jjimgs !== null) {
                                    tmpdata[i].jjarr = res.data[i].jjimgs.split(',')
                                } else {
                                    tmpdata[i].jjarr = []
                                }
                                if (res.data[i].pacnos !== null) {
                                    tmpdata[i].pacnoarr = res.data[i].pacnos.split(',')
                                } else {
                                    tmpdata[i].pacnoarr = []
                                }
                            }
                            that.allorderlist = tmpdata
                        } else {
                            that.allorderlist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getorderbypage () {
                var winfo = ''
                if (that.opwaymode) {
                    winfo = " ordertype != 9 and hxorder='" + localStorage.getItem('hxorderno') + "'"
                } else {
                    winfo = " ordertype != 9 and (userphone='" + localStorage.getItem('userphone') + "' or fromcontact='" + localStorage.getItem('userphone') + "')"
                }
                if (that.state > 0 && that.state < 11) {
                    winfo = winfo + ' and orderstate=' + that.state
                } else if (that.state > 20) {
                    winfo = winfo + ' and (orderstate=6 or orderstate = 8)'
                }
                var qstr = {
                    whereinfo: winfo,
                    limit: ' limit ' + (that.curpage - 1)*that.size + ',' + that.size,
                    sortinfo: ' order by adddate desc'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryorder',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].cuspacimgs !== null) {
                                    tmpdata[i].cuspacarr = res.data[i].cuspacimgs.split(',')
                                } else {
                                    tmpdata[i].cuspacarr = []
                                }
                                if (res.data[i].pacimgs !== null) {
                                    tmpdata[i].pacarr = res.data[i].pacimgs.split(',')
                                } else {
                                    tmpdata[i].pacarr = []
                                }
                                if (res.data[i].zcimgs !== null) {
                                    tmpdata[i].zcarr = res.data[i].zcimgs.split(',')
                                } else {
                                    tmpdata[i].zcarr = []
                                }
                                if (res.data[i].jjimgs !== null) {
                                    tmpdata[i].jjarr = res.data[i].jjimgs.split(',')
                                } else {
                                    tmpdata[i].jjarr = []
                                }
                                if (res.data[i].pacnos !== null) {
                                    tmpdata[i].pacnoarr = res.data[i].pacnos.split(',')
                                } else {
                                    tmpdata[i].pacnoarr = []
                                }
                                that.allorderlist.push(tmpdata[i])
                            }
                            window.console.log('分页后数据',that.allorderlist)
                        } else {
                            that.ifend = true
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getalldplorder () {
                var winfo = ''
                if (that.opwaymode) {
                    winfo = " ordertype != 9 and hxorder='" + localStorage.getItem('hxorderno') + "'"
                } else {
                    winfo = " ordertype != 9 and (userphone='" + localStorage.getItem('userphone') + "' or fromcontact='" + localStorage.getItem('userphone') + "')"
                }
                if (that.state > 0) {
                    winfo = winfo + ' and orderstate=' + that.state
                }
                var qstr = {
                    whereinfo: winfo,
                    limit: ' limit 0,' + that.size,
                    sortinfo: ' order by psdate desc'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querypsorderinfo',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].cuspacimgs !== null) {
                                    tmpdata[i].cuspacarr = res.data[i].cuspacimgs.split(',')
                                } else {
                                    tmpdata[i].cuspacarr = []
                                }
                                if (res.data[i].pacimgs !== null) {
                                    tmpdata[i].pacarr = res.data[i].pacimgs.split(',')
                                } else {
                                    tmpdata[i].pacarr = []
                                }
                                if (res.data[i].zcimgs !== null) {
                                    tmpdata[i].zcarr = res.data[i].zcimgs.split(',')
                                } else {
                                    tmpdata[i].zcarr = []
                                }
                                if (res.data[i].jjimgs !== null) {
                                    tmpdata[i].jjarr = res.data[i].jjimgs.split(',')
                                } else {
                                    tmpdata[i].jjarr = []
                                }
                                if (res.data[i].pacnos !== null) {
                                    tmpdata[i].pacnoarr = res.data[i].pacnos.split(',')
                                } else {
                                    tmpdata[i].pacnoarr = []
                                }
                            }
                            that.allorderlist = tmpdata
                        } else {
                            that.allorderlist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getalldplorderbypage () {
                var winfo = ''
                if (that.opwaymode) {
                    winfo = " ordertype != 9 and hxorder='" + localStorage.getItem('hxorderno') + "'"
                } else {
                    winfo = " ordertype != 9 and (userphone='" + localStorage.getItem('userphone') + "' or fromcontact='" + localStorage.getItem('userphone') + "')"
                }
                if (that.state > 0) {
                    winfo = winfo + ' and orderstate=' + that.state
                }
                var qstr = {
                    whereinfo: winfo,
                    limit: ' limit ' + (that.curpage - 1)*that.size + ',' + that.size,
                    sortinfo: ' order by adddate desc'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querypsorderinfo',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].cuspacimgs !== null) {
                                    tmpdata[i].cuspacarr = res.data[i].cuspacimgs.split(',')
                                } else {
                                    tmpdata[i].cuspacarr = []
                                }
                                if (res.data[i].pacimgs !== null) {
                                    tmpdata[i].pacarr = res.data[i].pacimgs.split(',')
                                } else {
                                    tmpdata[i].pacarr = []
                                }
                                if (res.data[i].zcimgs !== null) {
                                    tmpdata[i].zcarr = res.data[i].zcimgs.split(',')
                                } else {
                                    tmpdata[i].zcarr = []
                                }
                                if (res.data[i].jjimgs !== null) {
                                    tmpdata[i].jjarr = res.data[i].jjimgs.split(',')
                                } else {
                                    tmpdata[i].jjarr = []
                                }
                                if (res.data[i].pacnos !== null) {
                                    tmpdata[i].pacnoarr = res.data[i].pacnos.split(',')
                                } else {
                                    tmpdata[i].pacnoarr = []
                                }
                                that.allorderlist.push(tmpdata[i])
                            }
                            window.console.log('分页后数据',that.allorderlist)
                        } else {
                            that.ifend = true
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
    const JsBridge = function () {};
    const clearWindowsEnv = (callback) => {
        setTimeout(() => {
            delete(window)[callback];
        }, 10);
    };
    const sendNative = function (name, params, callback) {
        if(typeof params === 'function') {
            callback = params
            params = {}
        }
        const paramsToJson = JSON.stringify({
            name,
            params
        })

        if (window.ENV === 'IOS') {
            const pre = "IOS_CALLBACK_"
            window[pre + name] = (data) => {
                callback(data ? data : null)
                clearWindowsEnv(pre + name)
            }
            window.webkit.messageHandlers.IOS.postMessage(
                paramsToJson
            )
        }
        if (window.ENV === 'Android') {
            const data =  window.JsBridgeAndroid.postMessageAndroid(
                paramsToJson
            )
            callback(JSON.parse(data));
        }
    }
    JsBridge.prototype.call = function (name, params, callback) {
        sendNative(name, params, callback)
    }

    window.JSBridge = new JsBridge();
</script>

<style>
    .addressicon {
        width: 75px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
</style>
