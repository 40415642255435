<template>
    <div>
        <div style="margin-top:40px;display: flex; justify-content: center;align-items: center;">
            <img style="width:200px;height: 200px;" src="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/southeast.jpg" />
        </div>
        <div style="font-size:18px;margin-top:20px;display: flex; justify-content: center;align-items: center;">
            支付成功
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    Vue.prototype.$http = axios
    var that
    export default {
        name: "PaySuccess",
        mounted() {
            that = this
            if (localStorage.getItem('mode') !== undefined && localStorage.getItem('mode') !== null && localStorage.getItem('mode') !== '') {
                that.sendsmsmsg()
                that.$router.replace({ path: 'OrderInfo?state=2' })
            } else {
                window.console.log('正常模式')
            }
            if (this.$route.query.pac_order_no !== undefined && this.$route.query.pac_order_no !== null && this.$route.query.pac_order_no !== '') {
                // 更新订单状态，并调转state 2
                // that.uporderstate(this.$route.query.pac_order_no)
            }
        },
        methods: {
            uporderstate (orderno) {
                var updata = {
                    orderno: orderno,
                    state: '2'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/uporderstate',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.$router.replace({ path: 'OrderInfo?state=2' })
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            sendsmsmsg () {
                window.console.log('发送短信')
                if (localStorage.getItem('sendsmsphone') !== '' && localStorage.getItem('sendsmsphone') !== undefined && localStorage.getItem('sendsmsphone') !== null) {
                    var datatmp = {
                        phone: localStorage.getItem('sendsmsphone'),
                        signname: '航空行李管家',
                        tempcode: 'SMS_462245402',
                        is_send: '1'
                    }
                    that.$http({
                        url: 'https://api.yunshangyunkeji.com/getvercode',
                        method: 'post',
                        data: datatmp,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log(res)
                            if (res.data.result==='success') {
                                window.console.log('发送完毕')
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                }
            }
        }
    }
</script>

<style scoped>

</style>
