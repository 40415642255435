<template>
    <div>
        <div id="container"></div>
        <div style="margin-bottom: 100px;">
            <van-steps direction="vertical" :active="curstep">
                <van-step v-for="(item,index) in steps" :key="index">
                    <h3>{{item.text}}</h3>
                    <div v-if="item.ifphone">
                        <div style="display: flex; margin-left: 5px; flex-direction: column">
                            <div>司机：{{item.desc}}</div>
                            <div>联系电话：<a :href="'tel:' + item.phoneno">{{item.phoneno}}</a></div>
                        </div>
                    </div>
                    <div v-else>{{item.desc}}</div>
                </van-step>
            </van-steps>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import AMapLoader from '@amap/amap-jsapi-loader';
    import Utils from '../../util/utils'
    import { Step, Steps } from 'vant';
    Vue.use(Step);
    Vue.use(Steps);
    window._AMapSecurityConfig = {
        securityJsCode: "c9e71acafa53111266a56d18658776d5"
    };
    var that
    export default {
        name: "OrderPSInfo",
        data(){
            return{
                map:null,
                curitem: '',
                curstep: 0,
                curdriverpos: '',
                steps: [],
                markerList: []
            }
        },
        mounted () {
            that = this
            this.curitem = JSON.parse(localStorage.getItem('curitem'))
            // 获取当前司机的位置
            this.getdriverpos()
            this.initSetp()
        },
        methods:{
            getdriverpos () {
                var qstr = {
                    whereinfo: ' driver_id=' + this.curitem.driver_id,
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querydriverfinpos',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            that.curdriverpos = res.data[0].fin_location
                            that.initMap()
                        } else {
                            that.curdriverpos = ''
                            that.initMap()
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            initMap(){
                // 获取当前起始点的位置
                var frompos = this.curitem.frompos.split(',')
                var topos = this.curitem.topos.split(',')
                var driverpos = that.curdriverpos.split(',')
                window.console.log(frompos[0])
                AMapLoader.load({
                    key: "77757fa6557edf1f24f4bdebb70d1dce",             // 申请好的Web端开发者Key，首次调用 load 时必填
                    version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins:['AMap.DragRoute'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                }).then((AMap)=>{
                    this.map = new AMap.Map("container",{  //设置地图容器id
                        viewMode:"3D",    //是否为3D地图模式
                        zoom:11,           //初始化地图级别
                        center:[frompos[0],frompos[1]], //初始化地图中心点位置
                    });
                    var path = [];
                    var startLngLat = [frompos[0],frompos[1]]
                    var endLngLat = [topos[0],topos[1]]
                    path.push(startLngLat)
                    path.push(endLngLat)
                    var startIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(32, 32),
                        // 图标的取图地址
                        image: 'https://tuoyun.oss-cn-beijing.aliyuncs.com/icon/%E6%8B%89%E8%B4%A7%E8%BD%A6.png',
                        // 图标所用图片大小
                        imageSize: new AMap.Size(32, 32),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(-5, 0)
                    })
                    var route = new AMap.DragRoute(this.map, path, AMap.DrivingPolicy.LEAST_FEE);
                    route.search();
                    var marker = new AMap.Marker({
                        icon:startIcon,
                        position: new AMap.LngLat(driverpos[0], driverpos[1])
                    });
                    that.markerList.push(marker)
                    that.map.add(that.markerList)
                    /*
                    var lineArr = [
                        [frompos[0],frompos[1]],
                        [driverpos[0],driverpos[1]]
                    ];
                    var polyline = new AMap.Polyline({
                        path: lineArr,            // 设置线覆盖物路径
                        strokeColor: '#3366FF',   // 线颜色
                        strokeOpacity: 1,         // 线透明度
                        strokeWeight: 2,          // 线宽
                        strokeStyle: 'solid',     // 线样式
                        strokeDasharray: [10, 5], // 补充线样式
                        geodesic: true,
                        zIndex: 50 // 绘制大地线
                    });
                    this.map.add(polyline);
                     */
                }).catch(e=>{
                    window.console.log(e);
                })
            },
            async getwsjsdate () {
                return new Promise(resolve => {
                    var qstr = {
                        whereinfo: " orderno='" + that.curitem.orderno + "'",
                        limit: '',
                        sortinfo: ''
                    }
                    that.$http({
                        url: 'https://api.yunshangyunkeji.com/querywsorderinfo',
                        method: 'post',
                        data: qstr,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log('返回驻场员记录')
                            window.console.log(res)
                            resolve(res.data)
                        })
                        .catch(function (error) {
                            resolve(error)
                        })
                })
            },
            async getdriverjsdate() {
                return new Promise(resolve => {
                    var qstr = {
                        whereinfo: " orderno='" + that.curitem.orderno + "'",
                        limit: '',
                        sortinfo: ''
                    }
                    that.$http({
                        url: 'https://api.yunshangyunkeji.com/querypsorderinfo',
                        method: 'post',
                        data: qstr,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log(res)
                            resolve(res.data)
                        })
                        .catch(function (error) {
                            resolve(error)
                        })
                })
            },
            async getfindate() {
                return new Promise(resolve => {
                    var qstr = {
                        whereinfo: " orderno='" + that.curitem.orderno + "' and orderstate >= 6",
                        limit: '',
                        sortinfo: ''
                    }
                    that.$http({
                        url: 'https://api.yunshangyunkeji.com/queryorder',
                        method: 'post',
                        data: qstr,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log('最后的状态')
                            resolve(res.data)
                        })
                        .catch(function (error) {
                            resolve(error)
                        })
                })
            },
            async initSetp () {
                // 获取订单日期
                var adata = {
                    text: '订单已提交',
                    ifphone: false,
                    desc: this.curitem.adddate,
                }
                this.steps.push(adata)
                if (this.curitem.ordertype === 1) {
                    window.console.log('ordertype',1)
                    var wsresult = await this.getwsjsdate()
                    window.console.log('返回ws记录' + wsresult)
                    if (wsresult !== '' && wsresult.length > 0) {
                        var adata1 = {
                            text: '驻场员已接单',
                            ifphone: false,
                            desc: wsresult[0].wsdate,
                        }
                        this.steps.push(adata1)
                        this.curstep = 1
                    }
                    var driverresult = await this.getdriverjsdate()
                    window.console.log('返回driver记录')
                    window.console.log(driverresult)
                    if (driverresult !== '' && driverresult.length > 0) {
                        window.console.log('执行写入司机已接单')
                        var adata2 = {
                            text: '司机已接单',
                            ifphone: false,
                            desc: driverresult[0].psdate
                        }
                        var adata21 = {
                            text: '配送中',
                            ifphone: true,
                            phoneno: driverresult[0].phone,
                            desc: driverresult[0].name
                        }
                        this.curstep = 3
                        this.steps.push(adata2)
                        this.steps.push(adata21)
                    }
                }
                if (this.curitem.ordertype === 2) {
                    window.console.log('ordertype',2)
                    var driverresult1 = await this.getdriverjsdate()
                    window.console.log('返回driver记录',driverresult1)
                    if (driverresult1 !== '' && driverresult1.length >= 0) {
                        var adata3 = {
                            text: '司机已接单',
                            ifphone: false,
                            desc: driverresult1[0].psdate,
                        }
                        var adata31 = {
                            text: '配送中',
                            ifphone: true,
                            phoneno: driverresult1[0].phone,
                            desc: driverresult1[0].name
                        }
                        this.steps.push(adata3)
                        this.steps.push(adata31)
                        this.curstep = 2
                    }
                    var wsresult1 = await this.getwsjsdate()
                    window.console.log('返回ws记录' + wsresult1.length)
                    if (wsresult1 !== '' && wsresult1.length >= 0) {
                        var adata4 = {
                            text: '驻场员已接单',
                            ifphone: false,
                            desc: wsresult1[0].wsdate,
                        }
                        this.steps.push(adata4)
                        this.curstep = 3
                    }
                }
                let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                var finresult = await this.getfindate()
                window.console.log('最后状态data')
                window.console.log(finresult)
                if (finresult !== '' && finresult.length > 0) {
                    var adata41 = {
                        text: '已送达',
                        ifphone: false,
                        desc: nowStr,
                    }
                    this.steps.push(adata41)
                    this.curstep = 4
                }
            }
        }
    }
</script>

<style  scoped>
    #container{
        padding:0px;
        margin: 0px;
        width: 100%;
        height: 300px;
    }
</style>
