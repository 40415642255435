<template>
    <div>
        <div style="height:260px; background-color: #fff; margin-left: 20px;margin-right: 20px;  margin-top: 10px;border: 1px solid #f3f3f3;
            border-radius:10px">
            <div style="margin-top: 15px; display: flex; justify-content: center;">
                <van-field name="radio" label="地址分类" style="background-color: #F9F9F9;height: 50px;line-height: 30px; width:95%;">
                    <template #left-icon>
                        <van-icon size="16" name="https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E4%BA%BA%E5%91%98.png" />
                    </template>
                    <template #input>
                        <van-radio-group v-model="addresstype" direction="horizontal">
                            <van-radio name="0">寄件</van-radio>
                            <van-radio name="1">收件</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
            </div>
            <div style="margin-top: 15px; display: flex; justify-content: center;">
                <van-field label-width="50" @blur="getgps" label-class="lefttxt" type="textarea" rows="2" left-icon="smile-o" style="font-size:12px;background-color: #F9F9F9;height: 150px;line-height: 30px; width:95%;" v-model="curaddress" label="地址" placeholder="请输入地址" >
                    <template #left-icon>
                        <van-icon size="16" name="https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E5%AF%84%E4%BB%B6%E5%8A%9F%E8%83%BD.png" />
                    </template>
                    <template #button>
                        <van-icon size="18" name="search" @click="fromquerypos"/>
                    </template>
                </van-field>
            </div>
        </div>
        <div style="margin-top:20px; display: flex; justify-content: center;">
            <van-button style="width:300px;" color="#3c71d9" type="primary" round @click="saveaddress" >确认</van-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Notify} from 'vant';
    Vue.use(Notify);
    var that
    export default {
        name: "AddAddress",
        data () {
            return {
                addresstype: '0',
                curaddress: '',
                curaddressobj: ''
            }
        },
        mounted() {
            that = this
            that.addresstype = localStorage.getItem('atype')
            if (localStorage.getItem('atype') === '0') {
                if (localStorage.getItem('faddress') !== null && localStorage.getItem('faddress') !== '' && localStorage.getItem('faddress') !== undefined) {
                    that.curaddress = localStorage.getItem('faddress')
                    that.getgps()
                }
            }
            if (localStorage.getItem('atype') === '1') {
                if (localStorage.getItem('taddress') !== null && localStorage.getItem('taddress') !== '' && localStorage.getItem('faddress') !== undefined) {
                    that.curaddress = localStorage.getItem('taddress')
                    that.getgps()
                }
            }
        },
        methods: {
            getgps () {
                var that = this
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/geo?key=22f85a8aed7e77da085713450af984cf&address=' + that.curaddress,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        that.getaddress(res.data.geocodes[0].location)
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getaddress (pos) {
                that.$http({
                    url: 'https://restapi.amap.com/v3/geocode/regeo?key=22f85a8aed7e77da085713450af984cf&location=' + pos,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.info === 'OK') {
                            that.curaddressobj = res.data.regeocode.addressComponent
                            localStorage.setItem('curaddress',JSON.stringify(res.data.regeocode.addressComponent))
                        } else {
                            Notify({ type: 'warning', message: '地址获取失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            fromquerypos () {
                if (that.addresstype !== '') {
                    localStorage.setItem('atype',that.addresstype)
                    that.$router.push({ path: 'QueryPos?fyway=3&qway=' + that.addresstype })
                } else {
                    Notify({ type: 'warning', message: '请选择寄件分类' });
                }
            },
            saveaddress () {
                window.console.log('地址',that.curaddressobj)
                var adata = {
                    id: '',
                    adtype: this.addresstype.toString(),
                    province: that.curaddressobj.province,
                    city: '',
                    district: that.curaddressobj.district,
                    street: that.curaddressobj.streetNumber.street,
                    adcode: that.curaddressobj.adcode,
                    citycode: that.curaddressobj.citycode,
                    districtcode: that.curaddressobj.towncode,
                    location: that.curaddressobj.streetNumber.location,
                    format_address: that.curaddress,
                    is_default: '0',
                    is_recommend: '1',
                    user_id: localStorage.getItem('openid'),
                    cusid: '1'
                }
                window.console.log(adata)
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/addaddressinfo',
                    method: 'POST',
                    data: adata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            that.$router.go(-1)
                        }
                    })
            }
        }
    }
</script>

<style scoped>

</style>
