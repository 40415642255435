<template>
    <div>
        <div style="margin: 20px;margin-bottom: 0px;margin-top: 5px;border-radius: 10px;">
             <van-swipe :autoplay="3000">
                 <van-swipe-item v-for="(img, index) in branddata" :key="index" style="border-radius: 10px;display: flex;align-items: center;justify-content: center;">
                     <img style="width: 100%; height: 180px;border-radius: 10px;" v-lazy="img.adurl" />
                 </van-swipe-item>
             </van-swipe>
        </div>
        <div style="height: 120px;box-shadow: 2px 2px 2px 2px #ccc;margin-left: 20px; margin-right:22px;margin-bottom: 0px;margin-top: 10px;border-radius: 10px;">
            <van-grid icon-size="42px" border column-num="2" style="height: 120px; border-radius: 10px; font-size: 24px;">
                <van-grid-item
                        v-for="(kjrk) in kjrkdata"
                        :key="kjrk.id"
                        :icon="kjrk.scurl"
                        @click="showkjrk(kjrk.scname)"
                        :text="kjrk.scname"
                />
            </van-grid>
        </div>
        <div class="pagehffg" style="margin-top: 20px;">
            <img v-lazy="hdimg" class='pagehffgleft' />
            服务网点
        </div>
        <div class="goodscontainerex">
            <div v-for="(item,index) in fwwdlist" :key="index">
                <div :style="'width:' + colwidth + 'px;'">
                    <div class="txtcontainer1">
                        <img :src="item.purl" class="goodsimage" :style="'width:' + colwidth - 22  + 'px;'"  />
                        <div class="goodstxtcontainer">
                            <div style="margin: 20px;">
                                <div class="goodstxt">
                                    {{item.pservicename}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-overlay style="display: flex; align-items: center; justify-content: center;" :show="loadshow">
            <div style="display: flex; align-items: center; flex-direction: column; justify-content: center;">
                <van-loading type="spinner" />
                <div style="color:#fff;margin-top: 10px;">支付中...</div>
            </div>
        </van-overlay>

    </div>
</template>
<script>
    import Vue from 'vue'
    import Utils from '../util/utils'
    import '../../static/css/my.css'
    import '../../static/font/iconfont.css'
    import { Notify } from 'vant';
    import axios from 'axios'
    import { Lazyload } from 'vant'
    import { Swipe, SwipeItem } from 'vant'
    import { DropdownMenu, DropdownItem } from 'vant';
    import { Grid, GridItem } from 'vant';
    import { Loading } from 'vant';
    import { Overlay } from 'vant';

    Vue.use(Overlay);
    Vue.use(Loading);
    /*
    import Vconsole from 'vconsole'
    let vConsole = new Vconsole()
    Vue.use(vConsole)
     */
    Vue.use(Notify);
    Vue.use(Grid);
    Vue.use(GridItem);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Swipe).use(SwipeItem)
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    export default {
        name: 'MainPage',
        data () {
            return {
                active: 0,
                colwidth: (window.innerWidth / 2) -2,
                ordertype: '',
                ifhx: '',
                fwwdlist: [],
                loadshow: false,
                branddata: [],
                tjgoodsdata: [],
                hdimg: 'https://sqpro.oss-cn-beijing.aliyuncs.com/bc/resources/%E4%BF%83%E9%94%80.png',
                kjrkdata: [
                    {
                        id:0,
                        scurl: 'https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E5%A7%94%E6%89%98%E8%BF%90%E8%BE%93%E7%AE%A1%E7%90%86.png',
                        scname: '门到港'
                    },
                    {
                        id:0,
                        scurl: 'https://sqpro.oss-cn-beijing.aliyuncs.com/tuoyun/%E6%89%98%E8%BF%90%20%281%29.png',
                        scname: '港到门'
                    }
                ],
                recommtypes: [],
                ztdata: [],
                goodsrec: [],
                allscreenWidth: (document.body.clientWidth / 5) - 5,
                screenWidth: (document.body.clientWidth / 2)
            }
        },
        mounted: function () {
            that = this
            // let date = rTime('2022-12-01T11:15:48+08:00');
            // window.console.log(date)
            // 初始清空from地址
            localStorage.setItem('from_address','')
            // 获取OpenID
            document.title = '首页'
            that.allscreenWidth = (document.body.clientWidth / 5) - 5
            that.screenWidth = (document.body.clientWidth / 2) - 2
            window.onresize = () => {
                return (() =>{
                    that.allscreenWidth = (document.body.clientWidth / 5) - 5
                    that.screenWidth = (document.body.clientWidth / 2) - 1
                })()
            }
            if (this.$route.query.mode !== undefined && this.$route.query.mode !== null && this.$route.query.mode !== '') {
                if (this.$route.query.phone !== undefined && this.$route.query.phone !== null && this.$route.query.phone !== '') {
                    localStorage.setItem('proxy_phone',this.$route.query.phone)
                    localStorage.setItem('userphone',this.$route.query.phone)
                } else {
                    localStorage.setItem('proxy_phone','')
                    localStorage.setItem('userphone','')
                }
                if (this.$route.query.flight_no !== undefined && this.$route.query.flight_no !== null && this.$route.query.flight_no !== '') {
                    localStorage.setItem('flight_no',this.$route.query.flight_no)
                } else {
                    localStorage.setItem('flight_no','')
                }
                if (this.$route.query.if_free !== undefined && this.$route.query.if_free !== null && this.$route.query.if_free !== '') {
                    localStorage.setItem('if_free',this.$route.query.if_free)
                } else {
                    localStorage.setItem('if_free','')
                }
                if (this.$route.query.freight_price !== undefined && this.$route.query.freight_price !== null && this.$route.query.freight_price !== '') {
                    localStorage.setItem('freight_price',this.$route.query.freight_price)
                } else {
                    localStorage.setItem('freight_price','')
                }
                if (this.$route.query.mode !== undefined && this.$route.query.mode !== null && this.$route.query.mode !== '') {
                    localStorage.setItem('mode',this.$route.query.mode)
                } else {
                    localStorage.setItem('mode','')
                }
                if (this.$route.query.buildFlag !== undefined && this.$route.query.buildFlag !== null && this.$route.query.buildFlag !== '') {
                    localStorage.setItem('buildFlag',this.$route.query.buildFlag)
                } else {
                    localStorage.setItem('buildFlag','')
                }
                if (this.$route.query.parid !== undefined && this.$route.query.parid !== null && this.$route.query.parid !== '') {
                    localStorage.setItem('parid',this.$route.query.parid)
                } else {
                    localStorage.setItem('parid','')
                }
                if (this.$route.query.agentid !== undefined && this.$route.query.agentid !== null && this.$route.query.agentid !== '') {
                    localStorage.setItem('agentid',this.$route.query.agentid)
                } else {
                    localStorage.setItem('agentid','')
                }
                if (this.$route.query.oriSono !== undefined && this.$route.query.oriSono !== null && this.$route.query.oriSono !== '') {
                    localStorage.setItem('hxorder_no',this.$route.query.oriSono)
                } else {
                    localStorage.setItem('hxorder_no','')
                }
                if (this.$route.query.cagentid !== undefined && this.$route.query.cagentid !== null && this.$route.query.cagentid !== '') {
                    localStorage.setItem('cagentid',this.$route.query.cagentid)
                } else {
                    localStorage.setItem('cagentid','')
                }
                if (this.$route.query.travelType !== undefined && this.$route.query.travelType !== null && this.$route.query.travelType !== '') {
                    localStorage.setItem('travelType',this.$route.query.travelType)
                } else {
                    localStorage.setItem('travelType','')
                }
                that.ifhx = 1
            } else {
                window.console.log('非航信推来的运单号')
                that.ifhx = 0
                localStorage.setItem('hxorder_no','')
                localStorage.setItem('proxy_phone','')
                localStorage.setItem('flight_no','')
                localStorage.setItem('if_free','')
                localStorage.setItem('freight_price','')
                localStorage.setItem('mode','')
                localStorage.setItem('buildFlag','')
                localStorage.setItem('parid','')
                localStorage.setItem('agentid','')
                localStorage.setItem('cagentid','')
                localStorage.setItem('travelType','')
                const URL = window.location.href.replace('#/','')
                var params = parse_url(URL)
                if(localStorage.getItem('openid') !== '' && localStorage.getItem('openid') !== undefined && localStorage.getItem('openid') !== null) {
                    window.console.log('openid 存在')
                } else {
                    if (params['code'] !== '' && params['code'] !== undefined) {
                        var qdata = {
                            code: params['code']
                        }
                        that.$http({
                            url: 'https://wx.yunshangyunkeji.com/wx/getopenid',
                            method: 'post',
                            data: qdata,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(function (res) {
                                window.console.log(res)
                                if (res.data.state === 'success') {
                                    localStorage.setItem('openid',res.data.openid)
                                    localStorage.setItem('nickname',res.data.nickname)
                                    localStorage.setItem('headimgurl',res.data.headimgurl)
                                    localStorage.setItem('sex',res.data.nickname)
                                    that.checkuser()
                                } else {
                                    Notify({ type: 'danger', message: '授权失败' });
                                }
                            })
                            .catch(function (error) {
                                window.console.log(error)
                            })
                    } else {
                        window.console.log(localStorage.getItem('authway'))
                        if (localStorage.getItem('authway') !== undefined && localStorage.getItem('authway') !== null && localStorage.getItem('authway') !== '') {
                            window.console.log('已发起授权')
                        } else {
                            const URL1 = window.location.href.replace('#/','')
                            var params1 = encodeURIComponent(URL1)
                            window.console.log(params1)
                            localStorage.setItem('authway',1)
                            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe99f14b2ccfddb2f&redirect_uri=' + params1 + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
                        }
                    }
                }
            }
            that.getbrand()
            that.getservicepoint()
            that.clearhc()
        },
        methods: {
            clearhc () {
                localStorage.setItem('flight_no','')
                localStorage.setItem('fly_date','')
                localStorage.setItem('fly_time','')
                localStorage.setItem('from_date','')
                localStorage.setItem('from_time','')
                localStorage.setItem('to_date','')
                localStorage.setItem('to_time','')
                localStorage.setItem('gcount','')
                localStorage.setItem('orderdes','')
                localStorage.setItem('fileList','')
                localStorage.setItem('hyqid','')
                localStorage.setItem('yhje','')
                localStorage.setItem('lxrfrominfo','')
                localStorage.setItem('lxrtoinfo','')
            },
            checkuser () {
                var qstr = {
                    whereinfo: " openid='" + localStorage.getItem('openid') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryui',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data[0]
                            that.userimg = tmpdata.imgurl
                            that.usernick = tmpdata.nickname
                            if (tmpdata.phone !== null && tmpdata.phone !== '') {
                                localStorage.setItem('userphone',tmpdata.phone)
                            }
                        } else {
                            that.addnewuser()
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            addnewuser () {
                let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                var adata = {
                    realname: localStorage.getItem('nickname'),
                    nickname: localStorage.getItem('nickname'),
                    postid: '999',
                    imgurl: localStorage.getItem('headimgurl'),
                    state: '1',
                    sex: localStorage.getItem('sex'),
                    birthday: '',
                    phone: '',
                    adddate: nowStr,
                    utype: '999',
                    openid: localStorage.getItem('openid')
                }
                window.console.log(adata)
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/addui',
                    method: 'post',
                    data: adata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '授权成功' });
                        } else {
                            Notify({ type: 'danger', message: '授权失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getbrand: function () {
                var qstr = {
                    whereinfo: ' cusid=1',
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/querylbi',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            window.console.log('branddata',res.data)
                            that.branddata = res.data
                        } else {
                            that.branddata = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getservicepoint: function () {
                var qstr = {
                    whereinfo: ' cusid=1',
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryservicepoint',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            window.console.log(JSON.stringify(res.data))
                            that.fwwdlist = res.data
                        } else {
                            that.fwwdlist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            showkjrk(kjrkname) {
                window.console.log(kjrkname)
                localStorage.setItem('fromaddress','')
                localStorage.setItem('toaddress','')
                if (kjrkname === '港到门') {
                    that.$router.push({ path: 'ToHome?ordertype=1&ifhx=' + this.ifhx })
                } else if (kjrkname === '门到港') {
                    that.$router.push({ path: 'FToHome?ordertype=2&ifhx=' + this.ifhx })
                }
            }
        }
    }
    function parse_url(url){ //定义函数
        var pattern = /(\w+)=(\w+)/ig
        var parames = {}
        url.replace(pattern, function(a, b, c){parames[b] = c})
        return parames
    }
    /*
    function stopTouchendPropagationAfterScroll(){
        var locked = false;
        window.addEventListener('touchmove', function(ev){
            locked || (locked = true, window.addEventListener('touchend', stopTouchendPropagation, true));
            window.console.log(ev)
        }, true);
        function stopTouchendPropagation(ev){
            ev.stopPropagation();
            window.removeEventListener('touchend', stopTouchendPropagation, true);
            locked = false;
        }
    }
    */
</script>
<style>
    .max_goodscolline {
        height: 240px;
        background-color: #e6e6e6;
    }
    .goodscolline {
        height: 20px;
        background-color: #ebedf0;
    }
    .goodscolline1 {
        height: 6px;
        background-color: #e6e6e6;
    }
    .pagehffg {
        height: 40px;
        justify-content: left;
        align-items:center;
        text-align:left;
        display: flex;
        font-size: 12px;
        color: rgb(73, 70, 70);
        line-height:160px;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;
    }
    .pagehffgleft {
        margin-left: 40px;
        width:18px;
        height: 18px;
        margin-right: 5px;
    }

    .goodscontainer {
        -moz-column-count:2; /* Firefox */
        -webkit-column-count:2; /* Safari and Chrome */
        column-count:2;
        column-gap:2px;
        -moz-column-rule:4px solid #e6e6e6; /* Firefox */
        -webkit-column-rule:4px solid #e6e6e6; /* Safari and Chrome */
        column-rule:4px solid #e6e6e6;
        -moz-column-fill:auto;
        -webkit-column-fill:auto;
        column-fill:auto;
        overflow:hidden;
        margin-bottom:60px;

    }

    .goodsview {
        font-size: 56px;
        font-weight: 300;
    }

    .txtcontainer1 {
        display: flex;
        margin: 10px;
        border: 1px solid #f3f3f3;
        border-radius:10px 10px 0 0;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        vertical-align: middle;
    }
    .goodstxtcontainer {
        display: flex;
        width: 100%;
        flex-flow: column;
    }
    .goodstxt {
        font-size: 14px;
        color: #000;
        font-weight: 300;
    }
    .goodsimage {
        margin-top: 10px;
        height: 110px;
    }
    .goodspricetxt {
        font-size: 12px;
        color: #ccc;
        font-weight: 300;
    }
    .rowslip {
        height: 2px;
        width: 100%;
    }

    .colslip {
        width: 1px;
        background-color: #ebedf0;
    }
    .goodscontainerex {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
    }
</style>
