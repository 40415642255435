<template>
    <div>
        <div style="background-color:#fff;border: 1px solid #f3f3f3;margin: 6px; padding-bottom: 8px;padding: 6px; margin: 6px 6px 16px 6px; border-radius:10px;" :style="'min-height:' + colheight + 'px;'">
            <div style="display:flex; padding: 10px 15px 10px 15px; flex-direction: row; justify-content: flex-start; align-items: center; font-size: 28px;">
                <van-icon style="margin-right: 5px;margin-top: 3px;" name="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/logo.png" />
                <div style="color: #000; font-size: 16px; margin-left: 5px;">航空行李管家</div>
            </div>
            <div style="display: flex; flex-direction: row;">
                <div style="display:flex; flex:1; padding-left: 15px;padding-right: 15px; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 13px;">
                    <div>
                        订单号：{{curitem.orderno}}
                    </div>
                    <div style="margin-top: 5px; margin-left: -2px;">
                        下单时间：{{curitem.adddate}}
                    </div>
                    <div style="margin: 5px 0 0px 0px; display: flex; flex-direction: row;" v-if="curitem.pacnos !== null && curitem.pacnos !== null">
                        <div>
                            行李代码：
                        </div>
                        <div style="font-size: 12px;">
                            <div style="display: flex; flex-direction: column;">
                                <div style="margin: 2px 0 0 0px" v-for="(subitem,index) in curitem.pacnoarr" :key="index">
                                    {{subitem}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <van-submit-bar
                    button-text="取消订单"
                    @submit="qxorder(curitem)"
            />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios'
    import { Notify } from 'vant';
    Vue.prototype.$http = axios
    Vue.use(Notify);
    var that
    export default {
        name: "OrderCancel",
        data () {
            return {
                curitem: '',
                colheight: '',
                sendsmsphone: ''
            }
        },
        mounted() {
            that = this
            that.colheight =  window.innerHeight
            that.getorder()
        },
        methods: {
            sendsmsmsg () {
                var datatmp = {
                    phone: this.sendsmsphone,
                    signname: '航空行李管家',
                    tempcode: 'SMS_462245402',
                    is_send: '1'
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/getvercode',
                    method: 'post',
                    data: datatmp,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result==='success') {
                            window.console.log('发送完毕')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            qxorder (item) {
                that.uporderstate(item.orderno,'9','取消成功')
                if (parseInt(localStorage.getItem('mode')) === 0) {
                    that.uptohxyj()
                }
                if (parseInt(localStorage.getItem('mode')) === 1) {
                    that.uptohx()
                }
            },
            uptohx () {
                var updata = {
                    pacOrderNo: this.curitem.orderno,
                    agentid: localStorage.getItem('agentid'),
                    status: '9',
                    opration: '2',
                    sono: localStorage.getItem('hxorderno')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxinputorder',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '取消成功' });
                            // that.sendsmsmsg()
                        } else {
                            Notify({ type: 'danger', message: '取消失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            uptohxyj () {
                var updata = {
                    pacOrderNo: this.curitem.orderno,
                    agentid: localStorage.getItem('agentid'),
                    status: '9',
                    opration: '2',
                    sono: localStorage.getItem('hxorderno')
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/hxyjordercancel',
                    method: 'post',
                    data: {
                        param_json: updata
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.data.success) {
                            // that.sendsmsmsg()
                            Notify({ type: 'success', message: '取消成功' });
                        } else {
                            Notify({ type: 'danger', message: '取消失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            uporderstate (orderno,state,returnstr) {
                var updata = {
                    orderno: orderno,
                    state: state
                }
                that.$http({
                    url: 'https://api.yunshangyunkeji.com/uporderstate',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: returnstr });
                        } else {
                            Notify({ type: 'danger', message: '操作失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '操作失败' });
                    })
            },
            getorder () {
                var winfo = " hxorder = '" + localStorage.getItem('hxorderno') + "'"
                var qstr = {
                    whereinfo: winfo,
                    limit: '',
                    sortinfo: ''
                }
                window.console.log(qstr)

                that.$http({
                    url: 'https://api.yunshangyunkeji.com/queryorder',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            var tmpdata = res.data[0]
                            if (res.data[0].cuspacimgs !== null) {
                                tmpdata.cuspacarr = res.data[0].cuspacimgs.split(',')
                            } else {
                                tmpdata.cuspacarr = []
                            }
                            if (res.data[0].pacimgs !== null) {
                                tmpdata.pacarr = res.data[0].pacimgs.split(',')
                            } else {
                                tmpdata.pacarr = []
                            }
                            if (res.data[0].zcimgs !== null) {
                                tmpdata.zcarr = res.data[0].zcimgs.split(',')
                            } else {
                                tmpdata.zcarr = []
                            }
                            if (res.data[0].jjimgs !== null) {
                                tmpdata.jjarr = res.data[0].jjimgs.split(',')
                            } else {
                                tmpdata.jjarr = []
                            }
                            if (res.data[0].pacnos !== null) {
                                tmpdata.pacnoarr = res.data[0].pacnos.split(',')
                            } else {
                                tmpdata.pacnoarr = []
                            }
                            that.curitem = tmpdata
                            that.sendsmsphone = tmpdata.fromcontact
                        } else {
                            that.curitem = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>