<template>
    <div>
        <div style="margin-top:40px;display: flex; justify-content: center;align-items: center;">
            <img style="width:200px;height: 200px;" src="https://tuoyun.oss-cn-beijing.aliyuncs.com/bg/148359538778088447.jpg" />
        </div>
        <div style="font-size:18px;margin-top:20px;display: flex; justify-content: center;align-items: center;">
            支付失败
        </div>
    </div>
</template>

<script>
    export default {
        name: "PayError",
        mounted() {
            var that = this
            setTimeout(function showorder() {
                that.$router.replace({ path: 'OrderInfo?state=1' })
            },2000)
        }
    }
</script>

<style scoped>

</style>
